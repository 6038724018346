import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import * as turf from "@turf/turf";
import _, { range } from "lodash";
mapboxgl.accessToken = "pk.eyJ1IjoiZGMtZGFycmVuIiwiYSI6ImNreTNjaDhxZTAwb2cyb3BiYWtxd3Q0d3kifQ.jALTlPgOxgKMclZO2c2qZQ";

export default function EntityMap(props) {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(props.entity.geo_json ? props.entity.geo_json[0] : null);
  const [lat, setLat] = useState(props.entity.geo_json ? props.entity.geo_json[1] : null);
  const [zoom, setZoom] = useState(7);

  useEffect(() => {
    if (map.current) return; // initialize map only once

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/dc-darren/cky3ciu972qj915oyx3y737yq",
      center: [lng, lat],
      zoom: zoom,
      minZoom: 6,
      maxZoom: 15,
    });

    new mapboxgl.Marker({ color: "#0A162F" }).setLngLat([lng, lat]).addTo(map.current);

    var radius = 40;
    var options = { units: "miles" };
    var circle = turf.circle([lng, lat], radius, options);

    map.current.on("load", () => {
      map.current.addLayer({
        id: "radius",
        type: "fill",
        source: {
          type: "geojson",
          data: { type: "FeatureCollection", features: [] },
        },
        paint: {
          "fill-color": "#3246D3",
          "fill-opacity": 0.3,
        },
      });

      map.current.getSource("radius").setData(circle);

      map.current.addSource("outRangeCps", {
        type: "geojson",
        data: { type: "FeatureCollection", features: props.entity.pharmacies.geo_json.out_of_range },
      });

      map.current.addLayer({
        id: "outOfRangePharmacies",
        type: "circle",
        source: "outRangeCps",
        paint: {
          "circle-radius": 6,
          "circle-color": "#718291",
        },
        filter: ["==", "$type", "Point"],
      });

      map.current.addSource("inRangeCps", {
        type: "geojson",
        data: { type: "FeatureCollection", features: filterInRangePharmacies() },
      });

      map.current.addLayer({
        id: "inRangePharmacies",
        type: "circle",
        source: "inRangeCps",
        paint: {
          "circle-radius": 6,
          "circle-color": "#3246D3",
        },
        filter: ["==", "$type", "Point"],
      });

      map.current.on("mouseenter", "inRangePharmacies", () => {
        map.current.getCanvas().style.cursor = "pointer";
      });
      map.current.on("mouseleave", "inRangePharmacies", () => {
        map.current.getCanvas().style.cursor = "";
      });
    });
  });

  useEffect(() => {
    if (map.current && map.current.loaded()) {
      if (map.current.getLayer("cp-highlight")) {
        if (props.selectedPharmacy) {
          map.current.setFilter("cp-highlight", ["==", "id", props.selectedPharmacy.value]);
        } else {
          map.current.removeLayer("cp-highlight");
        }
      } else {
        if (props.selectedPharmacy) {
          map.current.addLayer({
            id: "cp-highlight",
            type: "circle",
            source: "inRangeCps",
            paint: {
              "circle-radius": 6,
              "circle-color": "#f50057",
            },
            filter: ["==", "id", props.selectedPharmacy.value],
          });
        }
      }
    }
  }, [props.selectedPharmacy]);

  const pharmacyIdsByKind = () => {
    var cps = _.filter(props.entity.pharmacies.details, (cp) => cp[props.manufacturer].includes(props.kind));
    return _.map(cps, (cp) => cp.value);
  };

  const filterInRangePharmacies = () => {
    if (props.kind != "retail") {
      var pids = pharmacyIdsByKind();

      return _.filter(props.entity.pharmacies.geo_json.in_range, (cp) => {
        return pids.includes(cp.properties.id);
      });
    } else {
      return props.entity.pharmacies.geo_json.in_range;
    }
  };

  return (
    <div>
      <div ref={mapContainer} className="map__container" />
    </div>
  );
}
