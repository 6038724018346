import React, { ReactElement } from "react";
import { NorthEast, SouthEast } from "@mui/icons-material";
import { wholeNumberWithCommas } from "../utils/NumberFormatter";
import Tile from "../Base/Tile";

type StatCardProps = {
  title: string;
  updated?: string;
  stat: number | string;
  legend?: string;
  badge: {
    trend: "up" | "down";
    value: string;
    info: string;
  };
  link?: ReactElement;
};

export default function StatCard({
  title,
  updated,
  stat,
  legend,
  badge,
  link,
}: StatCardProps) {
  return (
    <Tile>
      <Tile.Header className="justify-content-between">
        <div className="d-flex flex-column gap-1">
          <Tile.SecondaryText className="t--500">{title}</Tile.SecondaryText>
          {updated && (
            <Tile.TertiaryText>Updated - {updated}</Tile.TertiaryText>
          )}
        </div>
        {link}
      </Tile.Header>
      <Tile.Body className="d-flex align-items-center gap-1">
        <Tile.PrimaryText>
          {typeof stat === "string" ? stat : wholeNumberWithCommas(stat)}
        </Tile.PrimaryText>
        <Tile.TertiaryText>{legend}</Tile.TertiaryText>
      </Tile.Body>
      <Tile.Footer className="gap-2">
        <div className="badge badge--tiny badge--solid--blue">
          <div className="badge__icon">
            {badge.trend === "up" ? (
              <NorthEast fontSize="inherit" />
            ) : (
              <SouthEast fontSize="inherit" />
            )}
          </div>
          {badge.value}
        </div>
        <Tile.TertiaryText>{badge.info}</Tile.TertiaryText>
      </Tile.Footer>
    </Tile>
  );
}
