import React, { useState, useEffect } from "react";
import Button from "../Base/Button";
import httpClient from "../utils/httpClient";
import EventsTable from "../Reports/OviCommunications/EventsTable";
import { Close } from "@material-ui/icons";

const PharmacyDetailsModal = ({ pharmacy, setDisplayModal, organization_id, id_340b }) => {
  const [events, setEvents] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const query = {
    id_340b,
    pid: pharmacy.pid,
  };

  useEffect(() => {
    setIsLoading(true);
    httpClient
      .get(`/organization/${organization_id}/entities/archived_communications`, {
        params: { ...query },
      })
      .then((res) => {
        // @ts-ignore need to fix this typing across the project
        setEvents(res.mail_events);
        // @ts-ignore need to fix this typing across the project
        setContacts(res.emails);
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <div
      className="modal__background"
      style={{ alignItems: "flex-end" }}
      onClick={() => setDisplayModal(false)}
    >
      <div className="modal__panel animated fadeInUpScale" onClick={(e) => e.stopPropagation()}>
        <div className="container">
          <Button variant="link" className="modal__close" onClick={() => setDisplayModal(false)}>
            <Close />
          </Button>
          <div className="page-details__title">OVI History</div>
          <div className="d-flex justify-content-between">
            <div className="content__bordered_container" style={{ width: "50%", height: "auto" }}>
              <EventsTable events={events} loading={isLoading} />
            </div>
            <div className="content__bordered_container" style={{ width: "40%", height: "auto" }}>
              <table>
                <thead>
                  <tr>
                    <th>Contacts</th>
                  </tr>
                </thead>
                <tbody>
                  {contacts.map((c) => {
                    return (
                      <tr>
                        <td>{c}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PharmacyDetailsModal;
