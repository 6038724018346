import { withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";

const StyledCheckbox = withStyles(() => ({
  root: {
    color: "#DEE5EB",
    // marginLeft: 10,
    padding: 0,
    "&$checked": {
      color: "#3246D3",
      padding: 0,
    },
    "& .MuiIconButton-label": {
      position: "relative",
      zIndex: 0,
    },
    "&:not($checked) .MuiIconButton-label:after": {
      content: '""',
      left: 4,
      top: 4,
      height: 15,
      width: 15,
      position: "absolute",
      backgroundColor: "white",
      zIndex: -1,
    },
  },
  checked: {},
}))(Checkbox);

export default StyledCheckbox;
