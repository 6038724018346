import React from "react";
import { Check } from "@mui/icons-material";

type MultiChipSelectorProps = {
  options: Array<{ value: string; label: string }>;
  selected: Array<string>;
  handleSelection: (arg1: Array<string>) => void;
};

const MultiChipSelector = ({
  options,
  selected,
  handleSelection,
}: MultiChipSelectorProps) => {
  const isChipSelected = (chip: string) => selected.includes(chip);
  const select = (chip: string) => handleSelection([...selected, chip]);
  const deselect = (chip: string) =>
    handleSelection(selected.filter((value) => value !== chip));

  const renderCheck = (value: string) => {
    if (isChipSelected(value)) {
      return (
        <span className="d-inline-flex mr-1 t--md">
          <Check fontSize="inherit" />
        </span>
      );
    }
  };

  return options.map(({ label, value }) => (
    <div
      key={value}
      className={isChipSelected(value) ? "chip chip--selected" : "chip"}
      onClick={() => (isChipSelected(value) ? deselect(value) : select(value))}
    >
      {renderCheck(value)}
      {label}
    </div>
  ));
};

export default MultiChipSelector;
