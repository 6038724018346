import React from "react";

const VisibilityIcon = ({ height = 24, width = 24, isEyeOpen = false }) => {
  const eyeOpen =
    "M24 30.1q2.95 0 5.025-2.1t2.075-5q0-2.95-2.1-5.025T24 15.9q-2.95 0-5.025 2.1T16.9 23q0 2.95 2.1 5.025t5 2.075Zm0-1.6q-2.3 0-3.9-1.625T18.5 23q0-2.3 1.625-3.9T24 17.5q2.3 0 3.9 1.625T29.5 23q0 2.3-1.625 3.9T24 28.5Zm0 7.5q-6.45 0-11.775-3.55T4.15 23q2.75-5.9 8.075-9.45Q17.55 10 24 10t11.775 3.55Q41.1 17.1 43.85 23q-2.75 5.9-8.075 9.45Q30.45 36 24 36Zm0-13Zm0 11.45q5.85 0 10.725-3.1T42.2 23q-2.6-5.25-7.475-8.35-4.875-3.1-10.725-3.1t-10.725 3.1Q8.4 17.75 5.8 23q2.6 5.25 7.475 8.35 4.875 3.1 10.725 3.1Z";
  const eyeClosed =
    "m30.55 25.5-1.25-1.25q.85-3.2-1.5-5.25t-5.05-1.3l-1.25-1.25q.5-.25 1.125-.4.625-.15 1.375-.15 2.95 0 5.025 2.075T31.1 23q0 .75-.15 1.425t-.4 1.075Zm6.35 6.25-1.1-1q2.15-1.65 3.8-3.6 1.65-1.95 2.6-4.15-2.5-5.3-7.35-8.375t-10.6-3.075q-1.75 0-3.525.3t-3.075.75l-1.2-1.2q1.6-.65 3.675-1.025Q22.2 10 24.15 10q6.3 0 11.725 3.525T43.85 23q-1.15 2.55-2.875 4.725T36.9 31.75ZM40 42.1l-7.8-7.75q-1.3.65-3.475 1.15Q26.55 36 24 36q-6.45 0-11.825-3.525Q6.8 28.95 4.15 23q1.15-2.65 3.15-5.05t4.4-4.15l-6-5.95 1.1-1.1L41 40.95ZM12.75 14.9q-1.9 1.25-3.95 3.525Q6.75 20.7 5.8 23q2.5 5.3 7.4 8.375t10.95 3.075q2.1 0 4.15-.375 2.05-.375 2.85-.775l-3.9-3.9q-.55.25-1.5.475-.95.225-1.75.225-2.95 0-5.025-2.05T16.9 23q0-.75.225-1.7t.475-1.55Zm14 7Zm-4.8 2.45Z";

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" height={height} width={width}>
      <path d={isEyeOpen ? eyeOpen : eyeClosed} stroke="currentColor" />
    </svg>
  );
};

export default VisibilityIcon;
