import React, { Fragment } from "react";
import { ExpandLess, ExpandMore, UnfoldMore } from "@material-ui/icons";

import PaginationControls from "../Common/PaginationControls";
import SubTable from "./SubTable";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getExpandedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Row,
} from "@tanstack/react-table";

type OviComm = {
  id_340b: string;
  entity_name: string;
  pharmacy_count: number;
  at_risk_count: number;
  suspension_count: number;
  events: Array<any>;
  emails: Array<any>;
  pid: string;
  network: string;
  pharmacies: Array<any>;
};

export default function CommsTable({ records, compliance_trend_path }) {
  const columnHelper = createColumnHelper<OviComm>();
  const columns = [
    columnHelper.display({
      id: "expand",
      cell: ({ row }) => {
        return (
          <div
            className="btn btn--sqr"
            style={{ verticalAlign: "middle" }}
            onClick={() => {
              row.toggleExpanded();
            }}
          >
            {row.getIsExpanded() ? (
              <ExpandLess fontSize="small" />
            ) : (
              <ExpandMore fontSize="small" />
            )}
          </div>
        );
      },
    }),
    columnHelper.accessor("id_340b", {
      header: "Covered Entity",
      enableSorting: false,
      cell: ({ row, renderValue }) => {
        return (
          <div className="table__text__stack">
            <div className="t--sm t--500">{renderValue()}</div>
            <div className="t--sm t--subtitle">
              {row.original.entity_name.toUpperCase()}
            </div>
          </div>
        );
      },
    }),
    columnHelper.accessor("pharmacy_count", {
      header: "Pharmacy Count",
      enableSorting: false,
      cell: ({ row, renderValue }) => {
        return (
          <div className="table__text__stack">
            <div className="t--sm t--500">{renderValue()}</div>
          </div>
        );
      },
    }),
    columnHelper.accessor("at_risk_count", {
      header: "At Risk Count",
      enableSorting: false,
      cell: ({ renderValue }) => (
        <div className="t--sm t--500">{renderValue()}</div>
      ),
    }),
    columnHelper.accessor("suspension_count", {
      header: "Suspension Count",
      enableSorting: false,
      cell: ({ renderValue }) => (
        <div className="t--sm t--500">{renderValue()}</div>
      ),
    }),
  ];

  const table = useReactTable<OviComm>({
    data: records,
    columns,
    getExpandedRowModel: getExpandedRowModel(),
    getCoreRowModel: getCoreRowModel<OviComm>(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: { pagination: { pageSize: 50 } },
  });

  const renderSubComponent = (row: Row<OviComm>) => {
    return (
      <SubTable
        events={row.original.events}
        emails={row.original.emails}
        path={compliance_trend_path}
        id_340b={row.original.id_340b}
        pid={row.original.pid}
        network={row.original.network}
        pharmacies={row.original.pharmacies}
      ></SubTable>
    );
  };

  const renderRows = () => {
    return table.getRowModel().rows.map((row) => {
      return (
        <Fragment key={row.id}>
          <tr className="sm">
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id} className="t--sm">
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
          {row.getIsExpanded() && (
            <tr>
              <td colSpan={row.getVisibleCells().length}>
                {renderSubComponent(row)}
              </td>
            </tr>
          )}
        </Fragment>
      );
    });
  };

  const renderTable = (
    <table className="primary">
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id} className="sm">
            {headerGroup.headers.map((header) => (
              <th key={header.id}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                {header.column.getCanSort() && (
                  <span
                    style={{
                      fontSize: 12,
                      display: "inline-flex",
                      verticalAlign: "top",
                    }}
                    onClick={() => header.column.toggleSorting()}
                  >
                    <UnfoldMore fontSize="inherit" color="inherit" />
                  </span>
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>{renderRows()}</tbody>
    </table>
  );

  return (
    <>
      {renderTable}
      {<PaginationControls table={table} recordCount={records.length} />}
    </>
  );
}
