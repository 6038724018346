import React, { useState, useEffect } from "react";
import Button from "../Base/Button";
import httpClient from "../utils/httpClient";
import TableRowsLoadingSkeleton from "../TableRowsLoadingSkeleton";
import PaginationControls from "../Reports/Common/PaginationControls";
import DownloadReport from "../Reports/Common/DownloadReport";

import { Close } from "@material-ui/icons";
import { numberWithCommas, currencyWithCommas } from "../utils/NumberFormatter";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getExpandedRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";

type TransactionRow = {
  created_at: string;
  kind: string;
  product_name: string;
  rolling_purch: number;
  rolling_claims: number;
  rolling_balance: number;
  percent: number;
};

type AccountHistoryModalProps = {
  dailyBalancePath: string;
  query: any;
  displayModal: boolean;
  setDisplayModal: (arg1: boolean) => void;
};

const AccountHistoryModal = ({
  dailyBalancePath,
  query,
  displayModal,
  setDisplayModal,
}: AccountHistoryModalProps) => {
  const [ledger, setLedger] = useState<Array<TransactionRow>>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    httpClient
      .get(dailyBalancePath, { params: { ...query } })
      .then((res) => {
        // @ts-ignore need to fix this typing across the project
        setLedger(res.transactions);
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  }, []);

  const columnHelper = createColumnHelper<TransactionRow>();
  const columns = [
    columnHelper.accessor("created_at", {
      header: "Date",
      cell: ({ getValue }) => {
        return <div className="t--md">{getValue()}</div>;
      },
    }),
    columnHelper.accessor("kind", {
      header: "Type",
      cell: ({ getValue }) => {
        return <div className="t--sm t--500">{getValue()}</div>;
      },
    }),
    columnHelper.accessor("product_name", {
      header: "Product Name",
      cell: ({ getValue }) => {
        return <div className="t--sm t--500">{getValue()}</div>;
      },
    }),
    columnHelper.accessor("rolling_purch", {
      header: "Rolling Purchase Total",
      cell: ({ getValue }) => {
        return (
          <div className="t--md t--500">{currencyWithCommas(getValue())}</div>
        );
      },
    }),
    columnHelper.accessor("rolling_claims", {
      header: "Rolling Submissions Total",
      cell: ({ getValue }) => {
        return (
          <div className="t--md t--500">{currencyWithCommas(getValue())}</div>
        );
      },
    }),
    columnHelper.accessor("rolling_balance", {
      header: "Rolling Balance",
      cell: ({ getValue }) => {
        return (
          <div className="t--md t--500">{currencyWithCommas(getValue())}</div>
        );
      },
    }),
    columnHelper.accessor("percent", {
      header: "Percent",
      cell: ({ getValue }) => {
        return (
          <div className="t--md t--500">
            {numberWithCommas(getValue() * 100)}%
          </div>
        );
      },
    }),
  ];

  if (!displayModal) return null;

  const table = useReactTable<TransactionRow>({
    data: ledger,
    columns,
    getExpandedRowModel: getExpandedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: { pagination: { pageSize: 15 } },
  });

  const renderRows = () => {
    if (isLoading) return <TableRowsLoadingSkeleton rows={5} columns={7} />;

    return table.getRowModel().rows.map((row, index) => {
      return (
        <tr className="sm" key={index}>
          {row.getVisibleCells().map((cell) => (
            <td key={cell.id} className="t--md">
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          ))}
        </tr>
      );
    });
  };

  const downloadComponent = (
    <DownloadReport
      reportName="daily-balances"
      downloadPath={dailyBalancePath}
      downloadFilename={`transaction-history-${query.id_340b}-${query.pid}.xlsx`}
      downloadParams={query}
    />
  );

  return (
    <div
      className="modal__background"
      style={{ alignItems: "flex-end" }}
      onClick={() => setDisplayModal(false)}
    >
      <div
        className="modal__panel animated fadeInUpScale"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="container">
          <Button
            variant="link"
            className="modal__close"
            onClick={() => setDisplayModal(false)}
          >
            <Close />
          </Button>
          <div className="d-flex justify-content-between">
            <div className="page-details__title">Transaction History</div>
            <div className="mb-4">{downloadComponent}</div>
          </div>
          <div
            className="content__bordered_container"
            style={{ width: "100%", height: "auto" }}
          >
            <table className="primary">
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id} className="sm">
                    {headerGroup.headers.map((header) => (
                      <th key={header.id}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>{renderRows()}</tbody>
            </table>

            <PaginationControls table={table} recordCount={ledger.length} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountHistoryModal;
