import React from "react";
import StatCards from "./StatCards";
import AtRiskAccountsWidget from "./AtRiskAccountsWidget";
import SuspendedEntitiesWidget from "./SuspendedEntitiesWidget";
import ClaimsVolumeWidget from "./ClaimsVolumeWidget";
import ChannelParticipationWidget from "./ChannelParticipationWidget";

import { StatCardData } from "../types/models";

type DashboardProps = {
  organization: string;
  at_risk_status_history_path: string;
  suspended_entities_path: string;
  user_role: any;
  user_name: string;
  user_email: string;
  entities_count: StatCardData;
  pharmacies_count: StatCardData;
  hub_url: string;
  claims_data: any;
  at_risk_entities_path: string;
  channel_participation_data: {
    eligible_accounts_count: number;
    suspended_accounts_count: number;
  };
};

const Dashboard = (props: DashboardProps) => {
  const renderAtRiskAccounts = () => {
    return (
      <AtRiskAccountsWidget
        organization={props.organization}
        at_risk_status_history_path={props.at_risk_status_history_path}
        at_risk_entities_path={props.at_risk_entities_path}
      />
    );
  };

  return (
    <div className="dashboard__grid">
      <StatCards
        user_role={props.user_role}
        user_name={props.user_name}
        user_email={props.user_email}
        entities_count={props.entities_count}
        pharmacies_count={props.pharmacies_count}
        mfrProfileUrl={`/organization/${props.organization}/manufacturer`}
        hubUrl={props.hub_url}
        totalSalesDataUrl={`/organization/${props.organization}/total_sales`}
      />
      <div className="div5">
        <ClaimsVolumeWidget orgId={props.organization} />
      </div>
      <div className="div6">
        <ChannelParticipationWidget
          channelParticipationData={props.channel_participation_data}
        />
      </div>
      <div className="div7">
        <SuspendedEntitiesWidget
          organization={props.organization}
          suspended_entities_path={props.suspended_entities_path}
        />
      </div>
      <div className="div8">{renderAtRiskAccounts()}</div>
    </div>
  );
};

export default Dashboard;
