import React, { useState, useMemo } from "react";
import { search } from "../../utils/search";
import ReportSearch from "../Common/ReportSearch";
import Notice from "../../Notice";
import httpClient from "../../utils/httpClient";
import { MeatballMenu } from "../Common/MeatballMenu";
import { defaultDate } from "../../utils/dateFormatter";

import { Suspension } from "../../types/models";

type SuspendedEntitiesTableProps = {
  suspensions: Suspension[];
  suspensions_path: string;
};

const SuspendedEntitiesTable = ({
  suspensions,
  suspensions_path,
}: SuspendedEntitiesTableProps) => {
  const [searchTerms, setSearchTerms] = useState("");
  const [tableSuspensions, setTableSuspensions] =
    useState<Suspension[]>(suspensions);
  const [notice, setNotice] = useState({});

  const filteredEntities = useMemo(() => {
    return search(tableSuspensions, searchTerms, [
      "entity_name",
      "id_340b",
      "pharmacy_name",
    ]);
  }, [searchTerms]);

  const deleteSuspension = (id: string | number) => {
    httpClient
      .delete(suspensions_path + "/" + id)
      .then(() => {
        setTableSuspensions(tableSuspensions.filter((s) => s.id !== id));
        setNotice({
          kind: "success",
          open: true,
          message: "Success - you have successfully deleted the suspension",
        });
      })
      .catch(() => {
        setNotice({
          kind: "error",
          open: true,
          message: "An error occurred - please try again or contact support.",
        });
      });
  };

  const renderBadgeIndicator = (value: string) => {
    switch (value) {
      case "retail":
        return "light-blue";
      case "specialty":
        return "orange";
      case "pah":
        return "yellow";
      default:
        return "grey";
    }
  };

  const renderRows = () => {
    return filteredEntities.map((suspension, i) => {
      return (
        <tr className="sm" key={i}>
          <td className="t--sm t--500">
            <div className="table__text__stack">
              <div className="t--sm t--500">
                {suspension.id_340b.toUpperCase()}
              </div>
              <div className="t--sm t--subtitle">
                {suspension.entity_name.toUpperCase().substring(0, 35)}
              </div>
            </div>
          </td>
          <td className="t--sm t--500">
            <div className="table__text__stack">
              <div className="t--sm t--500">
                {suspension.pharmacy_name?.toUpperCase()}
              </div>
              <div className="t--sm t--subtitle">
                {suspension.pharmacy_address?.toUpperCase()?.substring(0, 35)}
              </div>
            </div>
          </td>
          <td className="t--sm t--500">{suspension.pid}</td>
          <td className="t--sm t--500">
            <div className="badge">
              <div
                className={`badge__indicator badge__indicator--${renderBadgeIndicator(
                  suspension.network
                )}`}
              />
              {suspension.network.toUpperCase()}{" "}
              {suspension.chain ? "CHAIN" : ""}
            </div>
          </td>
          <td className="t--sm t--500">
            {defaultDate(suspension.suspended_on)}
          </td>
          <td className="t--sm t--500">
            <MeatballMenu
              menuData={suspension}
              onModalConfirm={() => deleteSuspension(suspension.id)}
            />
          </td>
        </tr>
      );
    });
  };

  const renderTable = (
    <table className="primary">
      <thead>
        <tr className="sm">
          <th className="t--md">ENTITY</th>
          <th className="t--md">PHARMACY</th>
          <th className="t--md">PID</th>
          <th className="t--md">NETWORK</th>
          <th className="t--md">SUSPENDED ON</th>
          <th className="t--md">ACTIONS</th>
        </tr>
      </thead>
      <tbody>{renderRows()}</tbody>
    </table>
  );

  return (
    <div>
      <div className="table__actionbar table__actionbar--spacebetween">
        <ReportSearch setSearchTerms={setSearchTerms} />
      </div>
      {renderTable}
      <Notice details={notice} />
    </div>
  );
};

export default SuspendedEntitiesTable;
