export const DEFAULT_LEGEND_COLORS = [
  [0, "#E9EFF4"],
  [0.25, "#CBD4DB"],
  [0.5, "#ADB9C3"],
  [0.75, "#8F9DAA"],
  [1, "#718291"],
];

export const MAP_DATA_BASE_OPTIONS = {
  type: "choropleth",
  locationmode: "USA-states",
  marker: { line: { color: "#ffffff" } },
  showscale: false,
};

export const MAP_LAYOUT = {
  geo: {
    scope: "usa",
    showlakes: false,
    bgcolor: "rgba(0,0,0,0)",
  },
  paper_bgcolor: "rgba(0,0,0,0)",
  margin: { t: 0, r: 0, b: 0, l: 0, pad: 0 },
  width: 700,
  height: 450,
};

export const MAP_CONFIG = {
  showLink: false,
  displayModeBar: false,
  responsive: true,
};
