import React from "react";
import debounce from "debounce-promise";
import { Tooltip, withStyles } from "@material-ui/core";

const ReportSearchTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#fff",
    color: "#25265e",
    maxWidth: "300px",
    fontSize: "12px",
    border: "1px solid #dadde9",
    boxShadow: "0 6px 14px 0 rgba(76, 84, 153, 0.2)",
    borderRadius: "8px",
  },
}))(Tooltip);

type SearchTooltip = {
  title: string;
  placement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
};

const ReportSearch = ({
  setSearchTerms,
  tooltip = { title: "Search by entity name or 340B ID", placement: "top" },
}: {
  setSearchTerms: (arg1: string) => void;
  tooltip?: SearchTooltip;
}) => {
  const debouncedSetSearchTerms = debounce((newSearchTerm: string) => {
    setSearchTerms(newSearchTerm);
  }, 350);

  return (
    <div className="searchbar__wrapper">
      <div className="searchbar__container">
        <div className="searchbar__icon">
          <i className="solid solid-budicon-search-a" />
        </div>
        <ReportSearchTooltip
          title={tooltip.title}
          placement={tooltip.placement || "top"}
        >
          <input
            className="searchbar__input"
            type="text"
            placeholder="Search"
            onChange={(event) => debouncedSetSearchTerms(event.target.value)}
          />
        </ReportSearchTooltip>
      </div>
    </div>
  );
};

export default ReportSearch;
