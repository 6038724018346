import React, { PropsWithChildren } from "react";

type ReportDrawerProps = {
  download?: React.ReactNode;
  dateFilter?: React.ReactNode;
  filterComponents?: React.ReactNode[];
};

const ReportDrawer = ({
  download,
  dateFilter,
  filterComponents = [],
  children,
}: PropsWithChildren<ReportDrawerProps>) => {
  return (
    <div className="draw__container draw__container--scroll">
      {download ? (
        <>
          <div className="draw__tools__container">{download}</div>
          <div className="draw__compact_divider" />
        </>
      ) : null}
      {children}
      {dateFilter ? (
        <>
          {dateFilter}
          <div className="draw__divider" />
        </>
      ) : null}
      {filterComponents.map((fc: React.ReactNode, i: number) => {
        return (
          <div key={`filter-component-${i}`}>
            <div className="draw__item">{fc}</div>
            {i === filterComponents.length - 1 ? (
              <></>
            ) : (
              <div className="draw__divider" />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ReportDrawer;
