import React, { useState, useEffect } from "react";
import moment from "moment";
import _ from "lodash";
import Table from "./NonCompliantCEsTable";
import NetworkFilter from "../Common/NetworkFilter";
import ReportDrawer from "../Common/ReportDrawer";
import PolicyDate from "../Common/PolicyDate";
import DownloadReport from "../Common/DownloadReport";
import { currencyWithCommas } from "../../utils/NumberFormatter";
import { search } from "../../utils/search";

import ReportLayout from "../ReportLayout";
import httpClient from "../../utils/httpClient";
import { NetworkOption } from "../../types/models";

type NonCompliantCEsIndexProps = {
  networks: NetworkOption[];
  start_date: string;
  min_wac_threshold: number;
  threshold: number;
  entities_path: string;
  pharmacy_detail_path: string;
  entity_path: string;
  accounts_path: string;
  filename: string;
};

const NonCompliantCEsIndex = ({
  networks,
  start_date,
  min_wac_threshold,
  threshold,
  entities_path,
  pharmacy_detail_path,
  entity_path,
  accounts_path,
  filename,
}: NonCompliantCEsIndexProps) => {
  const [entities, setEntities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedNetwork, setSelectedNetwork] = useState("retail");
  const [searchTerms, setSearchTerms] = useState("");
  const queryParams = {
    network: selectedNetwork,
    threshold,
    date_range: { start: start_date, end: moment().format("YYYY-MM-DD") },
  };

  useEffect(() => {
    setLoading(true);

    httpClient
      .get(entities_path, { params: { ...queryParams } })
      // @ts-ignore todo: figure out axios typing
      .then((res) => setEntities(res.accounts))
      .finally(() => setLoading(false));
  }, [selectedNetwork]);

  const filteredData = search(entities, searchTerms, ["entity_name", "id_340b", "pharmacy_name"]);

  const downloadComponent = (
    <DownloadReport
      reportName="at-risk"
      downloadPath={entities_path}
      downloadFilename={filename}
      downloadParams={queryParams}
    />
  );

  const networkFilter = (
    <NetworkFilter
      networks={networks}
      selectedNetwork={selectedNetwork}
      setSelectedNetwork={setSelectedNetwork}
    />
  );

  const thresholdDisplay = (
    <>
      <div className="draw__item">
        <div className="draw__item__title draw__item__title--mb-sm t--upcase t--sm">Threshold</div>
        <div className="badge">{`${threshold * 100}% Percent`}</div>
      </div>
      <div className="draw__divider" />

      <div className="draw__item">
        <div className="draw__item__title draw__item__title--mb-sm t--upcase t--sm">
          WAC Threshold
        </div>
        <div className="badge">{`${currencyWithCommas(min_wac_threshold || 0)} WAC Dollars`}</div>
      </div>
    </>
  );

  return (
    <ReportLayout
      title={
        <div className="flex__container flex__container--align-center flex__container--gap-4">
          <span>At-Risk Covered Entities</span>
          <span className="badge">OVI</span>
        </div>
      }
      body={
        <Table
          entities={filteredData}
          entity_path={entity_path}
          accounts_path={accounts_path}
          pharmacy_detail_path={pharmacy_detail_path}
          isLoading={loading}
          setSearchTerms={setSearchTerms}
        />
      }
      aside={
        <ReportDrawer download={downloadComponent} filterComponents={[networkFilter]}>
          <PolicyDate date={start_date} />
          <div className="draw__divider" />
          {thresholdDisplay}
          <div className="draw__divider" />
        </ReportDrawer>
      }
    />
  );
};

export default NonCompliantCEsIndex;
