import axios from "axios";

let mosaicAxios = axios.create();
// Add a request interceptor
mosaicAxios.interceptors.request.use(
  function (config) {
    config.headers["Accept"] = "application/json";
    config.headers["Content-Type"] = "application/json";
    config.headers["X-CSRF-Token"] = document!
      .querySelector('meta[name="csrf-token"]')!
      .getAttribute("content");

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
mosaicAxios.interceptors.response.use(
  function (response) {
    if (response.data) return Promise.resolve(response.data);

    return Promise.resolve(response);
  },
  function (error) {
    console.log(error);
    return Promise.reject(error);
  }
);

export default {
  get: mosaicAxios.get,
  post: mosaicAxios.post,
  put: mosaicAxios.put,
  delete: mosaicAxios.delete,
  patch: mosaicAxios.patch,
};
