import React from "react";
import { Table } from "@tanstack/react-table";

type PaginationControlsProps = {
  table: Table<any>;
  recordCount: number;
};

const PaginationControls = ({
  table,
  recordCount,
}: PaginationControlsProps) => {
  if (table.getPageCount() === 1) return <></>;

  return (
    <div
      className="flex__container"
      style={{ justifyContent: "flex-end", marginTop: 10 }}
    >
      <button
        className="btn btn--small"
        onClick={() => table.previousPage()}
        disabled={!table.getCanPreviousPage()}
      >
        Prev
      </button>
      <button
        className="btn btn--small"
        style={{ marginLeft: 10 }}
        onClick={() => table.nextPage()}
        disabled={!table.getCanNextPage()}
      >
        Next
      </button>
    </div>
  );
};

export default PaginationControls;
