import React, { Fragment } from "react";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getExpandedRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";

import { EligibilityFile } from "../types/models";

type EligibilityFilesTableProps = {
  eligibility_files: EligibilityFile[];
};

const EligibilityFilesTable = ({
  eligibility_files,
}: EligibilityFilesTableProps) => {
  const columnHelper = createColumnHelper<EligibilityFile>();

  const columns = [
    columnHelper.accessor("file_name", {
      header: "File Name",
      cell: ({ getValue }) => {
        return <span className="t--500 t--sm">{getValue()}</span>;
      },
    }),
    columnHelper.accessor("network", {
      header: "Network",
      cell: ({ getValue }) => {
        return (
          <div className="table__text__stack">
            <div className="t--sm t--500">{getValue()}</div>
          </div>
        );
      },
    }),
    columnHelper.accessor("rows", {
      header: "Row Count",
      cell: ({ getValue }) => {
        return (
          <div className="table__text__stack">
            <div className="t--sm t--500">{getValue()}</div>
          </div>
        );
      },
    }),
    columnHelper.accessor("published", {
      header: "Publish Date",
      cell: ({ getValue }) => {
        return (
          <div className="table__text__stack">
            <div className="t--sm t--500">{getValue()}</div>
          </div>
        );
      },
    }),
    columnHelper.display({
      header: "actions",
      cell: ({}) => {
        return "--";
      },
    }),
  ];

  const table = useReactTable<EligibilityFile>({
    data: eligibility_files,
    columns,
    getExpandedRowModel: getExpandedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: { pagination: { pageSize: 50 } },
  });

  const renderSubComponent = (row) => {
    return null;
  };

  const renderRows = () => {
    return table.getRowModel().rows.map((row) => {
      return (
        <Fragment key={row.id}>
          <tr className="sm">
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id} className="t--md">
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
          {row.getIsExpanded() && (
            <tr>
              <td colSpan={row.getVisibleCells().length}>
                {renderSubComponent(row)}
              </td>
            </tr>
          )}
        </Fragment>
      );
    });
  };

  return (
    <div>
      <table className="primary">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className="sm">
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>{renderRows()}</tbody>
      </table>
    </div>
  );
};

export default EligibilityFilesTable;
