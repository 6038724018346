import React from "react";

const Chevron = ({ height = 48, width = 48, pointing = "right" }) => {
  const directionOptions = {
    up: "M14.15 30.75 12 28.6l12-12 12 11.95-2.15 2.15L24 20.85Z",
    right: "m18.75 36-2.15-2.15 9.9-9.9-9.9-9.9 2.15-2.15L30.8 23.95Z",
    down: "m24 30.75-12-12 2.15-2.15L24 26.5l9.85-9.85L36 18.8Z",
    left: "M28.05 36 16 23.95 28.05 11.9l2.15 2.15-9.9 9.9 9.9 9.9Z",
  };
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" height={height} width={width}>
      <path d={directionOptions[pointing]} fill="currentColor" />
    </svg>
  );
};

export default Chevron;
