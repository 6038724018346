import React, { Fragment } from "react";
import DatePicker from "react-datepicker";
import ButtonGroup from "../Base/ButtonGroup";
import { CalendarMonth } from "@mui/icons-material";

const DateSelector = (props) => {
  const buttons = ["This Month", "Last 3 Months", "Policy to Date"];

  return (
    <Fragment>
      <ButtonGroup buttons={buttons} activeButton={props.activeButton} setActiveButton={props.setActiveButton} />
      <div className="flex__container flex__container--space-between">
        <div className="label label--grey">
          <div className="label__icon">
            <CalendarMonth fontSize="inherit" />
          </div>
          <div className="label__value">
            <DatePicker
              dateFormat="yyyy-MM-dd"
              selected={props.startDate}
              onChange={props.onStartDateChangeEventEmit}
              selectsStart
            />
          </div>
        </div>
        <div className="label label--grey">
          <div className="label__icon">
            <CalendarMonth fontSize="inherit" />
          </div>
          <div className="label__value">
            <DatePicker
              dateFormat="yyyy-MM-dd"
              selected={props.endDate}
              onChange={props.onEndDateChangeEventEmit}
              selectsEnd
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default DateSelector;
