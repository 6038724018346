import { useEffect, useState } from "react";
import httpClient from "../utils/httpClient";

export default function useEntityFetch({
  endpoint,
  query,
}: {
  endpoint: string;
  query: any;
}) {
  const { id_340b } = query;
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<any>({});
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsLoading(true);

    httpClient
      .get(`${endpoint}?id_340b=${id_340b}`)
      .then((res) => setData(res.data))
      .catch((error) => setError(error.message))
      .finally(() => setIsLoading(false));
  }, [id_340b]);

  return { isLoading, data, error };
}
