import React from "react";
import ChipSelector from "../../ChipSelector";

type PharmacyTypeFilterProps = {
  types: Array<{ label: string; value: string }>;
  selectedType: string | null;
  setSelectedType: (arg1: string) => void;
  required?: boolean;
};

const PharmacyTypeFilter = ({
  types,
  selectedType,
  setSelectedType,
  required = true,
}: PharmacyTypeFilterProps) => {
  return (
    <>
      <div className="draw__item__title draw__item__title--mb-sm t--upcase t--sm">
        Categories
      </div>
      <div className="chip__well">
        <ChipSelector
          required={required}
          items={types}
          selected={selectedType}
          setSelected={setSelectedType}
        />
      </div>
    </>
  );
};

export default PharmacyTypeFilter;
