import React from "react";
import { ResponsiveRadialBar } from "@nivo/radial-bar";

const CenteredMetric =
  (content) =>
  ({ center, bars }) => {
    const { value, color } = bars[0];
    return (
      <text
        x={center[0] + 2}
        y={center[1] + 4}
        textAnchor="middle"
        fill={color || "currentColor"}
        style={{ fontSize: 16 }}
      >
        {content || value}
      </text>
    );
  };

const ThickerPath =
  (threshold) =>
  ({ center, bars, outerRadius, innerRadius, arcGenerator }) => {
    const { startAngle, endAngle } = bars[0].arc;
    const pizzaPie = (threshold / 100) * Math.PI - Math.PI / 2;
    const pathData = arcGenerator({
      startAngle,
      endAngle,
      innerRadius,
      outerRadius,
    });
    const thresholdData = arcGenerator({
      startAngle: pizzaPie - 0.09,
      endAngle: pizzaPie,
      innerRadius,
      outerRadius,
    });

    return (
      <g transform={`translate(${center[0]},${center[1]})`}>
        <path d={pathData} fill={bars[0].color} />
        <path d={thresholdData} fill="#ffffff" />
      </g>
    );
  };

const RadialBarChart = ({
  chartData,
  chartConfig,
  hasCenteredMetric = false,
  hasThickerPath = false,
  centeredContent,
  thresholdIndicator = 75,
}) => {
  const { height = 100, width = 100, startAngle = 0, endAngle = 360, innerRadius = 0.7 } = chartConfig;
  const { top = 0, right = 0, bottom = 0, left = 0 } = chartConfig?.margin || {};
  return (
    <div style={{ height, width }}>
      <ResponsiveRadialBar
        margin={{ top, right, bottom, left }}
        maxValue={100}
        startAngle={startAngle}
        endAngle={endAngle}
        padAngle={2}
        enableCircularGrid={false}
        enableRadialGrid={false}
        enableTracks={true}
        tracksColor="#dee5eb"
        cornerRadius={0}
        innerRadius={innerRadius}
        colors="#3246D3"
        data={chartData}
        layers={[
          "tracks",
          "bars",
          hasCenteredMetric && CenteredMetric(centeredContent),
          hasThickerPath && ThickerPath(thresholdIndicator),
        ]}
        tooltip={({ bar: { groupId, value, color } }) => (
          <span style={{ color, backgroundColor: "#ffffff" }}>
            {groupId}: {value}%
          </span>
        )}
      />
    </div>
  );
};

export default RadialBarChart;
