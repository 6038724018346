import React from "react";

import ClaimsHistoryTable from "./ClaimsHistoryTable";
import ReportLayout from "../ReportLayout";
import DownloadReport from "../Common/DownloadReport";
import ReportDrawer from "../Common/ReportDrawer";
import { ClaimHistoryRow } from "../../types/models";

type ClaimsHistoryIndexProps = {
  submissions: ClaimHistoryRow[];
  download_path: string;
  filename: string;
};

const ClaimsHistoryIndex = ({ submissions, download_path, filename }: ClaimsHistoryIndexProps) => {
  const downloadComponent = (
    <DownloadReport
      reportName="claims-history"
      downloadPath={download_path}
      downloadFilename={filename}
      downloadParams={{}}
    />
  );

  return (
    <ReportLayout
      title="Claims History"
      body={<ClaimsHistoryTable submissions={submissions} />}
      aside={<ReportDrawer download={downloadComponent} />}
    />
  );
};

export default ClaimsHistoryIndex;
