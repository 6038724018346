import React from "react";
import Button from "./Button";

type ButtonGroupProps = {
  buttons: string[];
  activeButton: string;
  setActiveButton: (arg1: string) => void;
};

const ButtonGroup = ({
  buttons,
  activeButton,
  setActiveButton,
}: ButtonGroupProps) => {
  return (
    <div className="btn__option__group btn__option__group--even-width btn__option__group--mb">
      {buttons.map((btn) => (
        <Button
          variant="link"
          key={btn}
          className={`${
            btn === activeButton
              ? "btn btn--sm btn--even-width btn--selected"
              : "btn btn--sm btn--even-width"
          }`}
          onClick={() => setActiveButton(btn)}
        >
          {btn}
        </Button>
      ))}
    </div>
  );
};

export default ButtonGroup;
