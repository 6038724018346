import React, { Fragment } from "react";

import EligibilityCard from "./EligibilityCard";
// import ActivityFeed from "./ActivityFeed";

const Draw = (props) => {
  return (
    <div>
      <EligibilityCard />
      <div className="feed">
        <div className="t--md t--500">Recent Activity</div>
      </div>
      {/* <ActivityFeed /> */}
    </div>
  );
};

export default Draw;
