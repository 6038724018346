import React from "react";

const SortingArrows = ({ height = 18, width = 18 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" height={height} width={width}>
      <path
        d="M5.494 5.568a.653.653 0 0 1-.183-.484c0-.2.06-.36.183-.483l2.05-2.05a.589.589 0 0 1 .217-.142.82.82 0 0 1 .509 0 .454.454 0 0 1 .207.142l2.067 2.067c.122.122.18.28.175.474a.67.67 0 0 1-.192.476.653.653 0 0 1-.483.183.653.653 0 0 1-.483-.183l-1.55-1.55-1.567 1.566a.617.617 0 0 1-.475.176.673.673 0 0 1-.475-.192zm2.517 8.167a.628.628 0 0 1-.25-.05.877.877 0 0 1-.217-.134l-2.05-2.05a.653.653 0 0 1-.183-.483c0-.2.06-.361.183-.484a.653.653 0 0 1 .483-.183c.2 0 .361.061.484.183l1.55 1.55 1.566-1.566a.615.615 0 0 1 .475-.175.67.67 0 0 1 .475.191.653.653 0 0 1 .184.484c0 .2-.062.36-.184.483l-2.05 2.05a.693.693 0 0 1-.467.183z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SortingArrows;
