import React, { useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const CustomTextField = withStyles({
  root: {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    "& label.Mui-focused": {
      color: "#3D71FF",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
      "& fieldset": {
        borderColor: "#DEE7EE",
      },
      "&:hover fieldset": {
        borderColor: "#DEE7EE",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3D71FF",
      },
    },
  },
})(TextField);

const Blocked = (props) => {

  const renderForm = () => {
    return (
      <div className="auth__form">
        <h1>{props.title}</h1>
        <p>{props.message}</p>
        <form method="POST" action="/logout" className="authentication__form">
            <button className="btn" style={{ flexGrow: 1, alignSelf: "inherit" }} type="submit">
              Logout
            </button>
            <div className="authentication__info" style={{ marginTop: 20 }}>
              <span>Need help? </span>
              <a className="authentication__link" href="/">
                Contact Us
              </a>
            </div>
        </form>
      </div>
    );
  };

  const renderBillboard = () => {
    return (
      <div className="">
        <div className="auth__container--billboard__title">
          <div style={{ maxWidth: 500 }}>
            <span className="t--xl t--500">340B ESP™</span>
            <br />
            Linking Healthcare
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="auth__wrapper">
      <div className="auth__container">
        <div className="auth__container--billboard">{renderBillboard()}</div>
        <div className="auth__container--form">{renderForm()}</div>
      </div>
    </div>
  );
};

export default Blocked;
