import React, { Fragment } from "react";
import _ from "lodash";
import { ExpandMore, ExpandLess } from "@material-ui/icons";
import SubTable from "./SubTable";
import PaginationControls from "../Common/PaginationControls";
import TableRowsLoadingSkeleton from "../../TableRowsLoadingSkeleton";
import { currencyWithCommas } from "../../utils/NumberFormatter";
import { CeCpTransactionRow } from "../../types/models";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getExpandedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";

const CeCpTable = ({ transactions, isLoading }) => {
  const columnHelper = createColumnHelper<CeCpTransactionRow>();

  const columns = [
    columnHelper.display({
      id: "expand",
      cell: ({ row }) => {
        return (
          <div
            className="btn btn--sqr"
            style={{ verticalAlign: "middle" }}
            onClick={() => {
              row.toggleExpanded();
            }}
          >
            {row.getIsExpanded() ? (
              <ExpandLess fontSize="small" />
            ) : (
              <ExpandMore fontSize="small" />
            )}
          </div>
        );
      },
    }),
    columnHelper.accessor("id_340b", {
      header: "Covered Entity",
      enableSorting: false,
      enableResizing: false,
      cell: ({ row, renderValue }) => {
        return (
          <div className="table__text__stack">
            <div className="t--sm t--500">{renderValue()}</div>
            <div className="t--sm t--subtitle t--upcase">
              {row.original.entity_name}
            </div>
          </div>
        );
      },
    }),
    columnHelper.accessor("pharmacy_id", {
      header: "Pharmacy",
      enableSorting: false,
      enableResizing: false,
      cell: ({ row, renderValue }) => {
        return (
          <div className="table__text__stack">
            <div className="t--sm t--500">{renderValue()}</div>
            <div className="t--sm t--subtitle t--upcase">
              {row.original.pharmacy_name}
            </div>
          </div>
        );
      },
    }),
    columnHelper.accessor("pharmacy_type_flag", {
      header: "Pharmacy Type Flag (CP/CE)",
      enableSorting: false,
      enableResizing: false,
      cell: ({ renderValue }) => {
        return (
          <div className="table__text__stack">
            <div className="t--sm t--500">{renderValue()}</div>
          </div>
        );
      },
    }),
    columnHelper.accessor("network", {
      header: "Network",
      enableSorting: false,
      enableResizing: false,
      cell: ({ renderValue }) => {
        return (
          <div className="table__text__stack">
            <div className="t--sm t--500">{renderValue()}</div>
          </div>
        );
      },
    }),
    columnHelper.accessor("total_purchases", {
      header: "Total Purchases",
      enableSorting: false,
      enableResizing: false,
      cell: ({ renderValue }) => {
        return (
          <div className="table__text__stack">
            <div className="t--sm t--500">
              {currencyWithCommas(renderValue())}
            </div>
          </div>
        );
      },
    }),
  ];

  const table = useReactTable<CeCpTransactionRow>({
    data: transactions,
    columns,
    getExpandedRowModel: getExpandedRowModel(),
    getCoreRowModel: getCoreRowModel<CeCpTransactionRow>(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: { pagination: { pageSize: 50 } },
  });

  const renderSubComponent = (row) => {
    return (
      <SubTable
        details={_.orderBy(
          row.original.product_details,
          ["sales", "product_name"],
          ["desc", "asc"]
        )}
      />
    );
  };

  const renderedRows = table.getRowModel().rows.map((row) => {
    return (
      <Fragment key={row.id}>
        <tr className="sm">
          {row.getVisibleCells().map((cell) => (
            <td key={cell.id} className="t--sm">
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          ))}
        </tr>
        {row.getIsExpanded() && (
          <tr>
            <td colSpan={row.getVisibleCells().length}>
              {renderSubComponent(row)}
            </td>
          </tr>
        )}
      </Fragment>
    );
  });

  const renderTable = (
    <table className="primary">
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id} className="sm">
            {headerGroup.headers.map((header, i) => (
              <th
                key={header.id}
                style={i > 4 ? { textAlign: "right" } : { textAlign: "left" }}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {isLoading ? (
          <TableRowsLoadingSkeleton rows={10} columns={6} />
        ) : (
          renderedRows
        )}
      </tbody>
    </table>
  );

  return (
    <div>
      {renderTable}
      <PaginationControls table={table} recordCount={transactions.length} />
    </div>
  );
};

export default CeCpTable;
