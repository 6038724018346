import React, { useState } from "react";
import useEntityFetch from "./useEntityFetch";
import TableRowsLoadingSkeleton from "../TableRowsLoadingSkeleton";
import PharmacyDetailsModal from "./PharmacyDetailsModal";

const OviTable = ({ query, resource, id_340b, organization_id }) => {
  const { isLoading, data } = useEntityFetch({
    query,
    endpoint: `${resource}/ovi`,
  });
  const [displayDetails, setDisplayDetails] = useState(false);
  const [currentPharmacy, setCurrentPharmacy] = useState(null);

  return (
    <>
      <table className="primary">
        <thead>
          <tr className="sm">
            <th style={{ width: "10px" }} className="rnd-l"></th>
            <th>Pharmacy ID</th>
            <th>Pharmacy Name</th>
            <th style={{ width: "100px" }}>Status</th>
            <th style={{ width: "178px" }} className="rnd-r">
              Effective Date
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <TableRowsLoadingSkeleton rows={5} columns={5} />
          ) : (
            (data?.pharmacies || []).map((row, i) => {
              return (
                <tr className="sm t--sm no-bottom-border" key={i}>
                  <td></td>
                  <td>{row.pid}</td>
                  <td className="t--sm">
                    <div className="table__text__stack">
                      <div className="t--sm t--500">{row.pharmacy_label || row.pharmacy_name}</div>
                      <div className="t--sm t--subtitle">{row.pharmacy_address}</div>
                    </div>
                  </td>
                  <td>
                    <div className="badge">
                      <div className={`badge__indicator badge__indicator`} />
                      {row.status}
                    </div>
                  </td>
                  <td>{row.effective_date}</td>
                  <td>
                    <button
                      className="btn btn--light"
                      onClick={() => {
                        setCurrentPharmacy(row);
                        setDisplayDetails(true);
                      }}
                    >
                      View Details
                    </button>
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
      {displayDetails && currentPharmacy && (
        <PharmacyDetailsModal
          setDisplayModal={setDisplayDetails}
          id_340b={id_340b}
          organization_id={organization_id}
          pharmacy={currentPharmacy}
        />
      )}
    </>
  );
};

export default OviTable;
