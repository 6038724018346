import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import ChipSelector from "../ChipSelector";
import Toggle from "../Toggle";
import DateSelector from "../DateSelector";
import MultiProductFilter from "../Reports/Common/MultiProductFilter";
import MultiChipSelector from "../MultiChipSelector";
import CoveredEntitySelector from "../Reports/Common/CoveredEntitySelector";
import { NetworkOption, ProductOption } from "../types/models";

type AccountsDrawProps = {
  handleSearch: (arg1: string, arg2: string | string[]) => void;
  handleFilter: (arg1: string, arg2: string | null) => void;
  filterQuery: {
    status: string | null;
  };
  initialEntity: {
    value: string;
    label: string;
  };
  organizationId: string;
  policyStartDate: string;
  showInHouseFilter: boolean;
  toggleOverdrawnOnly: boolean;
  searchQuery: {
    pharmacy_type: string;
    date_range: {
      start: string;
      end: string;
    };
  };
  networks: NetworkOption[];
  products: ProductOption[];
};

const AccountsDraw = (props: AccountsDrawProps) => {
  const [selectedEntityId, setSelectedEntityId] = useState(
    props.initialEntity.value
  );
  const [selectedProductNames, setSelectedProductNames] = useState<
    Array<string>
  >([]);
  const [status, setStatus] = useState(props.filterQuery.status);
  const [selectedNetworks, setSelectedNetworks] = useState<Array<string>>([]);

  useEffect(() => {
    props.handleSearch("id_340b", selectedEntityId);
  }, [selectedEntityId]);

  useEffect(() => {
    props.handleSearch("product_names", selectedProductNames);
  }, [selectedProductNames]);

  useEffect(() => {
    props.handleFilter("status", status);
  }, [status]);

  const setDateRange = (dateRange) => {
    props.handleSearch("date_range", dateRange);
  };

  useEffect(() => {
    props.handleSearch("networks", selectedNetworks);
  }, [selectedNetworks]);

  const pharmacyTypeOptions = [
    { value: "contract", label: "Contract Pharmacy" },
    ...(props.showInHouseFilter
      ? [{ value: "inhouse", label: "In-House" }]
      : []),
  ];

  return (
    <div>
      <DateSelector
        dateRange={props.searchQuery.date_range}
        setDateRange={setDateRange}
        policyStartDate={props.policyStartDate}
      />
      <div className="draw__divider" />
      <div className="draw__item">
        <CoveredEntitySelector
          selectedEntityId={selectedEntityId}
          setSelectedEntityId={setSelectedEntityId}
          organizationId={props.organizationId}
        />
      </div>
      <div className="draw__divider" />
      <div className="draw__item">
        <div className="draw__item__title draw__item__title--mb-sm t--upcase t--sm">
          Account Status
        </div>
        <Toggle
          setValue={setStatus}
          enabled_value={"NEGATIVE"}
          disabled_value={null}
          label={"Overdrawn Accounts Only"}
          defaultChecked={props.toggleOverdrawnOnly}
        />
      </div>
      <div className="draw__divider" />
      <div className="draw__item">
        <div className="draw__item__title draw__item__title--mb-sm t--upcase t--sm">
          Type
        </div>
        <div className="chip__well">
          <ChipSelector
            items={pharmacyTypeOptions}
            selected={props.searchQuery.pharmacy_type}
            setSelected={(value) => props.handleSearch("pharmacy_type", value)}
            required={true}
          />
        </div>
      </div>
      <div className="draw__divider" />
      <div className="draw__item">
        <MultiProductFilter
          products={props.products}
          selectedProductNames={selectedProductNames}
          setSelectedProductNames={setSelectedProductNames}
        />
      </div>
      <div className="draw__divider" />
      <div className="draw__item">
        <div className="draw__item__title draw__item__title--mb-sm t--upcase t--sm">
          Network Type
        </div>
        <div className="chip__well">
          <MultiChipSelector
            options={props.networks}
            selected={selectedNetworks}
            handleSelection={setSelectedNetworks}
          />
        </div>
      </div>
    </div>
  );
};

export default AccountsDraw;
