import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";

mapboxgl.accessToken = "pk.eyJ1IjoiZGMtZGFycmVuIiwiYSI6ImNreTNjaDhxZTAwb2cyb3BiYWtxd3Q0d3kifQ.jALTlPgOxgKMclZO2c2qZQ";

const ContractPharmaciesMap = (props) => {
  const { coveredEntityCenter, pharmacies } = props;

  const mapContainer = useRef(null);
  const [map, setMap] = useState(null);
  const [marker, setMarker] = useState(null);

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/dc-darren/cky3ciu972qj915oyx3y737yq",
      center: coveredEntityCenter,
      zoom: 2,
    });

    const initialMarker = new mapboxgl.Marker({ color: "#0A162F" });
    setMarker(initialMarker);
    initialMarker
      .setLngLat(coveredEntityCenter)
      .setPopup(
        new mapboxgl.Popup({ closeOnMove: true, closeButton: false }).setText(
          `${pharmacies.length} Contract Pharmacies`
        )
      )
      .addTo(map);

    map.on("load", () => {
      map.addSource("cps", {
        type: "geojson",
        data: { type: "FeatureCollection", features: pharmacies },
      });

      map.addLayer({
        id: "cps",
        source: "cps",
        type: "circle",
        paint: {
          "circle-radius": 6,
          "circle-color": "#3246D3",
        },
      });

      setMap(map);
    });

    return () => map.remove();
  }, []);

  useEffect(() => {
    if (map) {
      marker
        .setLngLat(coveredEntityCenter)
        .setPopup(
          new mapboxgl.Popup({ closeOnMove: true, closeButton: false }).setText(
            `${pharmacies.length} Contract Pharmacies`
          )
        )
        .addTo(map);
      map.flyTo({ center: coveredEntityCenter, speed: 1.5, curve: 1.5, zoom: 4 });
      map.getSource("cps").setData({ type: "FeatureCollection", features: pharmacies });
    }
  }, [coveredEntityCenter, pharmacies]);

  return <div ref={mapContainer} className="map__container" />;
};

export default ContractPharmaciesMap;
