import React from "react";
import TileLoadingSkeleton from "./TileLoadingSkeleton";
import ContractPharmaciesMap from "./ContractPharmaciesMap";
import Tile from "../Base/Tile";

const ContractPharmaciesTile = ({
  isLoading,
  pharmaciesMapData,
  entityDetails,
}) => {
  const {
    isEntityLoading,
    data: { lonlat },
    entityError,
  } = entityDetails;

  return (
    <div style={{ gridColumn: "3 / span 2" }}>
      {isLoading || isEntityLoading ? (
        <TileLoadingSkeleton />
      ) : (
        <Tile>
          <Tile.Header>
            <Tile.SecondaryText className="t--500">
              Contract Pharmacy Locations
            </Tile.SecondaryText>
          </Tile.Header>
          <Tile.Body>
            <div style={{ height: 258 }}>
              {lonlat?.length && pharmaciesMapData?.length && (
                <ContractPharmaciesMap
                  coveredEntityCenter={lonlat}
                  pharmacies={pharmaciesMapData}
                />
              )}
            </div>
          </Tile.Body>
        </Tile>
      )}
    </div>
  );
};

export default ContractPharmaciesTile;
