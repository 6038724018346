import React, { useState, useEffect, useRef } from "react";
import classnames from "classnames";
import User from "../Icons/User";
import Flag from "../Icons/Flag";
import Button from "../Base/Button";

const ActivityFeed = (props) => {
  const [hasNewActivity, setHasNewActivity] = useState(false);
  const [isScrollIntoView, setIsScrollIntoView] = useState(false);
  const topRef = useRef(null);

  useEffect(() => {
    const timeOut = setTimeout(() => setHasNewActivity(true), 5000);
    return () => clearTimeout(timeOut);
  }, []);

  useEffect(() => {
    if (isScrollIntoView) {
      topRef.current.scrollIntoView();
    }
  }, [isScrollIntoView]);

  const renderActivityItems = () => {
    return feed.map((item) => {
      // Hacky ref will only work if one item. Need better solution
      const { content, link, linkUrl } = item.message;

      return (
        <div className="activity__container" key={item.id}>
          <header className="activity__header">
            <div className="activity__icon"></div>
            <span className="feed__user">{item.user}</span>
            <span className="feed__time">{item.time}</span>
          </header>
          <div className="feed__message">
            <p>{content}</p>
            {link && (
              <a href={linkUrl} className="feed__link">
                {link}
              </a>
            )}
          </div>
        </div>
      );
    });
  };

  const renderActivityFeed = () => {
    return <div>{renderActivityItems()}</div>;
  };

  return (
    <div className="feed">
      <div className="t--md t--500">Recent Activity</div>
      {renderActivityFeed()}
    </div>
  );
};

export default ActivityFeed;
