import { useState } from 'react';
import axios from "axios";

const useFileDownload = ():
  [
    (fileUrl: string, defaultFileName: string, identifier: string, params?: any) => void,
    {}
  ] => {
  const [downloadStates, setDownloadStates] = useState({});

  const handleDownload = (fileUrl: string, defaultFileName: string, identifier: string, params = {}) => {
    const downloadState = {
      isDownloading: false,
      hasDownloadError: false
    };

    setDownloadStates((prevState) => ({
      ...prevState,
      [identifier]: downloadState
    }));

    downloadState.isDownloading = true;

    setDownloadStates((prevState) => ({
      ...prevState,
      [identifier]: downloadState
    }));

    axios.get(fileUrl, { params, responseType: "blob" })
      .then((response) => {
        const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
        const downloadLink = document.createElement("a");

        downloadLink.href = blobUrl;
        downloadLink.download = defaultFileName;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }).catch(() => {
        downloadState.hasDownloadError = true;
        setDownloadStates((prevState) => ({
          ...prevState,
          [identifier]: downloadState
        }));
      }).finally(() => {
        downloadState.isDownloading = false;
        setDownloadStates((prevState) => ({
          ...prevState,
          [identifier]: downloadState
        }));
      });
  };

  return [handleDownload, downloadStates];
};

export default useFileDownload;
