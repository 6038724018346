import React, { useState, useMemo } from "react";
import { UnfoldMore } from "@material-ui/icons";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  createColumnHelper,
  getPaginationRowModel,
  ColumnSort,
} from "@tanstack/react-table";
import PaginationControls from "../Common/PaginationControls";
import TableRowsLoadingSkeleton from "../../TableRowsLoadingSkeleton";

import { Claim } from "../../types/models";

type ClaimsTableProps = {
  claims: Claim[];
  isLoading: boolean;
};

export default function ClaimsTable({ claims, isLoading }: ClaimsTableProps) {
  const columnHelper = createColumnHelper<Claim>();
  const [sorting, setSorting] = useState<ColumnSort[]>([]);

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.ndc, {
        header: "Ndc",
        enableSorting: false,
        cell: ({ row, getValue }) => {
          return (
            <div>
              <div className="t--sm t--500">{row.original.product_name.toUpperCase()}</div>
              <div className="t--sm t--subtitle">{getValue()}</div>
            </div>
          );
        },
      }),
      columnHelper.accessor("quantity", {
        header: "Quantity",
        cell: ({ renderValue }) => <div className="t--md t--500">{renderValue()}</div>,
      }),
      columnHelper.accessor("service_provider_id", {
        header: "Service Provider ID",
        enableSorting: false,
      }),
      columnHelper.accessor("contracted_entity_id", {
        header: "340B ID",
        enableSorting: false,
        cell: ({ renderValue }) => <div className="t--sm t--500">{renderValue()}</div>,
      }),
      columnHelper.accessor((row) => row.status, {
        header: "Status",
        enableSorting: false,
        cell: ({ row, getValue }) => {
          return (
            <div className="badge">
              <div
                className={`badge__indicator badge__indicator--${getIndicatorName(getValue())}`}
              />
              {getValue()}
            </div>
          );
        },
      }),
      columnHelper.accessor("submission_date", {
        header: "Date Submitted",
        cell: ({ renderValue }) => <div className="t--sm t--500">{renderValue()}</div>,
      }),
    ],
    [claims]
  );

  const getIndicatorName = (name) => {
    var status = {
      CONFORMING: "green",
      "NON-CONFORMING": "skyblue",
      "INVALID 340B ID": "blue",
      "NOT A CONTRACT PHARMACY": "orange",
    };

    return status[name];
  };

  const table = useReactTable<Claim>({
    data: claims,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: { pagination: { pageSize: 50 } },
  });

  const renderRows = () => {
    return table.getRowModel().rows.map((row) => {
      return (
        <tr key={row.id} className="sm">
          {row.getVisibleCells().map((cell) => {
            return (
              <td key={cell.id} className="t--sm">
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            );
          })}
        </tr>
      );
    });
  };

  return (
    <>
      <table className="primary">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className="sm">
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())}
                  {header.column.getCanSort() && (
                    <span
                      style={{ fontSize: 12, display: "inline-flex", verticalAlign: "top" }}
                      onClick={() => header.column.toggleSorting()}
                    >
                      <UnfoldMore fontSize="inherit" color="inherit" />
                    </span>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {isLoading ? <TableRowsLoadingSkeleton rows={50} columns={6} /> : renderRows()}
        </tbody>
      </table>
      <PaginationControls table={table} recordCount={claims.length} />
    </>
  );
}
