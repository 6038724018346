import React, { useState } from "react";
import verifyImage from "~/images/verify_account_illustration.png";

const ConfirmationModal = ({ setModalState, action, title, displayModal, message }) => {
  const [submitting, setSubmitting] = useState(false);

  const handleSubmitting = () => {
    setSubmitting(true);
    setModalState(false);

    if (!submitting) {
      action();
    }
  };

  const renderTitle = title ?? "Confirmation needed";
  const loader = submitting ? <div className="loading-indicator"></div> : null;

  const renderModal = () => {
    if (displayModal) {
      return (
        <div className="modal__background">
          <div className="modal__body modal__body--no-side-padding" style={{ paddingTop: 30, paddingBottom: 30 }}>
            <div className="modal__body__title">Are you sure?</div>
            <div className="modal__body__subtitle" style={{ marginBottom: 30 }}>
              Please confirm before continuing
            </div>

            <div className="modal__body__spotlight">
              <img src={verifyImage} width={220} />
            </div>
            <div className="modal__body__container">
              <div className="modal__body__container__note__title">{renderTitle}</div>
              <div className="modal__body__container__note">{message ?? null}</div>
            </div>
            <div className="modal__body__footer">
              <div
                className="btn btn--white btn--outline"
                style={{ flexGrow: 1 }}
                onClick={() => setModalState(false)}
              >
                Cancel
              </div>
              <div
                className="btn"
                id="confirmButton"
                style={{ marginLeft: 10 }}
                onClick={handleSubmitting}
              >
                {loader}
                Confirm
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  return renderModal();
};
export default ConfirmationModal;
