import React, { useState, CSSProperties } from "react";
import { Pie } from "@nivo/pie";
import Tile from "../Base/Tile";

const COLOR_MAP = {
  eligible: "#3246D3",
  suspended: "#FF681F",
};

type ChannelParticipationWidgetProps = {
  channelParticipationData: {
    eligible_accounts_count: number;
    suspended_accounts_count: number;
  };
};

export default function ChannelParticipationWidget({
  channelParticipationData,
}: ChannelParticipationWidgetProps) {
  return (
    <>
      <Tile>
        <Tile.Header className="justify-content-between">
          <div className="d-flex flex-column gap-1">
            <Tile.SecondaryText className="t--500">
              Channel Participation
            </Tile.SecondaryText>
            <Tile.TertiaryText>Updated Daily</Tile.TertiaryText>
          </div>
        </Tile.Header>
        <Tile.Body className="d-flex gap-3">
          <CardBody
            eligibleData={channelParticipationData.eligible_accounts_count}
            suspendedData={channelParticipationData.suspended_accounts_count}
          />
        </Tile.Body>
      </Tile>
    </>
  );
}

function CardBody({ eligibleData, suspendedData }) {
  const [activeChunk, setActiveChunk] = useState("eligible");
  const total = eligibleData + suspendedData;
  const eligiblePercent =
    total > 0 ? Math.floor((eligibleData / total) * 100) : 0;
  const suspendedPercent = total > 0 ? 100 - eligiblePercent : 0;

  const data = [
    {
      id: "eligible",
      label: "Eligible",
      value: eligibleData,
      percent: `${eligiblePercent}%`,
    },
    {
      id: "suspended",
      label: "Suspended",
      value: suspendedData,
      percent: `${suspendedPercent}%`,
    },
  ];

  return (
    <>
      <div className="flex__container flex__container--col flex__container--align-center">
        <Pie
          data={data}
          width={180}
          height={120}
          margin={{ top: 16, right: 8, bottom: 0, left: 8 }}
          innerRadius={0.75}
          startAngle={-90}
          endAngle={90}
          activeInnerRadiusOffset={4}
          activeOuterRadiusOffset={4}
          enableArcLabels={false}
          enableArcLinkLabels={false}
          colors={Object.values(COLOR_MAP)}
          onClick={(chunk) => setActiveChunk(String(chunk.id))}
          layers={["arcs", CustomArcLayer(activeChunk)]}
          tooltip={({ datum }) => <Tooltip {...datum} />}
        />
        <span
          className="t--sm t--subtitle"
          style={{ textAlign: "center", marginTop: 10 }}
        >
          Breakdown of Channel Participation
        </span>
      </div>
      <div className="list__container list__container--overflow-hidden">
        {data.map((d) => {
          return (
            <div
              onClick={() => setActiveChunk(d.id)}
              key={d.id}
              className={
                activeChunk == d.id
                  ? "list__item list__item--rad list__item--spread list__item--active"
                  : "list__item list__item--rad list__item--spread"
              }
            >
              <div className="flex__container flex__container--align-center">
                <div
                  className="indicator"
                  style={{ backgroundColor: COLOR_MAP[d.id] }}
                ></div>
                <div className="t--md">{d.label}</div>
              </div>

              <div className="progress_bar__wrapper">
                <div
                  className="progress_bar__container progress_bar__container--sm"
                  style={{ overflow: "hidden" }}
                >
                  <div
                    className="progress_bar__fill"
                    style={{
                      width: d.percent,
                      backgroundColor: COLOR_MAP[d.id],
                    }}
                  ></div>
                </div>
                <div className="progress_bar__value progress_bar__value--min-w">
                  {d.percent}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export interface TooltipCSS extends CSSProperties {
  "--nivo-chunk-color": string;
}

function Tooltip({ color, label, data }) {
  return (
    <div
      className="tooltip-nivo"
      style={{ "--nivo-chunk-color": color } as TooltipCSS}
    >
      <span className="tooltip-nivo__label">{label}</span>
      <span className="tooltip-nivo__value">{data.value.friendly}</span>
    </div>
  );
}

type ArcLayerContext = {
  dataWithArc: Array<any>;
  centerX: number;
  centerY: number;
  arcGenerator: any;
};

function CustomArcLayer(activeChunk: any) {
  return function (context: ArcLayerContext) {
    const { dataWithArc, centerX, centerY, arcGenerator } = context;
    const { arc, color, data } = dataWithArc.find(
      ({ id }) => id === activeChunk
    );
    const pathData = arcGenerator({
      ...arc,
      innerRadius: arc.innerRadius - 4,
      outerRadius: arc.outerRadius + 4,
      cornerRadius: 10,
    });

    return (
      <g transform={`translate(${centerX},${centerY})`}>
        <path d={pathData} fill={color} />
        <text
          transform="translate(0,-10)"
          fill="currentColor"
          fontSize="20"
          textAnchor="middle"
        >
          {data.value}
        </text>
        <text textAnchor="middle" fontSize="12" color="#718291" y="5">
          Participants
        </text>
      </g>
    );
  };
}
