import React from "react";
import ReportLayout from "../ReportLayout";
import ReportDrawer from "../Common/ReportDrawer";
import DownloadReport from "../Common/DownloadReport";
import Table from "./SuspendedEntitiesTable";

import { Suspension } from "../../types/models";

type SuspendedEntitiesIndexProps = {
  suspensions: Suspension[];
  suspensions_path: string;
  suspensions_download_path: string;
  filename: string;
};

const SuspendedEntitiesIndex = ({
  suspensions,
  suspensions_path,
  suspensions_download_path,
  filename,
}: SuspendedEntitiesIndexProps) => {
  const downloadComponent = (
    <DownloadReport
      reportName="suspended-entities"
      downloadPath={suspensions_download_path}
      downloadFilename={filename}
      downloadParams={{}}
    />
  );

  return (
    <ReportLayout
      title="Suspended Entities"
      body={
        <Table suspensions={suspensions} suspensions_path={suspensions_path} />
      }
      aside={<ReportDrawer download={downloadComponent} />}
    />
  );
};

export default SuspendedEntitiesIndex;
