import React, { ReactNode } from "react";
import classnames from "classnames";

type AvailableVariants =
  | "primary"
  | "secondary"
  | "tertiary"
  | "link"
  | "tab"
  | "danger"
  | "warning";

type ButtonProps = {
  variant?: AvailableVariants;
  className?: string;
  isDisabled?: boolean;
  onClick: () => void;
  children: ReactNode;
};

const Button = ({
  variant = "primary",
  className = "",
  isDisabled = false,
  onClick,
  children,
}: ButtonProps) => {
  const buttonClasses = classnames({
    button: true,
    [`button--variant-${variant}`]: true,
    [`${className}`]: className,
  });

  return (
    <button onClick={onClick} className={buttonClasses} disabled={isDisabled}>
      {children}
    </button>
  );
};

export default Button;
