import React, { useEffect, useState } from "react";
import Notice from "../../Notice";
import useFileDownload from "../../Hooks/useFileDownload";

type DownloadReportProps = {
  downloadPath: string;
  downloadFilename: string;
  downloadParams: any;
  reportName: string;
};

const DownloadReport = ({
  downloadPath,
  downloadFilename,
  downloadParams,
  reportName,
}: DownloadReportProps) => {
  const [handleDownload, downloadStates] = useFileDownload();
  const isDownloading = downloadStates[reportName];

  const [notice, setNotice] = useState({
    kind: "error",
    open: false,
    message: "",
  });

  const downloadExport = () => {
    handleDownload(
      `${downloadPath}.xlsx`,
      `${downloadFilename}`,
      reportName,
      downloadParams
    );
  };

  useEffect(() => {
    if (downloadStates[reportName]?.hasDownloadError) {
      setNotice({
        kind: "error",
        open: true,
        message: "There was an error downloading the report. Please try again.",
      });
    }
  }, [downloadStates]);

  return (
    <div>
      <button
        className={`btn ${
          isDownloading?.isDownloading
            ? "btn--light btn--disabled"
            : "btn--light"
        }`}
        onClick={downloadExport}
        disabled={isDownloading?.isDownloading}
      >
        {isDownloading?.isDownloading && (
          <span className="loading-indicator loading-indicator--blue loading-indicator--no-left-margin"></span>
        )}
        Download Excel
      </button>
      <Notice details={notice} setNoticeState={setNotice} />
    </div>
  );
};

export default DownloadReport;
