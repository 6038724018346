import React, { useState, useEffect, PropsWithChildren } from "react";
import httpClient from "../utils/httpClient";
import { Skeleton } from "@mui/material";
import Tile from "../Base/Tile";
import { ResponsiveLine } from "@nivo/line";
import {
  abbreviatedNumber,
  fractionToPercentage,
  currencyWithCommas,
} from "../utils/NumberFormatter";
import { sum, isEmpty } from "lodash";
import {
  monthAndYear,
  monthOnly,
  timezoneAgnosticDate,
} from "../utils/dateFormatter";

type ClaimsVolumeWidgetProps = {
  orgId: string;
};

export default function ClaimsVolumeWidget({ orgId }: ClaimsVolumeWidgetProps) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>({});

  useEffect(() => {
    fetchClaimsVolume();
  }, []);

  async function fetchClaimsVolume() {
    setLoading(true);
    try {
      await httpClient
        .get(`/organization/${orgId}/claims_volume`)
        .then((res) => setData(res.data));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const totalVolumeClaims = () =>
    isEmpty(data)
      ? 0
      : sum(Object.values(data.inhouse_claims || 0)) +
        sum(Object.values(data.cp_claims));

  return (
    <Tile>
      <Tile.Header className="justify-content-between">
        <Tile.SecondaryText className="t--500">
          Claims Volume Year to Date
        </Tile.SecondaryText>
      </Tile.Header>
      <Tile.Body>
        <ClaimsVolumeChart
          data={data}
          totalVolumeClaims={totalVolumeClaims()}
          loading={loading}
        />
      </Tile.Body>
    </Tile>
  );
}

type ClaimsVolumeDatum = {
  x: string;
  y: number;
  color: string;
  wac: number;
  totalClaimsPercent: string;
};

type ClaimsVolumeData = {
  id: string;
  data: Array<ClaimsVolumeDatum>;
};

function ClaimsVolumeChart({ data, totalVolumeClaims, loading }) {
  const formattedData: Array<ClaimsVolumeData> = [];
  const height = 180;

  const LoadingWrapper = (
    props: PropsWithChildren<{
      width?: number | string;
      height?: number | string;
      isLoading: boolean;
    }>
  ) => {
    const { width, height, children, isLoading } = props;

    return isLoading ? <Skeleton width={width} height={height} /> : children;
  };

  if (data.inhouse_claims) {
    formattedData.push({
      id: "In-House WAC",
      data: Object.keys(data.inhouse_claims).map((key) => ({
        x: key,
        y: data.inhouse_claims[key],
        color: "#8957ee",
        wac: data.inhouse_wac[key],
        totalClaimsPercent: fractionToPercentage(
          data.inhouse_claims[key],
          totalVolumeClaims
        ),
      })),
    });
  }

  if (data.cp_claims) {
    formattedData.push({
      id: "Contract Pharmacy WAC",
      data: Object.keys(data.cp_claims).map((key) => ({
        x: key,
        y: data.cp_claims[key],
        color: "#35d2fc",
        wac: data.cp_wac[key],
        totalClaimsPercent: fractionToPercentage(
          data.cp_claims[key],
          totalVolumeClaims
        ),
      })),
    });
  }

  return (
    <div style={{ height }}>
      <LoadingWrapper height="100%" isLoading={loading}>
        <ResponsiveLine
          data={formattedData}
          colors={data.inhouse_claims ? ["#8957ee", "#35d2fc"] : ["#35d2fc"]}
          isInteractive
          margin={{ top: 38, right: 16, bottom: 24, left: 40 }}
          enableArea
          enablePoints={false}
          useMesh
          axisLeft={{
            format: (v) => abbreviatedNumber(v),
            tickValues: 4,
            tickSize: 0,
            tickPadding: 15,
          }}
          axisBottom={{
            tickSize: 0,
            tickPadding: 5,
            format: (v) => monthOnly(timezoneAgnosticDate(v), "short"),
          }}
          legends={[
            {
              anchor: "top-left",
              direction: "row",
              symbolShape: "circle",
              itemDirection: "left-to-right",
              itemWidth: 150,
              itemHeight: 20,
              symbolSize: 6,
              translateY: -40,
              translateX: -40,
            },
          ]}
          theme={{
            legends: {
              text: { fontSize: 10 },
            },
            axis: {
              ticks: {
                text: { fontSize: 10, textAlign: "right" },
              },
            },
          }}
          defs={[
            {
              id: "inhouse",
              type: "linearGradient",
              colors: [
                { offset: 0, color: "#8957ee" },
                { offset: 100, color: "rgba(137, 87, 238, 0.1)" },
              ],
            },
            {
              id: "cp",
              type: "linearGradient",
              colors: [
                { offset: 0, color: "#35d2fc" },
                { offset: 100, color: "rgba(53, 210, 252, 0.1)" },
              ],
            },
          ]}
          fill={[
            { match: { id: "In-House WAC" }, id: "inhouse" },
            {
              match: { id: "Contract Pharmacy WAC" },
              id: "cp",
            },
          ]}
          tooltip={({ point }) => (
            <div className="tooltip-nivo" style={{ padding: 16 }}>
              <span
                className="tooltip-nivo__label"
                style={{ marginBottom: 8, fontWeight: "bold", fontSize: 12 }}
              >
                CLAIMS VOLUME
              </span>
              <div
                style={{
                  // @ts-ignore - need to figure out how to handle this typing
                  borderLeft: `3px solid ${point.data.color}`,
                  paddingLeft: 8,
                }}
              >
                <div className="tooltip-nivo__small-body">
                  {
                    /* @ts-ignore - need to figure out how to handle this typing */
                    monthAndYear(new Date(point.data.x))
                  }
                </div>
                <div style={{ fontWeight: 500, fontSize: 12 }}>
                  <div>
                    {
                      /* @ts-ignore - need to figure out how to handle this typing */
                      point.data.totalClaimsPercent
                    }{" "}
                    of total claims
                  </div>
                  <div>
                    {
                      /* @ts-ignore - need to figure out how to handle this typing */
                      point.data.wac
                        ? currencyWithCommas(
                            /* @ts-ignore - need to figure out how to handle this typing */ point
                              .data.wac.value
                          )
                        : "--"
                    }
                  </div>
                </div>
              </div>
            </div>
          )}
        />
      </LoadingWrapper>
    </div>
  );
}
