import React from "react";
import TableRowsLoadingSkeleton from "./TableRowsLoadingSkeleton";

const TableLoadingSkeleton = ({ rows = 10, columns = 5 }) => {
  return (
    <table>
      <thead>
        <TableRowsLoadingSkeleton rows={rows} columns={columns} />
      </thead>
    </table>
  );
};

export default TableLoadingSkeleton;
