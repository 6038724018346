import React from "react";
import { Skeleton } from "@mui/material";

const TileLoadingSkeleton = ({ tiles = 1, height = 260, style = {} }) => {
  return Array.from(Array(tiles)).map((_, index) => (
    <div className="tile" style = {{...style}} key={index}>
      <div className="tile__header">
        <div className="tile__header__details">
          <div className="tile__header__details__title">
            <Skeleton animation="wave" width={210} />
          </div>
        </div>
      </div>
      <Skeleton animation="wave" variant="rounded" height={height} />
    </div>
  ));
};

export default TileLoadingSkeleton;
