import React, { useMemo, useState, Fragment } from "react";
import { ExpandMore, ExpandLess } from "@material-ui/icons";
import { numberWithCommas, currencyWithCommas } from "../utils/NumberFormatter";
import TableRowsLoadingSkeleton from "../TableRowsLoadingSkeleton";
import PaginationControls from "../Reports/Common/PaginationControls";
import _ from "lodash";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getExpandedRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";

import { Account, AccountProductRow } from "../types/models";

type AccountsTableProps = {
  accounts: Account[];
  setSelectedAccount: (arg1: any) => void;
  setDisplayModal: (arg1: boolean) => void;
  setDisplayHistoryModal: (arg1: boolean) => void;
  handleSearch: (arg1: string, arg2: string) => void;
  isLoading: boolean;
};

export default function AccountsTable({
  accounts = [],
  setSelectedAccount,
  setDisplayModal,
  setDisplayHistoryModal,
  handleSearch,
  isLoading,
}: AccountsTableProps) {
  const columnHelper = createColumnHelper<Account>();
  const [expanded, setExpanded] = useState({});

  const columns = useMemo(
    () => [
      columnHelper.accessor("id_340b", {
        header: "Covered Entity",
        cell: ({ row, getValue }) => {
          return (
            <>
              <button
                className="btn btn--sqr"
                aria-expanded={row.getIsExpanded()}
                style={{ marginRight: 10, verticalAlign: "middle" }}
                onClick={() => row.toggleExpanded()}
              >
                {row.getIsExpanded() ? (
                  <ExpandLess fontSize="small" />
                ) : (
                  <ExpandMore fontSize="small" />
                )}
              </button>
              <div className="table__text__stack">
                <div className="t--sm t--500">{getValue().toUpperCase()}</div>
                <div className="t--sm t--subtitle">
                  {row.original.entity_name}
                </div>
              </div>
            </>
          );
        },
      }),
      columnHelper.display({
        header: "Products",
        cell: ({ row }) => {
          return (
            <div className="table__text__stack">
              <div className="t--sm t--500">
                {_(row.original.accounts)
                  .countBy("product_name")
                  .values()
                  .sum()}
              </div>
            </div>
          );
        },
      }),
      columnHelper.accessor("pharmacy_name", {
        header: "Pharmacy",
        cell: ({ row, getValue }) => {
          return (
            <div className="table__text__stack">
              <div className="t--sm t--500">{getValue().toUpperCase()}</div>
              <div className="t--sm t--subtitle">{row.original.address}</div>
            </div>
          );
        },
      }),
      columnHelper.display({
        header: "Unit Balance",
        cell: ({ row }) => {
          return (
            <span className="t--500">
              {numberWithCommas(_.sumBy(row.original.accounts, "balance"))}
            </span>
          );
        },
      }),
      columnHelper.display({
        header: "WAC",
        cell: ({ row }) => {
          return (
            <span className="t--500">
              {currencyWithCommas(_.sumBy(row.original.accounts, "price"))}
            </span>
          );
        },
      }),
      columnHelper.display({
        header: "Status",
        cell: ({ row }) => {
          const status_counts = _.countBy(row.original.accounts, "status");

          return (
            <div className="badge">
              <div className="badge__indicator" />
              <div className="badge__item badge__item--t-lg">
                {status_counts["POSITIVE"] ? status_counts["POSITIVE"] : 0}
              </div>
              <div className="badge__indicator badge__indicator--orange" />
              <div className="badge__item badge__item--t-lg badge__item--last">
                {status_counts["NEGATIVE"] ? status_counts["NEGATIVE"] : 0}
              </div>
            </div>
          );
        },
      }),
      columnHelper.display({
        header: "Details",
        cell: ({ row }) => {
          return (
            <button
              className="btn btn--light"
              onClick={() => {
                handleSearch("pid", row.original.pid);
                setDisplayHistoryModal(true);
              }}
            >
              View Details
            </button>
          );
        },
      }),
    ],
    [accounts]
  );

  const table = useReactTable<Account>({
    data: accounts,
    columns,
    state: {
      expanded,
    },
    onExpandedChange: setExpanded,
    getExpandedRowModel: getExpandedRowModel(),
    getCoreRowModel: getCoreRowModel<Account>(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: { pagination: { pageSize: 50 } },
  });

  const renderStatusBadge = (status: string) => {
    if (status === "NEGATIVE") {
      return (
        <div className="badge">
          <div className="badge__indicator badge__indicator--orange" />
          Negative Balance
        </div>
      );
    } else {
      return (
        <div className="badge">
          <div className="badge__indicator" />
          Positive Balance
        </div>
      );
    }
  };

  const renderAccountsTableSubcomponent = (
    account_rows: Array<AccountProductRow>
  ) => {
    return (
      <table className="secondary" style={{ textAlign: "left" }}>
        <thead>
          <tr className="sm">
            <th className="rnd-l">Pharmacy</th>
            <th>Product Name</th>
            <th>Status</th>
            <th>Unit Balance</th>
            <th>WAC</th>
            <th className="rnd-r">Actions</th>
          </tr>
        </thead>
        <tbody>{renderAccountRowsSubcomponent(account_rows)}</tbody>
      </table>
    );
  };

  const renderAccountRowsSubcomponent = (
    account_rows: Array<AccountProductRow>
  ) => {
    return _.map(account_rows, (acc, i) => {
      return (
        <tr className="sm" key={i}>
          <td className="t--sm">
            <div className="table__text__stack">
              <div className="t--sm t--500">{acc.pharmacy_name}</div>
              <div className="t--sm t--subtitle">{acc.address}</div>
            </div>
          </td>
          <td className="t--sm t--500">{acc.product_name}</td>
          <td className="t--sm t--500">{renderStatusBadge(acc.status)}</td>
          <td className="t--500">{numberWithCommas(acc.balance)}</td>
          <td className="t--500">{currencyWithCommas(acc.price)}</td>
          <td style={{ paddingRight: 0 }}>
            <button
              className="btn btn--light"
              onClick={() => {
                setSelectedAccount(acc);
                setDisplayModal(true);
              }}
            >
              View Details
            </button>
          </td>
        </tr>
      );
    });
  };

  const renderSubComponent = (row) => {
    if (row.original.accounts) {
      return renderAccountsTableSubcomponent(row.original.accounts);
    }
  };

  const renderRows = () => {
    if (isLoading) return <TableRowsLoadingSkeleton rows={25} columns={7} />;

    return table.getRowModel().rows.map((row) => {
      return (
        <Fragment key={row.id}>
          <tr className="sm">
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id} className="t--md">
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
          {row.getIsExpanded() && (
            <tr className="sm">
              <td colSpan={row.getVisibleCells().length}>
                {renderSubComponent(row)}
              </td>
            </tr>
          )}
        </Fragment>
      );
    });
  };

  return (
    <div>
      <table className="primary">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className="sm">
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>{renderRows()}</tbody>
      </table>

      <PaginationControls table={table} recordCount={accounts.length} />
    </div>
  );
}
