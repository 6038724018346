import React, { useState } from "react";
import { Pie } from "@nivo/pie";
import { ChevronRight } from "@material-ui/icons";
import _ from "lodash";
import { wholeNumberWithCommas } from "../../utils/NumberFormatter";
import { Row } from "@tanstack/react-table";

import { ClaimHistoryRow } from "../../types/models";

type SubmissionSummaryChartProps = {
  row: Row<ClaimHistoryRow>;
};

export default function SubmissionSummaryChart({ row }: SubmissionSummaryChartProps) {
  const [selected, setSelected] = useState(0);

  const items = [
    "Conforming Claims",
    "Non-Conforming Claims",
    "Invalid 340B ID",
    "Invalid service provider ID",
  ];
  const ids = ["conforming", "non conforming", "invalid entities", "invalid pharmacies"];
  const colors = ["#24B668", "#28D0FC", "#3246D3", "#FF681F"];

  const renderChunk =
    () =>
    ({ dataWithArc, centerX, centerY, arcGenerator }) => {
      const [data] = dataWithArc.filter(({ id }) => id === ids[selected]);
      const pathData = arcGenerator({
        ...data.arc,
        innerRadius: data.arc.innerRadius - 3,
        outerRadius: data.arc.outerRadius + 3,
      });
      const total = dataWithArc.reduce((prev, curr) => prev + curr.value, 0);
      const percentage = total && Math.round((data.value / total) * 100);
      return (
        <g transform={`translate(${centerX},${centerY})`}>
          <path d={pathData} fill={data.color} />
          <text transform="translate(-16,8)" fill={"currentColor"} fontSize="20">
            {percentage}%
          </text>
        </g>
      );
    };

  const getIndicatorName = (index: number) => {
    return ["green", "skyblue", "blue", "orange"][index];
  };

  const renderTable = () => {
    switch (items[selected]) {
      case "Invalid service provider ID":
        return renderInvalidPharmaciesTable(row.original.invalid_pharmacies.details);
      case "Invalid 340B ID":
        return renderInvalidEntitiesTable(row.original.invalid_entities.details);
      case "Non-Conforming Claims":
        return renderNonConformingPharmaciesTable(row.original.non_conforming.details);
      case "Conforming Claims":
        return renderConformingPharmaciesTable(row.original.conforming.details);
    }
  };

  const renderInvalidPharmaciesTable = (items) => {
    return _.map(items, (val, key) => {
      return (
        <tr className="sm" key={key}>
          <td className="w-60">{key}</td>
          <td>--</td>
          <td>{val}</td>
        </tr>
      );
    });
  };

  const renderInvalidEntitiesTable = (items) => {
    return _.map(items, (val, key) => {
      return (
        <tr className="sm" key={key}>
          <td className="w-60">{key}</td>
          <td>--</td>
          <td>{val}</td>
        </tr>
      );
    });
  };

  const renderNonConformingPharmaciesTable = (items) => {
    return _.map(items, (val, key) => {
      return (
        <tr className="sm" key={key}>
          <td className="w-60">{key}</td>
          <td>--</td>
          <td>{val}</td>
        </tr>
      );
    });
  };

  const renderConformingPharmaciesTable = (items) => {
    return _.map(items, (val, key) => {
      return (
        <tr className="sm" key={key}>
          <td className="w-60">{key}</td>
          <td>--</td>
          <td>{val}</td>
        </tr>
      );
    });
  };

  const renderChevron = (index: number) => {
    if (selected == index) {
      return <ChevronRight style={{ fontSize: 20 }} />;
    }
  };

  const renderItem = () => {
    return _.map(items, (item, index) => {
      return (
        <div
          key={index}
          className={
            items[selected] == item
              ? "chart__component__submission__summary__info__item chart__component__submission__summary__info__item--active"
              : "chart__component__submission__summary__info__item"
          }
          onClick={() => setSelected(index)}
        >
          <div
            className={`chart__component__submission__summary__info__item__indicator chart__component__submission__summary__info__item__indicator--${getIndicatorName(
              index
            )}`}
          ></div>
          <div className="chart__component__submission__summary__info__item__title">{`${items[index]}`}</div>
          <div className="chart__component__submission__summary__info__item__icon">
            {renderChevron(index)}
          </div>
        </div>
      );
    });
  };

  const renderPieChart = () => {
    return (
      <Pie
        data={[
          {
            label: "Conforming",
            id: "conforming",
            value: row.original.conforming.count,
            color: colors[0],
          },
          {
            label: "Non Conforming",
            id: "non conforming",
            value: row.original.non_conforming.count,
            color: colors[1],
          },
          {
            label: "Invalid CPs",
            id: "invalid pharmacies",
            value: row.original.invalid_pharmacies.count,
            color: colors[3],
          },
          {
            label: "Invalid CEs",
            id: "invalid entities",
            value: row.original.invalid_entities.count,
            color: colors[2],
          },
        ]}
        width={130}
        height={130}
        margin={{ top: 16, right: 16, bottom: 16, left: 16 }}
        innerRadius={0.7}
        activeInnerRadiusOffset={4}
        activeOuterRadiusOffset={4}
        enableArcLabels={false}
        enableArcLinkLabels={false}
        colors={({ data }) => data.color}
        onClick={(data) => setSelected(ids.indexOf(data.id as string))}
        layers={["arcs", renderChunk()]}
      />
    );
  };

  return (
    <div className="row">
      <div className="col-3 d-none d-xl-block">
        <div className="row" style={{ height: "100%" }}>
          <div
            className="col-12 d-flex flex-column justify-content-between"
            style={{ paddingRight: 0 }}
          >
            <div className="stat_card__container">
              <div className="stat_card__header">Products</div>
              <div className="stat_card__body">
                <div className="stat_card__body__value">
                  {wholeNumberWithCommas(row.original.products.length)}
                </div>
              </div>
            </div>
            <div className="stat_card__container">
              <div className="stat_card__header">Contract Pharmacies</div>
              <div className="stat_card__body">
                <div className="stat_card__body__value">
                  {wholeNumberWithCommas(row.original.pharmacies)}
                </div>
              </div>
            </div>
            <div className="stat_card__container">
              <div className="stat_card__header">Unique NDCs</div>
              <div className="stat_card__body">
                <div className="stat_card__body__value">
                  {wholeNumberWithCommas(row.original.ndcs)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-xl-9">
        <div className="chart__component__submission__summary__container">
          <div className="chart__component__submission__summary__info">
            <div className="chart__component__submission__summary__info__header">
              Submission Summary
            </div>
            <div className="chart__component__submission__summary__info__chart">
              <div style={{ display: "flex", justifyContent: "center" }}>{renderPieChart()}</div>
            </div>
            <div className="chart__component__submission__summary__info__item__container">
              {renderItem()}
            </div>
          </div>
          <div className="chart__component__submission__summary__details">
            <table className="secondary">
              <thead>
                <tr className="sm">
                  <th className="w-60">Product Name</th>
                  <th>Pharmacy Chain</th>
                  <th>Claims</th>
                </tr>
              </thead>
              <tbody>{renderTable()}</tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
