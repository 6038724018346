import React, { useState, useEffect } from "react";
import moment from "moment";
import _ from "lodash";
import Table from "./EnforcementLayerTable";
import DownloadReport from "../Common/DownloadReport";
import ReportDrawer from "../Common/ReportDrawer";
import NetworkFilter from "../Common/NetworkFilter";
import PharmacyTypeFilter from "../Common/PharmacyTypeFilter";
import StatusFilter from "../Common/StatusFilter";
import ReportTitle from "../Common/ReportTitle";
import ReportSearch from "../Common/ReportSearch";
import Notice from "../../Notice";
import { wholeNumberWithCommas } from "../../utils/NumberFormatter";
import { search } from "../../utils/search";
import httpClient from "../../utils/httpClient";
import { NetworkOption, EnforcementRow } from "../../types/models";

type EnforcementData = {
  contract_pharmacies: EnforcementRow[];
  in_house: EnforcementRow[];
};

type EnforcementLayerProps = {
  announcement_date: string;
  threshold: number;
  min_wac_threshold: number;
  filename: string;
  data_path: string;
  entity_path: string;
  show_in_house_filter: boolean;
  networks: NetworkOption[];
};

const EnforcementLayer = ({
  announcement_date,
  threshold,
  min_wac_threshold,
  filename,
  data_path,
  entity_path,
  show_in_house_filter,
  networks,
}: EnforcementLayerProps) => {
  const [searchTerms, setSearchTerms] = useState("");
  const [data, setData] = useState<EnforcementData | null>(null);
  const [loading, setLoading] = useState(false);
  const [selectedNetwork, setSelectedNetwork] = useState(networks[0].value);
  const [selectedCategory, setSelectedCategory] = useState("contract_pharmacies");
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
  const [notice, setNotice] = useState({
    kind: "error",
    open: false,
    message: "",
  });

  const categories = show_in_house_filter
    ? [
        { label: "Contract Pharmacies", value: "contract_pharmacies" },
        { label: "In-House", value: "in_house" },
      ]
    : [{ label: "Contract Pharmacies", value: "contract_pharmacies" }];

  const statuses = [
    { label: "Pass", value: "pass" },
    { label: "Fail", value: "fail" },
  ];

  const cpData = _.orderBy(data?.contract_pharmacies, ["sales", "id_340b"], ["desc", "asc"]);

  const ihData = _.orderBy(data?.in_house, ["sales", "id_340b"], ["desc", "asc"]);

  useEffect(() => {
    setLoading(true);

    httpClient.get(data_path).then((res) => {
      setData(JSON.parse(res.data));
      setLoading(false);
    });
  }, []);

  const filterData = () => {
    let filteredData;

    if (selectedCategory == "contract_pharmacies") {
      filteredData = cpData.filter((d) => d.network === selectedNetwork);
    } else {
      filteredData = ihData.filter((d) => d.network === selectedNetwork);
    }

    if (selectedStatus) {
      filteredData = filteredData.filter((d) => d.pass_fail === selectedStatus);
    }

    if (searchTerms) {
      filteredData = search(filteredData, searchTerms, ["entity_name", "id_340b"]);
    }

    return filteredData;
  };

  useEffect(() => {
    if (data && filterData().length === 0) {
      setNotice({
        kind: "success",
        open: true,
        message: "The current selected filters returned no results",
      });
    }
  }, [data, searchTerms, selectedNetwork, selectedCategory, selectedStatus]);

  const downloadComponent = (
    <DownloadReport
      reportName="enforcement-layer-report"
      downloadPath={data_path}
      downloadFilename={filename}
      downloadParams={{
        network: selectedNetwork,
        category: selectedCategory,
        status: selectedStatus,
      }}
    />
  );

  const networkFilter = (
    <NetworkFilter
      networks={networks}
      selectedNetwork={selectedNetwork}
      setSelectedNetwork={setSelectedNetwork}
    />
  );

  const categoryFilter = (
    <PharmacyTypeFilter
      types={categories}
      selectedType={selectedCategory}
      setSelectedType={setSelectedCategory}
    />
  );

  const statusFilter = (
    <StatusFilter
      statuses={statuses}
      selectedStatus={selectedStatus}
      setSelectedStatus={setSelectedStatus}
    />
  );

  const policyDateDisplay = (
    <>
      <div className="draw__item__title draw__item__title--mb-sm t--upcase t--sm">Policy Date</div>
      <div className="badge">{`${moment(announcement_date).format("MMMM D, YYYY")}`}</div>
    </>
  );

  const thresholdDisplay = (
    <div className="draw__item">
      <div className="draw__item__title draw__item__title--mb-sm t--upcase t--sm">Threshold</div>
      <div className="badge">{`${threshold * 100}% Percent`}</div>
      {min_wac_threshold && (
        <div className="badge" style={{ marginLeft: 5 }}>
          ${wholeNumberWithCommas(min_wac_threshold)} WAC
        </div>
      )}
    </div>
  );

  return (
    <div className="d-flex">
      <div className="content__container">
        <ReportTitle title="Enforcement Layer" breadcrumb="Report" />
        <div className="table__actionbar table__actionbar--spacebetween">
          <ReportSearch setSearchTerms={setSearchTerms} />
        </div>
        <div style={{ marginTop: 20 }}>
          <Table transactions={filterData()} isLoading={loading} entity_path={entity_path} />
        </div>
      </div>
      <ReportDrawer
        download={downloadComponent}
        filterComponents={[networkFilter, categoryFilter, statusFilter]}
      >
        {policyDateDisplay}
        <div className="draw__divider" />
        {thresholdDisplay}
        <div className="draw__divider" />
      </ReportDrawer>
      <Notice details={notice} setNoticeState={setNotice} />
    </div>
  );
};

export default EnforcementLayer;
