import React from "react";
import useEntityFetch from "./useEntityFetch";
import TableRowsLoadingSkeleton from "../TableRowsLoadingSkeleton";

function TableRows({ users }) {
  return users.map((user) => (
    <tr className="sm" key={user.id}>
      <td className="t--md">{user.name}</td>
      <td className="t--md">{user.email}</td>
      <td className="t--md">{user.registered}</td>
      <td className="t--md">{user.status}</td>
    </tr>
  ));
}

const UsersTable = ({ query, resource }) => {
  const { isLoading, data, error } = useEntityFetch({ query, endpoint: `${resource}/users` });

  return (
    <table className="primary">
      <thead>
        <tr className="sm">
          <th>Name</th>
          <th>Email</th>
          <th>Registration Date</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>{isLoading ? <TableRowsLoadingSkeleton columns={4} /> : <TableRows users={data} />}</tbody>
    </table>
  );
};

export default UsersTable;
