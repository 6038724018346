import React from "react";
import { ResponsiveRadialBar } from "@nivo/radial-bar";

const GaugeChart = (props) => {
  const data = [{ id: "At Risk Entities", data: [{ x: "Entities", y: 1 }] }];
  return (
    <div style={{ width: "100px", height: "150px" }}>
      <ResponsiveRadialBar
        data={data}
        valueFormat=">-.2f"
        startAngle={-90}
        endAngle={90}
        innerRadius={0.6}
        padding={0.5}
        cornerRadius={38}
        colors="blue"
        borderColor={{
          from: "color",
          modifiers: [["darker", 1]],
        }}
        enableTracks={false}
        enableRadialGrid={false}
        enableCircularGrid={false}
        radialAxisStart={null}
        circularAxisOuter={null}
        labelsSkipAngle={4}
        motionConfig="slow"
        transitionMode="startAngle"
        legends={[]}
      />
    </div>
  );
};

export default GaugeChart;
