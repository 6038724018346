import React from "react";
import ProductBalanceHistoryChart from "./Charts/ProductBalanceHistoryChart";
import { currencyWithCommas } from "../utils/NumberFormatter";
import { defaultDate } from "../utils/dateFormatter";
import Tile from "../Base/Tile";

const BalanceHistory = (props) => {
  const { isLoading, productHistory } = props;
  const balance =
    productHistory?.data?.[0].data[productHistory.data[0].data.length - 1].y ||
    0;

  return (
    <div style={{ flexGrow: 1, gridColumn: "1 / span 3" }}>
      <Tile>
        <Tile.Header>
          <div className="d-flex flex-column gap-1">
            <Tile.SecondaryText className="t--500">
              Balance History
            </Tile.SecondaryText>
            <Tile.TertiaryText>
              Updated - {defaultDate(productHistory?.updated_at)}
            </Tile.TertiaryText>
          </div>
        </Tile.Header>
        <Tile.Body>
          <div className="d-flex align-items-center gap-1 mb-2">
            <Tile.PrimaryText>{currencyWithCommas(balance)}</Tile.PrimaryText>
            <Tile.TertiaryText>Balance</Tile.TertiaryText>
          </div>
          <div style={{ height: 170 }}>
            {isLoading ? null : <ProductBalanceHistoryChart data={productHistory?.data} />}
          </div>
        </Tile.Body>
      </Tile>
    </div>
  );
};

export default BalanceHistory;
