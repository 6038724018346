import React from "react";
import useEntityFetch from "./useEntityFetch";
import ClaimsTable from "../Reports/ClaimSubmissions/ClaimsDetailsTable";

const SubmissionsTable = ({ query, resource }) => {
  const { isLoading, data, error } = useEntityFetch({ query, endpoint: `${resource}/submissions` });

  return (
    <ClaimsTable claims={data} isLoading={isLoading} />
  );
};

export default SubmissionsTable;
