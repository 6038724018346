var context = import.meta.globEager("../../components/**/*.{js,jsx,tsx}");

let componentsContext = {};
for (const component in context) {
  componentsContext[component.replace(".jsx", "").replace(".tsx", "").replace(/.+components\//g, "")] = context[component].default;
}

import ReactRailsUJS from "react_ujs";

ReactRailsUJS.getConstructor = (name) => {
  return componentsContext[name];
};
