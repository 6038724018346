import React, { useState, useMemo } from "react";

import { search } from "../../utils/search";
import Table from "./CPDesignationsTable";
import ReportLayout from "../ReportLayout";
import DownloadReport from "../Common/DownloadReport";
import ReportDrawer from "../Common/ReportDrawer";

import { CPDesignation } from "../../types/models";

type CPDesignationsIndexProps = {
  designations: CPDesignation[];
  filename: string;
  download_path: string;
};

const CPDesignationsIndex = ({
  designations,
  filename,
  download_path,
}: CPDesignationsIndexProps) => {
  const [searchTerms, setSearchTerms] = useState("");

  const filterDesignations = () => {
    return search(designations, searchTerms, [
      "entity_name",
      "id_340b",
      "pharmacy_name",
    ]);
  };

  const filteredDesignations = useMemo(
    () => filterDesignations(),
    [designations, searchTerms]
  );

  const downloadComponent = (
    <DownloadReport
      reportName="cp-designations"
      downloadPath={download_path}
      downloadFilename={filename}
      downloadParams={{}}
    />
  );

  return (
    <ReportLayout
      title="Contract Pharmacy Designations"
      body={
        <Table
          designations={filteredDesignations}
          setSearchTerms={setSearchTerms}
        />
      }
      aside={<ReportDrawer download={downloadComponent} />}
    />
  );
};

export default CPDesignationsIndex;
