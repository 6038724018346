import React from "react";
import { ResponsiveLine } from "@nivo/line";

const CreditsDebitsChart = ({ data }) => {
  return (
    <ResponsiveLine
      data={data}
      margin={{ top: 24, right: 16, bottom: 24, left: 44 }}
      colors={["#ff7052", "#3246d3"]}
      yScale={{ type: "linear", min: 0 }}
      curve="monotoneX"
      axisLeft={{ tickValues: 5 }}
      axisBottom={{
        tickSize: 0,
        tickPadding: 9,
        tickRotation: 0,
        format: (v) => {
          return v.slice(0, 3);
        },
      }}
      enableGridX={false}
      enableGridY={true}
      enablePoints={false}
      useMesh={true}
      legends={[
        {
          anchor: "top-right",
          direction: "row",
          justify: false,
          translateX: -85,
          translateY: -20,
          itemWidth: 10,
          itemHeight: 10,
          itemsSpacing: 120,
          symbolSpacing: 4,
          symbolSize: 6,
          symbolShape: "circle",
        },
      ]}
      enableArea={true}
      areaOpacity={0.3}
      defs={[
        {
          id: "gradientDebits",
          type: "linearGradient",
          colors: [
            { offset: 0, color: "#ff7052" },
            { offset: 100, color: "rgba(255, 112, 82, 0.2)" },
          ],
        },
        {
          id: "gradientCredits",
          type: "linearGradient",
          colors: [
            { offset: 0, color: "#3246d3" },
            { offset: 100, color: "rgba(50, 70, 211, 0.2)" },
          ],
        },
      ]}
      fill={[
        { match: { id: "340B Purchases" }, id: "gradientDebits" },
        { match: { id: "Claim Submissions" }, id: "gradientCredits" },
      ]}
    />
  );
};

export default CreditsDebitsChart;
