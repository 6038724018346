import httpClient from "./utils/httpClient";
import mixpanel from "mixpanel-browser";

httpClient.get("/user/tracking_data").then(({ data }) => {
  const projectToken =
    data.project_token || import.meta.env.VITE_MIXPANEL_TOKEN_MOSAIC;
  if (!projectToken) return;

  mixpanel.init(projectToken, {
    debug: import.meta.env.VITE_MIXPANEL_DEBUG_MODE == "true",
    track_pageview: true,
    persistence: "localStorage",
    ignore_dnt: true,
  });

  if (!data.user) return;

  mixpanel.identify(data.user.id);
  mixpanel.people.set({
    $name: data.user.name,
  });
});
