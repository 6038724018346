import React, { useState, useEffect } from "react";
import BarChart from "../Charts/BarChart";
import { wholeNumberWithCommas } from "../utils/NumberFormatter";
import moment from "moment";
import httpClient from "../utils/httpClient";
import Tile from "../Base/Tile";
import StatCard from "../Base/StatCard";

const START_OF_MONTH = moment().startOf("month").format("M/D/YYYY");
const TODAY = moment().format("M/D/YYYY");

const ReportsGraphs = (props) => {
  const { total, change } = props.designations_count;
  const [claims, setClaims] = useState({
    conforming_claims_percentage: null,
    conforming_claims_count: null,
    non_conforming_claims_count: null,
    chart_data: [],
  });
  const [exceptions, setExceptions] = useState(0);
  const [suspendedEntities, setSuspendedEntities] = useState({
    current: null,
    last_month: null,
  });
  const [atRisks, setAtRisks] = useState();
  const [claimsHistory, setClaimsHistory] = useState([]);

  useEffect(() => {
    fetchClaims();
    fetchExceptions();
    fetchSuspendedEntities();
    fetchAtRiskEntities();
    fetchClaimsHistory();
  }, []);

  const fetchClaims = () => {
    httpClient
      .get(
        `/organization/${props.organizationId}/reports/conforming_claims_count`
      )
      .then((response) => setClaims(response.data))
      .catch((error) => console.log(error));
  };

  const fetchExceptions = () => {
    httpClient
      .get(`/organization/${props.organizationId}/reports/exception_count`)
      .then((response) => setExceptions(response.data))
      .catch((error) => console.log(error));
  };

  const fetchSuspendedEntities = () => {
    httpClient
      .get(`/organization/${props.organizationId}/reports/suspended_ces`)
      .then((response) => setSuspendedEntities(response.data))
      .catch((error) => console.log(error));
  };

  const fetchAtRiskEntities = () => {
    httpClient
      .get(`/organization/${props.organizationId}/reports/at_risk_entities`)
      .then((response) => setAtRisks(response.data))
      .catch((error) => console.log(error));
  };

  const fetchClaimsHistory = () => {
    httpClient
      .get(`/organization/${props.organizationId}/reports/claims_history`)
      .then((response) => setClaimsHistory(response.data))
      .catch((error) => console.log);
  };

  return (
    <div className="d-flex">
      <Tile>
        <Tile.Header className="flex-column gap-1">
          <Tile.SecondaryText className="t--500">
            Submitted Claims
          </Tile.SecondaryText>
          <Tile.TertiaryText>Updated - {TODAY}</Tile.TertiaryText>
        </Tile.Header>
        <Tile.Body className="d-flex justify-content-between">
          <section>
            <div className="d-flex flex-column gap-1 mb-3">
              <Tile.PrimaryText>
                {wholeNumberWithCommas(claims.conforming_claims_count || 0)}
              </Tile.PrimaryText>
              <Tile.TertiaryText>
                This Month - Conforming Claims
              </Tile.TertiaryText>
            </div>
            <div className="d-flex flex-column gap-1">
              <Tile.PrimaryText>
                {wholeNumberWithCommas(claims.non_conforming_claims_count || 0)}
              </Tile.PrimaryText>
              <Tile.TertiaryText>
                This Month - Non Conforming Claims
              </Tile.TertiaryText>
            </div>
          </section>
          <section style={{ width: 450, marginBottom: 20 }}>
            <BarChart chartHeight={138} data={claimsHistory} />
          </section>
        </Tile.Body>
        <Tile.Footer className="gap-3">
          <StatCard
            title="Designations"
            updated={START_OF_MONTH}
            stat={total}
            legend="Entities"
            badge={{
              trend: change?.trending,
              value: change?.friendly,
              info: "vs last month",
            }}
          />
          <StatCard
            title="Entity Exceptions"
            updated={START_OF_MONTH}
            stat={exceptions?.total}
            legend="Entities"
            badge={{
              trend: exceptions?.change?.trending,
              value: exceptions?.change?.friendly,
              info: "vs last month",
            }}
          />
          <StatCard
            title="At Risk Entities"
            updated={TODAY}
            stat={atRisks?.today}
            legend="Entities"
            badge={{
              trend: atRisks?.change?.trending,
              value: atRisks?.change?.friendly,
              info: "vs last month",
            }}
          />
          <StatCard
            title="Suspended Entities"
            updated={TODAY}
            stat={suspendedEntities?.total}
            legend="Entities"
            badge={{
              trend: suspendedEntities?.change?.trending,
              value: suspendedEntities?.change?.friendly,
              info: "vs last month",
            }}
          />
        </Tile.Footer>
      </Tile>
    </div>
  );
};

export default ReportsGraphs;
