import React, { useState } from "react";
import createPlotlyComponent from "react-plotly.js/factory";
import Plotly from "plotly.js-geo-dist-min";
import {
  MAP_CONFIG,
  MAP_LAYOUT,
  MAP_DATA_BASE_OPTIONS,
  DEFAULT_LEGEND_COLORS,
} from "../constants/mapConstants";
import { STATE_ABBREVIATION_TO_FULL_MAP } from "../constants/states";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import {
  fractionToPercentage,
  wholeNumberWithCommas,
  abbreviatedNumber,
} from "../utils/NumberFormatter";

const Plot = createPlotlyComponent(Plotly);

type CpAndCeMapProps = {
  data: {
    [key: string]: {
      covered_entities: {
        grantee: number;
        hospital: number;
      };
      contracted_pharmacies: number;
    };
  };
};

type TooltipData = {
  points: {
    location: string;
    customdata: {
      covered_entities: {
        grantee: number;
        hospital: number;
      };
      contracted_pharmacies: number;
    };
  }[];
} | null;

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
    "&::before": {
      backgroundColor: theme.palette.common.white,
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    boxShadow: "0 6px 14px 0 rgba(76, 84, 153, 0.2)",
  },
}));

const CpAndCeMap = ({ data }: CpAndCeMapProps) => {
  const [tooltipData, setTooltipData] = useState<TooltipData>(null);
  const highestValue = Math.max(
    ...Object.values(data).map(
      (d) =>
        d.covered_entities.grantee +
        d.covered_entities.hospital +
        d.contracted_pharmacies
    )
  );

  const mapData = [
    {
      ...MAP_DATA_BASE_OPTIONS,
      locations: Object.keys(data),
      z: Object.values(data).map(
        (d) =>
          d.covered_entities.grantee +
          d.covered_entities.hospital +
          d.contracted_pharmacies
      ),
      zmin: 0,
      customdata: Object.keys(data).map((key) => data[key]),
      hoverinfo: "none",
      colorscale: DEFAULT_LEGEND_COLORS,
    },
  ];

  const Legend = () => (
    <div className="tooltip-map__legend">
      <div className="tooltip-map__legend__values">
        {[0, Math.ceil(highestValue / 2), Math.ceil(highestValue)].map(
          (val) => {
            return <span key={val}>{abbreviatedNumber(val)}</span>;
          }
        )}
      </div>
      <div className="tooltip-map__legend__rectangle"></div>
    </div>
  );

  const tooltipContent = () => {
    if (!tooltipData) return null;

    const customData = tooltipData.points[0].customdata;
    const coveredEntitiesTotal =
      customData.covered_entities.grantee +
      customData.covered_entities.hospital;
    const granteePercent = fractionToPercentage(
      customData.covered_entities.grantee,
      coveredEntitiesTotal,
      1
    );
    const hospitalPercent = fractionToPercentage(
      customData.covered_entities.hospital,
      coveredEntitiesTotal,
      1
    );

    return (
      <>
        <h5 className="tooltip-map__title">
          {STATE_ABBREVIATION_TO_FULL_MAP[tooltipData.points[0].location]}
        </h5>
        <div className="tooltip-map__body">
          <div className="tooltip-map__body__row">
            <span>Covered Entities</span>
            <span style={{ fontWeight: "bold" }}>
              {wholeNumberWithCommas(coveredEntitiesTotal)}
            </span>
          </div>
          <hr />
          <div className="tooltip-map__body__row sub-content">
            <span>Grantees</span> <span>{granteePercent}</span>
          </div>
          <div className="tooltip-map__body__row sub-content">
            <span>Hospitals</span>
            <span>{hospitalPercent}</span>
          </div>
          <div className="tooltip-map__body__row">
            <span>Contract Pharmacies</span>
            <span style={{ fontWeight: "bold" }}>
              {wholeNumberWithCommas(customData.contracted_pharmacies)}
            </span>
          </div>
        </div>
      </>
    );
  };

  const MetricBoxes = ({ mostCPs, mostHospitalCEs, mostGranteeCEs }) => {
    return (
      <div className="metric-cards">
        <div className="metric-cards__item">
          <div>
            <div className="metric-cards__item__secondary-header">
              CONTRACT PHARMACY
            </div>
            <div className="metric-cards__item__primary-header">Most CPs</div>
          </div>
          <div className="metric-cards__item__primary-title">{mostCPs}</div>
        </div>
        <div className="metric-cards__item">
          <div>
            <div className="metric-cards__item__secondary-header">
              COVERED ENTITIES - HOSPITALS
            </div>
            <div className="metric-cards__item__primary-header">
              Most CEs - Hospitals
            </div>
          </div>
          <div className="metric-cards__item__primary-title">
            {mostHospitalCEs}
          </div>
        </div>
        <div className="metric-cards__item">
          <div>
            <div className="metric-cards__item__secondary-header">
              COVERED ENTITIES - GRANTEES
            </div>
            <div className="metric-cards__item__primary-header">
              Most CEs - Grantees
            </div>
          </div>
          <div className="metric-cards__item__primary-title">
            {mostGranteeCEs}
          </div>
        </div>
      </div>
    );
  };

  const stateWithMostCPs = Object.keys(data).reduce((a, b) =>
    data[a].contracted_pharmacies > data[b].contracted_pharmacies ? a : b
  );
  const stateWithMostHospitalCEs = Object.keys(data).reduce((a, b) =>
    data[a].covered_entities.hospital > data[b].covered_entities.hospital
      ? a
      : b
  );
  const stateWithMostGranteeCEs = Object.keys(data).reduce((a, b) =>
    data[a].covered_entities.grantee > data[b].covered_entities.grantee ? a : b
  );

  return (
    <HtmlTooltip
      title={tooltipContent()}
      className="tooltip-map"
      followCursor
      arrow
    >
      <div>
        <MetricBoxes
          mostCPs={STATE_ABBREVIATION_TO_FULL_MAP[stateWithMostCPs]}
          mostHospitalCEs={
            STATE_ABBREVIATION_TO_FULL_MAP[stateWithMostHospitalCEs]
          }
          mostGranteeCEs={
            STATE_ABBREVIATION_TO_FULL_MAP[stateWithMostGranteeCEs]
          }
        />
        <Plot
          className="state-profiles-map__map"
          data={mapData}
          layout={MAP_LAYOUT}
          config={MAP_CONFIG}
          onHover={(data) => {
            setTooltipData(data);
          }}
          onUnhover={() => {
            setTooltipData(null);
          }}
        />
        <Legend />
      </div>
    </HtmlTooltip>
  );
};

export default CpAndCeMap;
