import React from "react";
import ChipSelector from "../../ChipSelector";

type ClaimStatusFilterProps = {
  selectedStatus: string | null;
  setSelectedStatus: (arg1: string) => void;
};

const ClaimStatusFilter = ({ selectedStatus, setSelectedStatus }: ClaimStatusFilterProps) => {
  return (
    <>
      <div className="draw__item__title draw__item__title--mb-sm t--upcase t--sm">Status</div>
      <div className="chip__well">
        <ChipSelector
          items={[
            { value: "CONFORMING", label: "Conforming" },
            { value: "NON-CONFORMING", label: "Non-Conforming" },
            {
              value: "NOT A CONTRACT PHARMACY",
              label: "Not a Contract Pharmacy",
            },
            { value: "INVALID 340B ID", label: "Invalid 340B ID" },
          ]}
          selected={selectedStatus}
          setSelected={setSelectedStatus}
        />
      </div>
    </>
  );
};

export default ClaimStatusFilter;
