import React, { ReactNode } from "react";

type TileProps = {
  children: ReactNode;
  className?: string;
};

function Tile({ children, className = "" }) {
  // Temp style attribute
  return (
    <article className={`tile ${className}`} style={{ height: "100%" }}>
      {children}
    </article>
  );
}

function Header({ children, className = "" }: TileProps) {
  return <header className={`tile__header ${className}`}>{children}</header>;
}

function PrimaryText({ children, className = "" }: TileProps) {
  return <span className={`tile__primary-text ${className}`}>{children}</span>;
}

function SecondaryText({ children, className = "" }: TileProps) {
  return <span className={`tile__secondary-text ${className}`}>{children}</span>;
}

function TertiaryText({ children, className = "" }: TileProps) {
  return <span className={`tile__tertiary-text ${className}`}>{children}</span>;
}

function Body({ children, className = "" }) {
  return <section className={`tile__body ${className}`}>{children}</section>;
}

function Footer({ children, className = "" }) {
  return <footer className={`tile__footer ${className}`}>{children}</footer>;
}

Tile.Header = Header;
Tile.PrimaryText = PrimaryText;
Tile.SecondaryText = SecondaryText;
Tile.TertiaryText = TertiaryText;
Tile.Body = Body;
Tile.Footer = Footer;

export default Tile;
