import React from "react";

const Email = ({ height = 48, width = 48, isOpen = false }) => {
  const open =
    "m24 2 18.55 11.1q.85.45 1.15 1.225.3.775.3 1.525V39q0 1.2-.9 2.1-.9.9-2.1.9H7q-1.2 0-2.1-.9Q4 40.2 4 39V15.85q0-.75.325-1.525.325-.775 1.125-1.225Zm0 23.3 16.8-9.85L24 5.35 7.2 15.45Zm0 3.35-17-10V39h34V18.65ZM24 39h17H7Z";
  const closed =
    "M7 40q-1.2 0-2.1-.9Q4 38.2 4 37V11q0-1.2.9-2.1Q5.8 8 7 8h34q1.2 0 2.1.9.9.9.9 2.1v26q0 1.2-.9 2.1-.9.9-2.1.9Zm17-15.1L7 13.75V37h34V13.75Zm0-3L40.8 11H7.25ZM7 13.75V11v26Z";

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" height={height} width={width}>
      <path
        stroke="#3246d3"
        fill="#3246d3"
        opacity={isOpen ? "1" : ".4"}
        strokeWidth="1"
        d={isOpen ? open : closed}
      />
    </svg>
  );
};

export default Email;
