import React from "react";

import ActivityItem from "./ActivityItem";

const UserActivity = (props) => {
  const activities = [
    { time: "10:45", user: "Kate Peksa", action: "Lorem ipsum dolor sit amet, consectetur adipiscing elit." },
    {
      time: "12/29/22",
      user: "Seung Kang",
      action: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    {
      time: "12/28/22",
      user: "Grace Tam",
      action: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    { time: "10:45", user: "Kate Peksa", action: "Lorem ipsum dolor sit amet, consectetur adipiscing elit." },
    {
      time: "12/29/22",
      user: "Seung Kang",
      action: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    {
      time: "12/28/22",
      user: "Grace Tam",
      action: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    { time: "10:45", user: "Kate Peksa", action: "Lorem ipsum dolor sit amet, consectetur adipiscing elit." },
    {
      time: "12/29/22",
      user: "Seung Kang",
      action: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    {
      time: "12/28/22",
      user: "Grace Tam",
      action: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    { time: "10:45", user: "Kate Peksa", action: "Lorem ipsum dolor sit amet, consectetur adipiscing elit." },
    {
      time: "12/29/22",
      user: "Seung Kang",
      action: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
    {
      time: "12/28/22",
      user: "Grace Tam",
      action: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
  ];

  const renderActivities = () => {
    return activities.map((item, i) => {
      return <ActivityItem time={item.time} user={item.user} action={item.action} key={i} />;
    });
  };

  return (
    <div className="activity__container">
      <div className="activity__title">Activity</div>
      {renderActivities()}
    </div>
  );
};

export default UserActivity;
