import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import debounce from "debounce-promise";

import { setCookie, setQueryParam } from "../../utils/Page";
import httpClient from "../../utils/httpClient";

const customSelectStyles = {
  container: (base) => ({
    ...base,
    width: "100%",
    margin: 0,
  }),
  placeholder: (base) => ({
    ...base,
    color: "#718291",
    fontSize: 14,
  }),
  control: (base, state) => ({
    ...base,
    minHeight: 42,
    borderColor: `${state.isFocused ? "#3246D3" : "#DEE5EB"}`,
    borderRadius: 8,
    boxShadow: "none",
    "&:hover": {
      borderColor: `${state.isFocused ? "#3246D3" : "#DEE5EB"}`,
    },
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 8,
    position: "relative",
    top: 0,
    marginBottom: 0,
    color: "#718291",
    fontSize: 14,
    border: "1px solid #DEE5EB",
    boxShadow: "none",
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: 210,
    borderRadius: 8,
    padding: 8,
  }),
  option: (base, state) => ({
    ...base,
    fontSize: 14,
    borderRadius: 8,
    cursor: "pointer",
    color: "#0A162F",
    backgroundColor: state.isSelected ? "#3246D3" : "#ffffff",
    "&:hover": {
      opacity: state.isFocused ? 0.7 : 1,
    },
  }),
  singleValue: (base) => ({
    ...base,
    fontSize: 14,
  }),
  input: (base) => ({
    ...base,
    fontSize: 14,
  }),
  valueContainer: (base) => ({
    ...base,
    padding: 6,
  }),
};

type CoveredEntitySelectorProps = {
  selectedEntityId: string;
  setSelectedEntityId: (arg1: string) => void;
  organizationId: string;
};

const CoveredEntitySelector = ({
  selectedEntityId,
  setSelectedEntityId,
  organizationId,
}: CoveredEntitySelectorProps) => {
  // Store entities returned from search so we can render the selected entity.
  const [entities, setEntities] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);

  const handleSelection = (option) => {
    setSelectedEntityId(option.value);
    setQueryParam("id_340b", option.value);
    const cookiePath = `/organization/${organizationId}`;
    setCookie("id_340b", option.value, cookiePath);
  };

  const fetchAvailableEntities = async (rawQuery) => {
    const query = rawQuery || selectedEntityId;

    return await httpClient
      .get(`/api/entities?${new URLSearchParams({ query }).toString()}`)
      .then((response) => {
        // @ts-ignore - need to figure out how to handle this typing
        setEntities(response);
        return response;
      });
  };

  const formatOptionLabel = ({ value, label }) => {
    return (
      <>
        <div className="t--sm">{value}</div>
        <div className="t--500">{label}</div>
      </>
    );
  };

  const selectedEntity = entities.find((e) => e.value === selectedEntityId);

  const debouncedEntityHandler = debounce(fetchAvailableEntities, 500);

  return (
    <>
      <div className="draw__item__title draw__item__title--mb-sm t--upcase t--sm d-flex align-items-center">
        {selectedEntity ? null : (
          <div className="indicator indicator--blue indicator--pulse--blue"></div>
        )}
        Covered Entity
      </div>
      <div className="chip__well">
        {selectedEntity ? (
          <div className="label label--full-width label--col label--align-l">
            <div className="label__subtitle">Name</div>
            <div className="label__value">{selectedEntity.label}</div>
            <div className="label__subtitle">340B ID</div>
            <div className="label__value">{selectedEntity.value}</div>
          </div>
        ) : null}
        <AsyncSelect
          components={{ IndicatorSeparator: null }}
          cacheOptions
          defaultOptions
          isClearable={false}
          name="id_340b"
          placeholder={"Select Covered Entity"}
          loadOptions={debouncedEntityHandler}
          formatOptionLabel={formatOptionLabel}
          styles={customSelectStyles}
          controlShouldRenderValue={false}
          hideSelectedOptions
          onChange={handleSelection}
        />
      </div>
    </>
  );
};

export default CoveredEntitySelector;
