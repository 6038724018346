import { Skeleton } from "@mui/material";
import React from "react";

const EntityDetailsHeader = ({ entityDetails }) => {
  const { isLoading, data, error } = entityDetails;

  const renderAdmin = () => {
    return (
      <div className="details__header__partition__item__content">
        <div className="profile__avatar">
          <div className="profile__avatar__initials">
            {isLoading ? <Skeleton variant="circular" width={38} height={38} /> : data.administrator?.initials}
          </div>
        </div>
        <div className="details__header__partition__item__content__details">
          <div className="details__header__partition__item__content__details__title">
            {isLoading ? <Skeleton width={210} /> : data.administrator?.name}
          </div>
          <div className="details__header__partition__item__content__details__subtitle">
            {isLoading ? <Skeleton width={300} /> : data.administrator?.email}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="details__header" style={{ border: 0 }}>
      <div className="details__header__partition">
        <div className="entities__item details__header__section__info__item">
          <div className="entities__item__icon">
            {isLoading ? <Skeleton variant="circular" width={52} height={52} /> : data.name?.charAt(0)}
          </div>
          <div className="entities__item__details">
            <div className="entities__item__details__title">{isLoading ? <Skeleton width={300} /> : data.name}</div>
            <div className="entities__item__details__subtitle">
              {isLoading ? <Skeleton width={210} /> : data.address}
            </div>
          </div>
        </div>
      </div>

      <div className="details__header__partition details__header__section__info__item" style={{ border: 0 }}>
        <div className="details__header__partition__item">
          <div className="details__header__partition__item__title">Administrator</div>
          {renderAdmin()}
        </div>
        {/* <div className="details__header__partition__item">
          <div className="details__header__partition__item__title">Eligibility</div>
          <a href={ce.eligibility_file_url || "/"}>
            <div className="details__header__partition__item__content">
              <div className="details__header__partition__item__content__action-icon">
                <i className="solid solid solid-budicon-file-download"></i>
              </div>
              <div className="details__header__partition__item__content__details">
                <div className="details__header__partition__item__content__details__title">Download File</div>
                <div className="details__header__partition__item__content__details__subtitle">340B ESP Eligibility</div>
              </div>
            </div>
          </a>
        </div> */}
      </div>
      <div className="details__header__section__info__container details__header__section__info__container--mt">
        <div className="details__header__section__info__item">
          <div className="details__header__section__info__item__title">340B ID</div>
          <div className="details__header__section__info__item__value">{isLoading ? <Skeleton /> : data.id_340b}</div>
        </div>
        <div className="details__header__section__info__item">
          <div className="details__header__section__info__item__title">Entity Type</div>
          <div className="details__header__section__info__item__value">{isLoading ? <Skeleton /> : data.type_code}</div>
        </div>
        <div className="details__header__section__info__item">
          <div className="details__header__section__info__item__title">Primary Contact</div>
          <div className="details__header__section__info__item__value">
            {isLoading ? <Skeleton /> : data.primary_contact}
          </div>
        </div>
        <div className="details__header__section__info__item">
          <div className="details__header__section__info__item__title">Authorizing Official</div>
          <div className="details__header__section__info__item__value">
            {isLoading ? <Skeleton /> : data.authorizer}
          </div>
        </div>
      </div>
      <div className="details__header__section__info__container details__header__section__info__container--bb">
        <div className="details__header__section__info__item">
          <div className="details__header__section__info__item__title">340B ESP Registration</div>
          <div className="details__header__section__info__item__value">
            {isLoading ? <Skeleton /> : data.registered}
          </div>
        </div>
        <div className="details__header__section__info__item">
          <div className="details__header__section__info__item__title">Exemptions</div>
          <div className="details__header__section__info__item__value">
            {isLoading ? <Skeleton /> : data.designations}
          </div>
        </div>
        <div className="details__header__section__info__item">
          <div className="details__header__section__info__item__title">Contracted Pharmacies</div>
          <div className="details__header__section__info__item__value">
            {isLoading ? <Skeleton /> : data.pharmacies}
          </div>
        </div>
        <div className="details__header__section__info__item">
          <div className="details__header__section__info__item__title">Users</div>
          <div className="details__header__section__info__item__value">{isLoading ? <Skeleton /> : data.users}</div>
        </div>
      </div>
    </div>
  );
};

export default EntityDetailsHeader;
