import React, { useState, useMemo } from "react";
import { search } from "../../utils/search";
import ReportDrawer from "../Common/ReportDrawer";
import ReportLayout from "../ReportLayout";
import ReportSearch from "../Common/ReportSearch";
import DownloadReport from "../Common/DownloadReport";
import NetworkFilter from "../Common/NetworkFilter";
import StatusFilter from "../Common/StatusFilter";
import Toggle from "../../Toggle";
import CommsTable from "./CommsTable";

type OviComm = {
  pharmacies: Array<any>;
  network: string;
};

type IndexProps = {
  networks: Array<{ label: string; value: string }>;
  details: Array<OviComm>;
  data_path: string;
  filename: string;
  compliance_trend_path: string;
};

export default function Index({
  networks,
  details,
  data_path,
  filename,
  compliance_trend_path,
}: IndexProps) {
  const [searchTerms, setSearchTerms] = useState("");
  const [selectedNetwork, setSelectedNetwork] = useState(networks[0].value);
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
  const [filterEligible, setFilterEligible] = useState<string | null>(null);

  const filterQuery = {
    status: selectedStatus,
    network: selectedNetwork,
    eligible: filterEligible,
  };

  const statuses = [
    { value: "At Risk", label: "AT RISK" },
    { value: "Exception", label: "EXCEPTION" },
    { value: "Suspended", label: "SUSPENDED" },
    { value: "Reinstated", label: "REINSTATED" },
  ];

  const filterTableDetails = useMemo(() => {
    var filteredData: Array<OviComm> = [];

    if (selectedNetwork?.length) {
      filteredData = details.filter(
        (detail) => detail.network === selectedNetwork
      );
    }

    if (selectedStatus?.length) {
      filteredData = filteredData.filter((detail) =>
        detail.pharmacies.some((pharmacy) => pharmacy.status === selectedStatus)
      );
    }

    if (filterEligible?.length) {
      filteredData = filteredData.filter((detail) =>
        detail.pharmacies.some(
          (pharmacy) => pharmacy.eligible === filterEligible
        )
      );
    }

    if (searchTerms) {
      filteredData = search(filteredData, searchTerms, [
        "entity_name",
        "id_340b",
      ]);
    }

    return filteredData;
  }, [filterQuery, searchTerms]);

  const downloadComponent = (
    <DownloadReport
      reportName="ovicomms"
      downloadPath={data_path}
      downloadFilename={filename}
      downloadParams={filterQuery}
    />
  );

  const networkFilter = (
    <NetworkFilter
      required={true}
      networks={networks}
      selectedNetwork={selectedNetwork}
      setSelectedNetwork={setSelectedNetwork}
    />
  );

  const statusFilter = (
    <StatusFilter
      statuses={statuses}
      selectedStatus={selectedStatus}
      setSelectedStatus={setSelectedStatus}
    />
  );

  const eligibilityToggle = (
    <>
      <div className="draw__item__title draw__item__title--mb-sm t--upcase t--sm">
        Eligible
      </div>
      <Toggle
        setValue={setFilterEligible}
        enabled_value={"eligible"}
        disabled_value={false}
        label={"Show Eligible Arrangements"}
      />
      <div className="draw__divider" />
    </>
  );

  return (
    <ReportLayout
      title="OVI Communications"
      body={
        <>
          <div className="table__actionbar table__actionbar--spacebetween">
            <ReportSearch setSearchTerms={setSearchTerms} />
          </div>
          <div style={{ marginTop: 20 }}>
            <CommsTable
              records={filterTableDetails}
              compliance_trend_path={compliance_trend_path}
            />
          </div>
        </>
      }
      aside={
        <ReportDrawer
          download={downloadComponent}
          filterComponents={[statusFilter, networkFilter]}
        >
          {eligibilityToggle}
        </ReportDrawer>
      }
    />
  );
}
