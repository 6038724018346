import React from "react";
// import DayJS from "react-dayjs";

const EligibilityCard = (props) => {
  return (
    <div className="draw__submission_card">
      <div className="draw__submission_card--half-cir-shape-1" />
      <div className="draw__submission_card--half-cir-shape-2" />
      <div className="draw__submission_card--half-cir-shape-3" />
      <div className="draw__submission_card--half-cir-shape-4" />
      <div className="draw__submission_card--half-cir-shape-5" />
      <div className="draw__submission_card__content_container">
        <div>
          <div className="draw__submission_card__title">Eligibility Files</div>
          <div className="draw__submission_card__subtitle">--</div>
        </div>
        <a href="#">
          <div className="btn btn--light btn--disabled btn--align-end" style={{ color: "#bbbbbb" }}>
            Download Files
          </div>
        </a>
      </div>
    </div>
  );
};

export default EligibilityCard;
