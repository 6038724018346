import React from "react";

const ActivityItem = (props) => {
  return (
    <div className="activity__item__container">
      <div className="activity__item__check_icon"></div>
      <div className="activity__item__text_wrapper">
        <div className="activity__item__time">{props.time}</div>
        <b>{props.user}</b>
        <span> {props.action}</span>
      </div>
    </div>
  );
};

export default ActivityItem;
