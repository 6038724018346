import React from "react";
import TileLoadingSkeleton from "./TileLoadingSkeleton";
import OverdrawnAccountCharts from "./Charts/OverdrawnAccountsChart";

const OverdrawnAccounts = ({ overdrawnAccounts }) => {
  const { isLoading, data, error } = overdrawnAccounts;

  if (isLoading) return <TileLoadingSkeleton />;

  return (
    <div className="tile">
      <div className="tile__header">
        <div className="tile__header__details">
          <div className="tile__header__details__title">Overdrawn Accounts</div>
        </div>
      </div>
      <div style={{ width: "100%", height: 258 }}>
        <OverdrawnAccountCharts data={data} />
      </div>
    </div>
  );
};

export default OverdrawnAccounts;
