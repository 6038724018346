import React from "react";
import moment from "moment";

type PolicyDateProps = {
  date: string;
};

const PolicyDate = ({ date }: PolicyDateProps) => {
  return (
    <>
      <div className="draw__item__title draw__item__title--mb-sm t--upcase t--sm">
        Policy Date
      </div>
      <div className="badge">{`${moment(date).format("MMMM D, YYYY")}`}</div>
    </>
  );
};

export default PolicyDate;
