import React from "react";
import AsyncSelect from "react-select/async";
import debounce from "debounce-promise";
import httpClient from "./utils/httpClient";
import { StylesConfig } from "react-select";

const customSelectStyles : StylesConfig<{value: string, label: string}, false> = {
  control: (base) => ({
    ...base,
    backgroundColor: '#f2f5f8',
    border: 'none',
    borderRadius: 8,
  }),
  indicatorsContainer: (base) => ({
    ...base,
    display: 'none'
  })
};

const Search = ({ org_id } : { org_id: string }) => {
  const fetchAvailableEntities = async (query) => {
    return await httpClient.get(`/api/entities?${new URLSearchParams({ query }).toString()}`)
      .then((response) => response);
  };

  const formatOptionLabel = ({ value, label }) => {
    return (
      <>
        <div className="t--sm t--subtitle">{value}</div>
        <div className="t--500">{label}</div>
      </>
    );
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      const searchInput = (event.target as HTMLInputElement).value;

      // For demo account mfr switching purposes. We hijack the async select input and make the request to
      // switch manufacturers.
      if (searchInput.startsWith("mfr:")) {
        event.preventDefault();
        window.location.href = `/organizations/${org_id}/search?search=${searchInput}`;
      }
    }
  };

  const handleSelection = (value) => {
    window.location.href = `/organization/${org_id}/entities?id_340b=${value.value}`;
  };

  return (
    <div className="search__container" style={{ marginLeft: 20, zIndex: 101 }}>
      <AsyncSelect
        components={{ IndicatorSeparator: null, DropdownIndicator: null }}
        cacheOptions
        defaultOptions
        isClearable={false}
        name="covered_entity_search"
        placeholder="Search for 340B ID or Covered Entity"
        styles={customSelectStyles}
        loadOptions={debounce(fetchAvailableEntities, 500)}
        formatOptionLabel={formatOptionLabel}
        controlShouldRenderValue={false}
        hideSelectedOptions={true}
        onChange={handleSelection}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
};

export default Search;
