import { isSubstrIncludedInString, doesStringStartWith } from "../utils/String";

export function search<T>(pharmacies: Array<T>, { searchQuery }) {
  const substringMatchKeys = ["address", "name"];
  const startMatchKeys = ["dea"];

  return pharmacies.filter((pharmacy) => {
    return (
      substringMatchKeys.some((key) =>
        isSubstrIncludedInString(pharmacy[key], searchQuery)
      ) ||
      startMatchKeys.some((key) =>
        doesStringStartWith(pharmacy[key], searchQuery)
      )
    );
  });
}

interface Pharm {
  status: string;
}

export function filterByStatus<T extends Pharm>(
  pharmacies: Array<T>,
  searchTerms
) {
  return search(pharmacies, searchTerms).filter((pharmacy) => {
    return searchTerms.filterQuery.some((query) => pharmacy.status == query);
  });
}
