import React, { useRef, useEffect, useState } from "react";
import AccountsTable from "./AccountsTable";
import Draw from "./AccountsDraw";
import AccountsModal from "./AccountsModal";
import AccountHistoryModal from "./AccountHistoryModal";
import BalanceHistory from "./BalanceHistory";
import Notice from "../Notice";
import httpClient from "../utils/httpClient";
import debounce from "debounce-promise";
import moment from "moment";
import { Pie } from "@nivo/pie";
import Tile from "../Base/Tile";
import InvisibleOverlay from "../Base/InvisibleOverlay";
import DisabledTabModal from "../Modals/DisabledTabModal";

import {
  Account,
  AccountProductRow,
  NetworkOption,
  ProductOption,
} from "../types/models";

const DEFAULT_CE = {
  entity_name: "Henry Ford",
  id_340b: "DSH230053",
};

type AccountsIndexProps = {
  default_ce: {
    entity_name: string;
    id_340b: string;
  };
  policy_annc_date: string;
  inhouse_query_path: string;
  query_path: string;
  inhouse_monthly_balance_path: string;
  monthly_balance_path: string;
  submission_details_path: string;
  status_history_path: string;
  submissions_path: string;
  inhouse_ledger_path: string;
  ledger_path: string;
  organization_id: string;
  daily_balance_path: string;
  show_in_house_filter: boolean;
  networks: NetworkOption[];
  products: ProductOption[];
  ovi_client: boolean;
};

export default function AccountsIndex({
  default_ce,
  policy_annc_date,
  inhouse_query_path,
  query_path,
  inhouse_monthly_balance_path,
  monthly_balance_path,
  organization_id,
  daily_balance_path,
  show_in_house_filter,
  submission_details_path,
  inhouse_ledger_path,
  ledger_path,
  networks,
  products,
  ovi_client,
}: AccountsIndexProps) {
  const queryParams = new URLSearchParams(document.location.search);
  const id340b = queryParams.get("id") || queryParams.get("id_340b");
  const toggleOverdrawnOnly = queryParams.get("toggleOverdrawnOnly") === "true";
  const entityID = id340b || DEFAULT_CE.id_340b;
  const [isLoading, setIsLoading] = useState(false);
  const [statusCounts, setStatusCounts] = useState({ pos: 0, neg: 0 });
  const [selectedAccount, setSelectedAccount] =
    useState<AccountProductRow | null>();
  const [displayModal, setDisplayModal] = useState(false);
  const [displayHistoryModal, setDisplayHistoryModal] = useState(false);
  const [entity, setEntity] = useState(id340b ? default_ce : DEFAULT_CE);
  const [accounts, setAccounts] = useState<Array<Account>>([]);
  const [productHistory, setProductHistory] = useState({});
  const [notice, setNotice] = useState({
    kind: "error",
    open: false,
    message: "",
  });
  const [filterQuery, setFilterQuery] = useState({
    status: toggleOverdrawnOnly ? "NEGATIVE" : null,
  });
  const [searchQuery, setSearchQuery] = useState({
    product_names: [],
    pid: null,
    grouping: "product_name",
    enable_exclusions: null,
    // Default date range to Manufacturer policy_annc_date
    date_range: {
      start: moment(policy_annc_date).format("YYYY-MM-DD"),
      end: moment().format("YYYY-MM-DD"),
    },
    id_340b: entityID || entity?.id_340b,
    pharmacy_type: "contract",
    networks: [],
  });
  const [modalOpen, setModalOpen] = useState(false);

  const isMounted = useRef(false);

  useEffect(() => {
    setIsLoading(true);
    debouncedFetchHandler();
  }, [searchQuery]);

  useEffect(() => {
    if (
      (isMounted.current && !accounts) ||
      (isMounted.current && accounts.length == 0)
    ) {
      setNotice({
        kind: "success",
        open: true,
        message: "The selected entity has no accounts for the current filters.",
      });
    }

    isMounted.current = true;
  }, [accounts]);

  const fetch = () => {
    const path =
      searchQuery.pharmacy_type === "inhouse" ? inhouse_query_path : query_path;
    const monthly_path =
      searchQuery.pharmacy_type === "inhouse"
        ? inhouse_monthly_balance_path
        : monthly_balance_path;

    httpClient.get(path, { params: { ...searchQuery } }).then((res) => {
      // @ts-ignore - need to figure out how to handle this typing
      setAccounts(res.accounts);
      // @ts-ignore - need to figure out how to handle this typing
      setEntity({ entity_name: res.entity_name, id_340b: res.id_340b });
      // @ts-ignore - need to figure out how to handle this typing
      setStatusCounts({ pos: res.pos, neg: res.neg });
    });

    httpClient.get(monthly_path, { params: { ...searchQuery } }).then((res) => {
      setProductHistory(res);
      setIsLoading(false);
    });
  };

  const debouncedFetchHandler = debounce(fetch, 500);

  const handleFilter = (name: string, value) => {
    setFilterQuery({ ...filterQuery, [name]: value });
  };

  const handleSearch = (name: string, value) => {
    setSearchQuery({ ...searchQuery, [name]: value });
  };

  const filteredAccounts = filterQuery.status
    ? accounts.map((a) => {
        return {
          ...a,
          accounts: a.accounts.filter((ac) => ac.status === filterQuery.status),
        };
      })
    : accounts;

  const renderDisabledModal = () => {
    if (modalOpen)
      return (
        <DisabledTabModal
          closeModal={() => setModalOpen(false)}
          headerText="OVI is disabled for this account."
          bodyText="Please reach out to your account lead for more information."
        />
      );
  };

  return (
    <>
      {renderDisabledModal()}
      <InvisibleOverlay active={!ovi_client} onClick={() => setModalOpen(true)}>
        <div style={{ display: "flex" }}>
          <div className="content__container">
            <div className="page-details__container">
              <div className="page-details__title">Submission Balances</div>
            </div>
            <div className="flex__container flex__container--gutter">
              <Tile>
                <Tile.Header className="flex-column">
                  <Tile.SecondaryText>Account Details</Tile.SecondaryText>
                  <Tile.TertiaryText>
                    Updated - {moment().startOf("month").format("M/D/YYYY")}
                  </Tile.TertiaryText>
                </Tile.Header>
                <Tile.Body>
                  <div className="d-flex align-items-center gap-1">
                    <Tile.PrimaryText>{statusCounts.neg}</Tile.PrimaryText>
                    <Tile.TertiaryText>
                      Negative Balance Accounts
                    </Tile.TertiaryText>
                  </div>
                  <Pie
                    data={
                      accounts && accounts.length
                        ? [
                            {
                              id: "positive",
                              label: "Positive",
                              value: statusCounts.pos,
                            },
                            {
                              id: "negative",
                              label: "Negative",
                              value: statusCounts.neg,
                            },
                          ]
                        : []
                    }
                    width={200}
                    height={180}
                    margin={{ top: 5, right: 40, bottom: 30, left: 40 }}
                    innerRadius={0.8}
                    activeInnerRadiusOffset={4}
                    activeOuterRadiusOffset={4}
                    enableArcLabels={false}
                    enableArcLinkLabels={false}
                    colors={["#3246D3", "#FF681F"]}
                    legends={[
                      {
                        anchor: "bottom",
                        direction: "row",
                        justify: false,
                        translateX: 0,
                        translateY: 25,
                        itemWidth: 70,
                        itemHeight: 10,
                        symbolSpacing: 4,
                        symbolSize: 6,
                        symbolShape: "circle",
                      },
                    ]}
                  />
                </Tile.Body>
              </Tile>
              <BalanceHistory productHistory={productHistory} />
            </div>
            <div style={{ marginTop: 20 }}>
              <AccountsTable
                accounts={filteredAccounts}
                setDisplayHistoryModal={setDisplayHistoryModal}
                setDisplayModal={setDisplayModal}
                setSelectedAccount={setSelectedAccount}
                handleSearch={handleSearch}
                isLoading={isLoading}
              />
            </div>
          </div>
          <div className="draw__container draw__container--scroll">
            <Draw
              products={products}
              searchQuery={searchQuery}
              filterQuery={filterQuery}
              handleSearch={handleSearch}
              handleFilter={handleFilter}
              initialEntity={{
                value: entity?.id_340b,
                label: entity?.entity_name,
              }}
              policyStartDate={policy_annc_date}
              organizationId={organization_id}
              showInHouseFilter={show_in_house_filter}
              networks={networks}
              toggleOverdrawnOnly={toggleOverdrawnOnly}
            />
          </div>
          {displayModal && selectedAccount && (
            <AccountsModal
              displayModal={displayModal}
              setDisplayModal={setDisplayModal}
              selectedAccount={selectedAccount}
              searchQuery={searchQuery}
              ledgerPath={ledger_path}
              inhouseLedgerPath={inhouse_ledger_path}
              balanceHistoryPath={monthly_balance_path}
              inhouseBalanceHistoryPath={inhouse_monthly_balance_path}
              submissionDetailsPath={submission_details_path}
            />
          )}
          {displayHistoryModal && (
            <AccountHistoryModal
              displayModal={displayHistoryModal}
              setDisplayModal={setDisplayHistoryModal}
              query={searchQuery}
              dailyBalancePath={daily_balance_path}
            />
          )}
          <Notice details={notice} setNoticeState={setNotice} />
        </div>
      </InvisibleOverlay>
    </>
  );
}
