import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MosaicLogo from "~/images/mosaic_logo.png";
import Illustration from "~/images/illustration.png";

const CustomTextField = withStyles({
  root: {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    "& label.Mui-focused": {
      color: "#3D71FF",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
      "& fieldset": {
        borderColor: "#DEE7EE",
      },
      "&:hover fieldset": {
        borderColor: "#DEE7EE",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3D71FF",
      },
    },
  },
})(TextField);

const TwoFactor = (props) => {
  const getActionLink = () => {
    if (props.otp_secret) {
      return `/organization/${props.org_id}/createtwofactor`;
    } else {
      return `/organization/${props.org_id}/verifytwofactor`;
    }
  };

  const renderTitle = () => {
    if (props.otp_secret) {
      return (
        <React.Fragment>
          <h1>Setup Authenticator</h1>
          <p>Scan the QR code with your choice of authenticator.</p>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <h1>Enter Authenticator Code</h1>
          <p>Enter the code from your authenticator.</p>
        </React.Fragment>
      );
    }
  };

  const renderForm = () => {
    return (
      <div className="auth__form">
        <img src={props.mfr_logo} style={{ width: 150, marginBottom: 30 }} />

        {renderTitle()}
        <form method="POST" action={getActionLink()} className="authentication__form">
          <div className="">
            <img src={props.qr_code} style={{ textAlign: "center" }} />
            <input type="hidden" id="token" name="otp_secret" value={props.otp_secret} />
            <CustomTextField
              label="Authenticator Code"
              margin="normal"
              variant="outlined"
              id="code"
              name="otp_attempt"
              fullWidth
              required
              autoFocus
            />
          </div>
          <div className="auth__form__footer">
            <button className="btn" style={{ flexGrow: 1, alignSelf: "inherit" }} type="submit">
              SUBMIT
            </button>
            <div className="authentication__info" style={{ marginTop: 20 }}>
              <span>Need help? </span>
              <a className="authentication__link" href="/">
                Contact Us
              </a>
            </div>
          </div>
        </form>
      </div>
    );
  };

  const renderBillboard = () => {
    return (
      <div className="">
        <div className="auth__container--billboard__title">
          <div className="auth__container--billboard__logo">
            <img src={MosaicLogo} />
          </div>

          <img src={Illustration} style={{ maxWidth: 450 }} />

          <div style={{ marginTop: 30 }}>
            <span className="t--xl t--500">340B ESP™</span>
            <br />
            <span className="t--lg">Linking Healthcare</span>
            <p className="t--md color--subtitle" style={{ marginTop: 20, maxWidth: 380 }}>
              340B ESP™ allows 340B covered entities and pharmaceutical manufacturers to work collaboratively to resolve
              duplicate discounts.
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="auth__wrapper">
      <div className="auth__container">
        <div className="auth__container--billboard">{renderBillboard()}</div>
        <div className="auth__container--form">
          <div className="auth__container--form--logout">
            <a href="/logout">
              <button className="btn" style={{ flexGrow: 1, alignSelf: "inherit" }}>
                Logout
              </button>
            </a>
          </div>
          {renderForm()}
        </div>
      </div>
    </div>
  );
};

export default TwoFactor;
