import React, { useEffect, useState } from "react";
import Button from "../../Base/Button";

import { Close } from "@material-ui/icons";
import PharmacySubTable from "./PharmacySubTable";
import { ProductSummary } from "../../types/models";
import httpClient from "../../utils/httpClient";
import EventsTable from "../OviCommunications/EventsTable";

type DetailModalProps = {
  products: ProductSummary[];
  setDisplayModal: (arg1: boolean) => void;
  pharmacy_detail_path: string;
  id_340b: string;
  pid: string;
};

const DetailModal = ({
  products,
  setDisplayModal,
  pharmacy_detail_path,
  id_340b,
  pid,
}: DetailModalProps) => {
  const [events, setEvents] = useState<any[]>([]);
  const [emails, setEmails] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    httpClient
      .get(pharmacy_detail_path, { params: { id_340b, pid } })
      .then((res) => {
        // @ts-ignore need to figure out typing
        setEvents(res.events);
        // @ts-ignore need to figure out typing
        setEmails(res.emails);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, []);

  return (
    <div
      className="modal__background"
      style={{ alignItems: "flex-end" }}
      onClick={() => setDisplayModal(false)}
    >
      <div className="modal__panel animated fadeInUpScale" onClick={(e) => e.stopPropagation()}>
        <div className="container">
          <Button variant="link" className="modal__close" onClick={() => setDisplayModal(false)}>
            <Close />
          </Button>
          <div className="page-details__title">Account Details</div>
          <div className="d-flex justify-content-between mb-4">
            <div className="content__bordered_container" style={{ width: "50%", height: "auto" }}>
              <EventsTable events={events} loading={loading} />
            </div>
            <div className="content__bordered_container" style={{ width: "40%", height: "auto" }}>
              <table className="secondary">
                <thead>
                  <tr className="sm">
                    <th className="rnd-l rnd-r">Users Emailed</th>
                  </tr>
                </thead>
                <tbody>
                  {emails.map((email, i) => {
                    return (
                      <tr className="sm t--sm" key={i}>
                        <td>{email}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="content__bordered_container" style={{ width: "100%", height: "auto" }}>
            <PharmacySubTable products={products} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailModal;
