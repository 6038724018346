import React, { useState } from "react";
import Button from "../Base/Button";

export default function Tabs({ tabs, initialActiveTabId }) {
  const [activeTab, setActiveTab] = useState(initialActiveTabId);
  const { content } = tabs.find((tab) => tab.id === activeTab);

  return (
    <div className="tabs">
      <div className="tabs__container">
        {tabs.map(({id, label, isDisabled}) => (
          <Button
            className={`${activeTab === id ? "tabs__item--active" : "tabs__item"}`}
            variant="link"
            isDisabled={isDisabled}
            onClick={() => setActiveTab(id)}
            key={id}
          >
            {label}
          </Button>
        ))}
      </div>
      <div className="tabs__content">{content}</div>
    </div>
  );
}