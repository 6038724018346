import React from "react";

const User = ({ height = 14, width = 14 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      height={height}
      width={width}
      fill="none"
    >
      <circle cx="7" cy="7" r="7" fill="#fff" />
      <path
        d="M2.695 10.57A7.311 7.311 0 0 1 4.69 9.493 6.822 6.822 0 0 1 7 9.1c.805 0 1.575.131 2.31.393.735.263 1.4.622 1.995 1.077.408-.478.726-1.02.954-1.627.227-.607.341-1.255.341-1.943 0-1.552-.545-2.873-1.636-3.964C9.873 1.946 8.552 1.4 7 1.4s-2.873.545-3.963 1.636C1.946 4.127 1.4 5.448 1.4 7c0 .688.114 1.336.342 1.943.227.606.545 1.149.953 1.627zM7 7.7c-.688 0-1.269-.236-1.742-.708-.472-.473-.708-1.054-.708-1.742s.236-1.269.708-1.742C5.731 3.036 6.312 2.8 7 2.8s1.269.236 1.742.708c.472.473.708 1.054.708 1.742s-.236 1.269-.708 1.742C8.269 7.464 7.688 7.7 7 7.7zM7 14a6.813 6.813 0 0 1-2.73-.552 7.065 7.065 0 0 1-2.222-1.495A7.064 7.064 0 0 1 .551 9.73 6.813 6.813 0 0 1 0 7c0-.968.184-1.878.552-2.73a7.064 7.064 0 0 1 1.495-2.222A7.074 7.074 0 0 1 4.27.55 6.82 6.82 0 0 1 7 0a6.82 6.82 0 0 1 2.73.55 7.073 7.073 0 0 1 2.223 1.497c.63.63 1.128 1.371 1.495 2.223.368.852.552 1.762.552 2.73s-.184 1.878-.552 2.73a7.064 7.064 0 0 1-1.495 2.223 7.064 7.064 0 0 1-2.223 1.495A6.813 6.813 0 0 1 7 14z"
        fill="#3246D3"
      />
    </svg>
  );
};

export default User;
