import React, { useState, Fragment } from "react";
import ComplianceChart from "./Charts/ComplianceChart";
import ChartLoadingSkeleton from "./ChartLoadingSkeleton";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import EventsTable from "./EventsTable";
import { uniq, flatten  } from "lodash";
import httpClient from "../../utils/httpClient";

const SubTable = (props) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [eventsExpandedForPharmacy, setEventsExpandedForPharmacy] = useState({})

  // useEffect(() => {
  //   fetchComplianceData();
  // }, []);

  const fetchComplianceData = () => {
    return httpClient
      .get(props.path, {
        params: {
          id_340b: props.id_340b,
          pid: props.pid,
          network: props.network,
        },
      })
      .then(function (response) {
        setData(response.trend_data);
        setLoading(false);
      });
  };

  const renderChart = () => {
    if (loading) {
      return <ChartLoadingSkeleton />;
    } else {
      return (
        <div style={{ height: 170, marginTop: 10 }}>
          <ComplianceChart data={data} />
        </div>
      );
    }
  };

  const renderEventsExpandIcon = (rowKey) => {
    const onClick = () => setEventsExpandedForPharmacy({...eventsExpandedForPharmacy, [rowKey]: !eventsExpandedForPharmacy[rowKey]})
    const sharedAttributes = { fontSize: "small", onClick: onClick }

    return <div className="btn btn--sqr" style={{ verticalAlign: "middle" }}>
      {eventsExpandedForPharmacy[rowKey] ? <ExpandLess {...sharedAttributes} /> : <ExpandMore {...sharedAttributes} />}
    </div>
  }

  const renderEventsRow = (rowKey, pharmacy) => {
    return eventsExpandedForPharmacy[rowKey] && (
      <tr><td colSpan="4"><EventsTable events={pharmacy.events} /></td></tr>
    )
  }

  const renderPharmacyRows = () => {
    if (props.pharmacies) {
      return props.pharmacies.map((row, i) => {
        const rowKey = `${props.id_340b}|${i}`

        return (
          <Fragment key={i}>
            <tr className="sm t--sm no-bottom-border">
              <td>{renderEventsExpandIcon(rowKey)}</td>
              <td className="t--sm">
                <div className="table__text__stack">
                  <div className="t--sm t--500">{row.pharmacy_label || row.pharmacy_name}</div>
                  <div className="t--sm t--subtitle">{row.pharmacy_address}</div>
                </div>
              </td>
              <td>
                <div className="badge">
                  <div className={`badge__indicator badge__indicator--${getIndicatorName(row.status.toUpperCase())}`} />
                  {row.status}
                </div>
              </td>
              <td>{row.effective_date}</td>
            </tr>
            {renderEventsRow(rowKey, row)}
          </Fragment>
        );
      });
    }
  };

  const getIndicatorName = (name) => {
    var status = {
      "AT RISK": "orange",
      COMPLIANT: "green",
      EXCEPTION: "blue",
      "IN-HOUSE - ELIGIBLE": "yellow",
      REINSTATED: "green",
      SUSPENDED: "red",
    };

    return status[name];
  };

  const emails = uniq(flatten(props.pharmacies.map(pharmacy => pharmacy.emails)))

  const renderEmailRows = () => {
    if (emails) {
      return emails.map((email, i) => {
        return (
          <tr className="sm t--sm" key={i}>
            <td>{email}</td>
          </tr>
        );
      });
    }
  };

  // TODO: SCRUM-2229 evaluate whether compliance history panel should be deprecated
  const renderComplianceHistory = () => {
    {/* <div className="tile" style={{ flexGrow: 1, position: "relative" }}>
      <div className="tile__header">
        <div className="tile__header__details">
          <div className="tile__header__details__title align-left">Compliance History</div>
          <div className="tile__header__details__subtitle">Compliance trend reflects ledger data as of today</div>
        </div>
      </div>
      <div className="flex__container flex__container--align-center"></div>
      {renderChart()}
    </div> */}
  }

  const renderTable = () => {
    return (
      <>
        {renderComplianceHistory()}
        <div className="row">
          <div className="col-7">
            <div className="table__container">
              <table className="secondary">
                <thead>
                  <tr className="sm">
                    <th style={{ width: "10px" }} className="rnd-l"></th>
                    <th>Pharmacy</th>
                    <th style={{ width: "100px" }}>Status</th>
                    <th style={{ width: "178px" }}  className="rnd-r">Effective Date</th>
                  </tr>
                </thead>
                <tbody>{renderPharmacyRows()}</tbody>
              </table>
            </div>
          </div>
          <div className="col-5">
            <div className="table__container">
              <table className="secondary">
                <thead>
                  <tr className="sm">
                    <th className="rnd-l rnd-r">Users Emailed</th>
                  </tr>
                </thead>
                <tbody>{renderEmailRows()}</tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    );
  };

  return <div>{renderTable()}</div>;
};

export default SubTable;
