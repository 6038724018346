import React from "react";
import { numberWithCommas } from "../../utils/NumberFormatter";
import HorizontalBarChart from "../../Charts/HorizontalBarChart";

import { ProductSummary } from "../../types/models";

type PharmacySubTableProps = {
  products: ProductSummary[];
};

const PharmacySubTable = ({ products }: PharmacySubTableProps) => {
  const getRatio = (row) => {
    if (row.debits_wac == 0) {
      return 100;
    }

    return (Math.abs(row.credits_wac) / Math.abs(row.debits_wac)) * 100;
  };

  const renderRows = products.map((row, i) => {
    return (
      <tr className="sm t--sm" key={i}>
        <td>
          <div className="t--sm t--500">{row.product_name.toUpperCase()}</div>
        </td>
        <td className="t--sm t--500">{row.last_submission ? row.last_submission : "--"}</td>

        <td className="t--sm t--500">{numberWithCommas(row.debits_qty)}</td>
        <td className="t--sm t--500">{numberWithCommas(row.credits_qty)}</td>
        <td className="t--sm t--500">$ {numberWithCommas(row.debits_wac)}</td>
        <td className="t--sm t--500">$ {numberWithCommas(row.credits_wac)}</td>
        <td>
          <HorizontalBarChart variant="danger" progress={getRatio(row)} />
        </td>
      </tr>
    );
  });

  return (
    <div>
      <table className="primary">
        <thead>
          <tr className="sm">
            <th>Product Name</th>
            <th>Last Submission</th>
            <th>Purchased Units</th>
            <th>Submitted Units</th>
            <th>Purchases</th>
            <th>Claims</th>
            <th>Percentage</th>
          </tr>
        </thead>
        <tbody>{renderRows}</tbody>
      </table>
    </div>
  );
};

export default PharmacySubTable;
