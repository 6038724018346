import React, { useState, useEffect } from "react";
import { ExpandMore, MoreHoriz } from "@material-ui/icons";
import StyledCheckbox from "../../Base/StyledCheckbox";
import ConfirmationModal from "../../ConfirmationModal";
import _ from "lodash";

import { MfrException, Suspension } from "../../types/models";

const NetworkCheckbox = ({ network, handleClick, checked, chain = false }) => {
  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <>
      <div className="flex__container flex__container--align-center">
        <StyledCheckbox
          color="primary"
          onClick={handleClick}
          checked={checked}
        />
        <span style={{ marginLeft: 8 }}>
          {capitalizeFirstLetter(network)} {chain ? "Chain" : "Pharmacy"}
        </span>
      </div>
      <div className="line-divider" style={{ margin: "8px 0" }}></div>
    </>
  );
};

type DropDownMenuProps = {
  open: boolean;
  pharmacy_id: string;
  submitting: boolean;
  networks: Array<string>;
  exceptions: Array<MfrException>;
  suspensions: Array<Suspension>;
  submitExceptions: (arg1: any) => void;
  submitSuspensions: (arg1: any) => void;
  deleteException: (arg1: any) => void;
  deleteSuspension: (arg1: any) => void;
  onOpenChange: (arg: any) => void;
  chainName: string;
  chainEnforcement: boolean;
};

type NetworkChain = {
  network: string;
  chain: boolean;
};

export default function DropDownMenu({
  open,
  pharmacy_id,
  submitting,
  networks,
  exceptions,
  suspensions,
  submitExceptions,
  submitSuspensions,
  deleteException,
  deleteSuspension,
  onOpenChange,
  chainName,
  chainEnforcement,
}: DropDownMenuProps) {
  const [renderContent, setRenderContent] = useState<string | null>();
  const [exceptionsToAdd, setExceptionsToAdd] = useState<NetworkChain[]>([]);
  const [exceptionsToDelete, setExceptionsToDelete] = useState<
    Array<string | number>
  >([]);
  const [suspensionsToAdd, setSuspensionsToAdd] = useState<NetworkChain[]>([]);
  const [suspensionsToDelete, setSuspensionsToDelete] = useState<
    Array<string | number>
  >([]);
  const [displayConfirmation, setDisplayConfirmation] = useState(false);

  useEffect(() => {
    if (submitting === false) {
      reset();
    }
  }, [submitting]);

  useEffect(() => {
    if (displayConfirmation == false) {
      reset();
      onOpenChange(null);
    }
  }, [displayConfirmation]);

  const handleCheckException = (
    checked: boolean,
    network: string,
    chain: boolean
  ) => {
    if (checked) {
      setExceptionsToAdd([...exceptionsToAdd, { network, chain }]);
    } else {
      setExceptionsToAdd(
        exceptionsToAdd.filter(
          (item) => !(item.network === network && item.chain === chain)
        )
      );
    }
  };

  const handleCheckDeleteException = (
    checked: boolean,
    exceptionId: string | number
  ) => {
    if (checked) {
      setExceptionsToDelete([...exceptionsToDelete, exceptionId]);
    } else {
      setExceptionsToDelete(
        exceptionsToDelete.filter((item) => item !== exceptionId)
      );
    }
  };

  const handleCheckSuspension = (
    checked: boolean,
    network: string,
    chain: boolean
  ) => {
    if (checked) {
      setSuspensionsToAdd([...suspensionsToAdd, { network, chain }]);
    } else {
      setSuspensionsToAdd(
        suspensionsToAdd.filter(
          (item) => !(item.network === network && item.chain === chain)
        )
      );
    }
  };

  const handleCheckDeleteSuspension = (checked: boolean, suspensionId) => {
    if (checked) {
      setSuspensionsToDelete([...suspensionsToDelete, suspensionId]);
    } else {
      setSuspensionsToDelete(
        suspensionsToDelete.filter((item) => item !== suspensionId)
      );
    }
  };

  const reset = () => {
    setRenderContent(null);
    setExceptionsToAdd([]);
    setSuspensionsToAdd([]);
    setExceptionsToDelete([]);
    setSuspensionsToDelete([]);
    setDisplayConfirmation(false);
  };

  const handleDropDownClose = () => {
    reset();
    onOpenChange(null);
  };

  const renderSuspensions = renderContent === "suspensions";
  const renderExceptions = renderContent === "exceptions";
  const renderDeleteExceptions = renderContent === "deleteExceptions";
  const renderDeleteSuspensions = renderContent === "deleteSuspensions";

  const handleSubmit = () => {
    if (renderExceptions) {
      const exception_data = exceptionsToAdd.map((e) => {
        return {
          ...e,
          pid: pharmacy_id,
          chain_name: e.chain ? chainName : null,
        };
      });

      submitExceptions(exception_data);
      reset();
    }

    if (renderSuspensions) {
      const suspension_data = suspensionsToAdd.map((s) => {
        return {
          ...s,
          pid: pharmacy_id,
          chain_name: s.chain ? chainName : null,
        };
      });

      submitSuspensions(suspension_data);
      reset();
    }

    if (renderDeleteExceptions) {
      exceptionsToDelete.forEach((exceptionId) => {
        deleteException(exceptionId);
      });

      reset();
    }

    if (renderDeleteSuspensions) {
      suspensionsToDelete.forEach((suspensionId) => {
        deleteSuspension(suspensionId);
      });

      reset();
    }
  };

  const enableSubmit = () => {
    if (renderExceptions) {
      return exceptionsToAdd.length > 0;
    }

    if (renderSuspensions) {
      return suspensionsToAdd.length > 0;
    }

    if (renderDeleteExceptions) {
      return exceptionsToDelete.length > 0;
    }

    if (renderDeleteSuspensions) {
      return suspensionsToDelete.length > 0;
    }
  };

  const renderOptions = () => {
    return (
      <div className="list__container">
        {remainingExceptionNetworks.length > 0 && (
          <>
            <div
              className="list__item list__item--rad list__item--spread"
              onClick={() => setRenderContent("exceptions")}
            >
              Create Exception
              <ExpandMore fontSize="small" />
            </div>
            {renderExceptions && renderExceptionContent}
          </>
        )}
        {remainingSuspensionNetworks.length > 0 && (
          <>
            <div
              className="list__item list__item--rad  list__item--spread"
              onClick={() => setRenderContent("suspensions")}
            >
              Create Suspension
              <ExpandMore fontSize="small" />
            </div>
            {renderSuspensions && renderSuspensionContent}
          </>
        )}
        {exceptions.length > 0 && (
          <>
            <div
              className="list__item list__item--rad  list__item--spread"
              onClick={() => setRenderContent("deleteExceptions")}
            >
              Delete Exception
              <ExpandMore fontSize="small" />
            </div>
            {renderDeleteExceptions && renderDeleteExceptionContent}
          </>
        )}
        {suspensions.length > 0 && (
          <>
            <div
              className="list__item list__item--rad  list__item--spread"
              onClick={() => setRenderContent("deleteSuspensions")}
            >
              Delete Suspension
              <ExpandMore fontSize="small" />
            </div>
            {renderDeleteSuspensions && renderDeleteSuspensionContent}
          </>
        )}
      </div>
    );
  };

  const remainingExceptionNetworks = networks.filter(
    (network) => !_.find(exceptions, { network, chain: false })
  );

  const remainingChainExceptionNetworks = networks.filter(
    (network) => !_.find(exceptions, { network, chain: true })
  );

  const renderExceptionContent = (
    <div className="dropdown__option">
      {remainingExceptionNetworks.map((network) => {
        return (
          <NetworkCheckbox
            key={`${network}-false`}
            network={network}
            chain={false}
            handleClick={(e) =>
              handleCheckException(
                (e.target as HTMLInputElement).checked,
                network,
                false
              )
            }
            checked={!!_.find(exceptionsToAdd, { network, chain: false })}
          />
        );
      })}
      {chainEnforcement &&
        remainingChainExceptionNetworks.map((network) => {
          return (
            <NetworkCheckbox
              key={`${network}-true`}
              network={network}
              chain={true}
              handleClick={(e) =>
                handleCheckException(
                  (e.target as HTMLInputElement).checked,
                  network,
                  true
                )
              }
              checked={!!_.find(exceptionsToAdd, { network, chain: true })}
            />
          );
        })}
    </div>
  );

  const renderDeleteExceptionContent = (
    <div className="dropdown__option">
      {exceptions.map((exception) => {
        return (
          <NetworkCheckbox
            key={`exception-${exception.id}`}
            network={exception.network}
            chain={chainEnforcement && exception.chain}
            handleClick={(e) =>
              handleCheckDeleteException(
                (e.target as HTMLInputElement).checked,
                exception.id
              )
            }
            checked={exceptionsToDelete.includes(exception.id)}
          />
        );
      })}
    </div>
  );

  const remainingSuspensionNetworks = networks.filter(
    (network) => !_.find(suspensions, { network, chain: false })
  );

  const remainingChainSuspensionNetworks = networks.filter(
    (network) => !_.find(suspensions, { network, chain: true })
  );

  const renderSuspensionContent = (
    <div className="dropdown__option">
      {remainingSuspensionNetworks.map((network) => {
        return (
          <NetworkCheckbox
            key={`${network}-false`}
            network={network}
            chain={false}
            handleClick={(e) =>
              handleCheckSuspension(
                (e.target as HTMLInputElement).checked,
                network,
                false
              )
            }
            checked={!!_.find(suspensionsToAdd, { network, chain: false })}
          />
        );
      })}
      {chainEnforcement &&
        remainingChainSuspensionNetworks.map((network) => {
          return (
            <NetworkCheckbox
              key={`${network}-true`}
              network={network}
              chain={true}
              handleClick={(e) =>
                handleCheckSuspension(
                  (e.target as HTMLInputElement).checked,
                  network,
                  true
                )
              }
              checked={!!_.find(suspensionsToAdd, { network, chain: true })}
            />
          );
        })}
    </div>
  );

  const renderDeleteSuspensionContent = (
    <div className="dropdown__option">
      {suspensions.map((suspension) => {
        return (
          <NetworkCheckbox
            key={`suspension-${suspension.id}`}
            network={suspension.network}
            chain={chainEnforcement && suspension.chain}
            handleClick={(e) =>
              handleCheckDeleteSuspension(
                (e.target as HTMLInputElement).checked,
                suspension.id
              )
            }
            checked={suspensionsToDelete.includes(suspension.id)}
          />
        );
      })}
    </div>
  );

  const renderDropdownMenu = (
    <div
      className="dropdown__container"
      style={{ top: 30, right: 0, left: "auto" }}
    >
      <div className="dropdown__header">
        <div className="dropdown__header__title">Options</div>
        <div
          className="dropdown__header__close"
          onClick={() => handleDropDownClose()}
        >
          <div className="solid solid-budicon-cross-ui" />
        </div>
      </div>
      {renderOptions()}
      <div
        className="line-divider"
        style={{ marginTop: 12, marginBottom: 12 }}
      ></div>
      <div style={{ display: "flex" }}>
        <div
          className="btn btn--tiny btn--outline btn--white"
          style={{ marginRight: 10, flex: 0.8 }}
          onClick={() => handleDropDownClose()}
        >
          Cancel
        </div>
        <div
          className={
            enableSubmit() ? "btn btn--tiny" : "btn btn--tiny btn--disabled"
          }
          style={{ flex: 0.2 }}
          onClick={() => setDisplayConfirmation(true)}
        >
          Submit
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div style={{ position: "relative" }}>
        <div
          className={open ? "btn btn--sqr btn--sqr--active" : "btn btn--sqr"}
          onClick={() => onOpenChange(pharmacy_id)}
        >
          <MoreHoriz fontSize="small" />
        </div>
        {open && renderDropdownMenu}
      </div>
      <ConfirmationModal
        title={"Please confirm action"}
        message={"Are you sure you want to continue?"}
        displayModal={displayConfirmation}
        setModalState={setDisplayConfirmation}
        action={handleSubmit}
      />
    </>
  );
}
