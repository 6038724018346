import React from "react";
import MultiChipSelector from "../../MultiChipSelector";

type MultiProductFilterProps = {
  products: Array<{ value: string; label: string }>;
  selectedProductNames: Array<string>;
  setSelectedProductNames: (arg1: Array<string>) => void;
};

const MultiProductFilter = ({
  products,
  selectedProductNames,
  setSelectedProductNames,
}: MultiProductFilterProps) => {
  return (
    <>
      <div className="draw__item__title draw__item__title--mb-sm t--upcase t--sm">
        Products
      </div>
      <div className="chip__well">
        <MultiChipSelector
          options={products}
          selected={selectedProductNames}
          handleSelection={setSelectedProductNames}
        />
      </div>
    </>
  );
};

export default MultiProductFilter;
