import React, { useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MosaicLogo from "~/images/mosaic_logo.png";
import Illustration from "~/images/illustration.png";

const CustomTextField = withStyles({
  root: {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    "& label.Mui-focused": {
      color: "#3D71FF",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
      "& fieldset": {
        borderColor: "#DEE7EE",
      },
      "&:hover fieldset": {
        borderColor: "#DEE7EE",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#3D71FF",
      },
    },
  },
})(TextField);

const SignUp = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState(props.email);

  const renderForm = () => {
    return (
      <div className="auth__form">
        <img src={props.mfr_logo} style={{ width: 100, marginBottom: 50 }} />

        <h1>Welcome</h1>
        <p>Activate your account below</p>

        <form
          method="POST"
          action={`/organization/${props.org}/setpassword?token=${props.token}`}
          className="authentication__form"
        >
          <div className="">
            <input type="hidden" id="token" name="session[token]" value={props.token} />
            <CustomTextField
              label="Email Address"
              margin="normal"
              variant="outlined"
              id="email"
              name="session[email]"
              fullWidth
              required
              defaultValue={email}
              value={email}
            />

            <CustomTextField
              label="Password"
              margin="normal"
              variant="outlined"
              id="password"
              fullWidth
              required
              autoFocus
              name="session[password]"
              type="password"
            />
            <CustomTextField
              label="Confirm Password"
              margin="normal"
              ß
              variant="outlined"
              id="confirm_password"
              fullWidth
              required
              name="session[confirm_password]"
              type="password"
            />
          </div>
          <div className="auth__form__footer">
            <button className="btn" style={{ flexGrow: 1, alignSelf: "inherit" }} type="submit">
              LOGIN
            </button>

            <div className="authentication__info" style={{ marginTop: 20 }}>
              <span>Need help? </span>
              <a className="authentication__link" href="/">
                Contact Us
              </a>
            </div>
          </div>
        </form>
      </div>
    );
  };

  const renderBillboard = () => {
    return (
      <div className="">
        <div className="auth__container--billboard__title">
          <div className="auth__container--billboard__logo">
            <img src={MosaicLogo} />
          </div>

          <img src={Illustration} style={{ maxWidth: 450 }} />

          <div style={{ marginTop: 30 }}>
            <span className="t--xl t--500">340B ESP™</span>
            <br />
            <span className="t--lg">Linking Healthcare</span>
            <p className="t--md color--subtitle" style={{ marginTop: 20, maxWidth: 380 }}>
              340B ESP™ allows 340B covered entities and pharmaceutical manufacturers to work collaboratively to resolve
              duplicate discounts.
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="auth__wrapper">
      <div className="auth__container">
        <div className="auth__container--billboard">{renderBillboard()}</div>
        <div className="auth__container--form">{renderForm()}</div>
      </div>
    </div>
  );
};

export default SignUp;
