import React from "react";
import { numberWithCommas } from "../../utils/NumberFormatter";
import { CeCpProductDetail } from "../../types/models";

const SubTable = ({ details }: { details: Array<CeCpProductDetail> }) => {
  const renderRows = details.map((row, i) => {
    return (
      <tr className="sm t--sm" key={i}>
        <td>
          <div className="t--sm t--500">{row.product_name.toUpperCase()}</div>
        </td>
        <td className="t--sm t--500 align-right">{row.year}</td>
        <td className="t--sm t--500 align-right">{row.month}</td>
        <td className="t--sm t--500 align-right">
          ${numberWithCommas(row.sales)}
        </td>
        <td className="t--sm t--500 align-right">
          {numberWithCommas(row.units)}
        </td>
      </tr>
    );
  });

  return (
    <div>
      <table className="primary">
        <thead>
          <tr className="sm">
            <th>Product</th>
            <th className="align-right">Year</th>
            <th className="align-right">Month</th>
            <th className="align-right">Purchases</th>
            <th className="align-right">Units</th>
          </tr>
        </thead>
        <tbody>{renderRows}</tbody>
      </table>
    </div>
  );
};

export default SubTable;
