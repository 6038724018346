import React, { useEffect, useState } from "react";
import { search, filterByStatus } from "../Pharmacies/utils";
import PharmacySearch from "../Pharmacies/PharmacySearch";
import TableRowsLoadingSkeleton from "../TableRowsLoadingSkeleton";
import DropDownMenu from "./pharmacies/DropDownMenu";
import Notice from "../Notice";

import _ from "lodash";
import httpClient from "../utils/httpClient";
import { MfrException, Suspension } from "../types/models";

type Pharmacy = {
  status: string;
  pharmacy_id: string;
  address: string;
  name: string;
  dea: string;
  hin: string;
  chain_name: string;
  is_wholly_owned: boolean;
};

type ContractPharmaciesTableProps = {
  isLoading: boolean;
  id_340b: string;
  exceptions_path: string;
  suspensions_path: string;
  networks: Array<string>;
  designations: Array<any>;
  pharmacies: Array<Pharmacy>;
  chainEnforcement: boolean;
};

const ContractPharmaciesTable = ({
  isLoading,
  id_340b,
  exceptions_path,
  suspensions_path,
  pharmacies,
  networks,
  designations,
  chainEnforcement,
}: ContractPharmaciesTableProps) => {
  const [openDropDownMenu, setOpenDropDownMenu] = useState(null);
  const [exceptions, setExceptions] = useState<Array<MfrException>>([]);
  const [suspensions, setSuspensions] = useState<Array<Suspension>>([]);
  const [searchTerms, setSearchTerms] = useState({
    searchQuery: "",
    filterQuery: [],
  });
  const [submitting, setSubmitting] = useState(false);
  const [notice, setNotice] = useState({
    kind: "error",
    open: false,
    message: "",
  });

  useEffect(() => {
    httpClient
      .get(`${exceptions_path}?id_340b=${id_340b}`)
      .then((res) => setExceptions(res.data))
      .catch((error) => errorNotice(error.message));

    httpClient
      .get(`${suspensions_path}?id_340b=${id_340b}`)
      .then((res) => setSuspensions(res.data))
      .catch((error) => errorNotice(error.message));
  }, []);

  const successNotice = (message: string) => {
    setNotice({
      kind: "success",
      open: true,
      message,
    });
  };

  const errorNotice = (message = null) => {
    setNotice({
      kind: "error",
      open: true,
      message:
        message || "An error occurred - please try again or contact support.",
    });
  };

  const submitExceptions = (data: Array<any>) => {
    setSubmitting(true);

    httpClient
      .post(`${exceptions_path}/bulk_create`, {
        id_340b,
        exceptions: data,
      })
      .then((response) => {
        setExceptions([...exceptions, ...response.data]);
        successNotice("Success - you have successfully created exceptions");
      })
      .catch(() => errorNotice())
      .finally(() => setSubmitting(false));
  };

  const submitSuspensions = (data: Array<any>) => {
    setSubmitting(true);

    httpClient
      .post(`${suspensions_path}/bulk_create`, {
        id_340b,
        suspensions: data,
      })
      .then((response) => {
        setSuspensions([...suspensions, ...response.data]);
        successNotice("Success - you have successfully created exceptions");
      })
      .catch(() => errorNotice())
      .finally(() => setSubmitting(false));
  };

  const deleteException = (id: number | string) => {
    setSubmitting(true);

    httpClient
      .delete(`${exceptions_path}/${id}`)
      .then(() => {
        setExceptions(exceptions.filter((e) => e.id !== id));
        successNotice("Success - you have successfully deleted the exception");
      })
      .catch(() => errorNotice())
      .finally(() => setSubmitting(false));
  };

  const deleteSuspension = (id: number | string) => {
    setSubmitting(true);

    httpClient
      .delete(`${suspensions_path}/${id}`)
      .then(() => {
        setSuspensions(suspensions.filter((s) => s.id !== id));
        successNotice("Success - you have successfully deleted the suspension");
      })
      .catch(() => errorNotice())
      .finally(() => setSubmitting(false));
  };

  const initialPharmacies =
    isLoading || Object.keys(pharmacies).length === 0 ? [] : pharmacies;

  const filteredPharmacies =
    searchTerms.filterQuery.length > 0
      ? filterByStatus(initialPharmacies, searchTerms)
      : initialPharmacies;

  const searchedPharmacies = searchTerms.searchQuery.length
    ? search(filteredPharmacies, searchTerms)
    : filteredPharmacies;

  const renderDesignationBadge = (row: Pharmacy) => {
    const items = _.map(networks, (network) => {
      if (_.find(designations, { pid: row.pharmacy_id, network })) {
        return (
          <div key={row.pharmacy_id} className="badge">
            <div className={`badge__indicator badge__indicator--blue`} />
            Designated
          </div>
        );
      }
    });

    return _.compact(items)[0];
  };

  const renderExceptionBadges = (row: Pharmacy) => {
    const filteredExceptions = exceptions.filter(
      (s) =>
        s.pid == row.pharmacy_id ||
        (!s.pid && s.chain_name?.toLowerCase() == row.chain_name?.toLowerCase())
    );
    return filteredExceptions.map((exception) => {
      return (
        <div key={exception.id} className="badge" style={{ marginRight: 5 }}>
          <div className={`badge__indicator badge__indicator--green`} />
          {exception.network} {exception.chain && "Chain"}
        </div>
      );
    });
  };

  const renderWhollyOwnedBadges = (row: Pharmacy) =>
    row.is_wholly_owned && <span className="badge badge--tiny">WO</span>;

  const renderSuspensionsBadges = (row: Pharmacy) => {
    const filteredSuspensions = suspensions.filter(
      (s) =>
        s.pid == row.pharmacy_id ||
        (!s.pid && s.chain_name?.toLowerCase() == row.chain_name?.toLowerCase())
    );
    return filteredSuspensions.map((suspension) => {
      return (
        <div key={suspension.id} className="badge" style={{ marginRight: 5 }}>
          <div className={`badge__indicator badge__indicator--red`} />
          {suspension.network} {suspension.chain && "Chain"}
        </div>
      );
    });
  };

  const pharmacyExceptions = (pharmacy) => {
    if (pharmacy.chain_name) {
      return _.filter(exceptions, (exception) => {
        if (!exception.chain_name) return false;
        return (
          pharmacy.chain_name.toUpperCase() ==
          exception.chain_name.toUpperCase()
        );
      });
    }

    return _.filter(exceptions, { pid: pharmacy.pharmacy_id });
  };

  const renderTableRows = () => {
    return searchedPharmacies.map((row) => {
      return (
        <tr className="sm" key={row.pharmacy_id}>
          <td className="t--md">
            <div className="table__text__stack">
              <div className="t--sm t--500">
                {row.name} {renderWhollyOwnedBadges(row)}
              </div>
              <div className="t--sm t--subtitle">{row.address}</div>
            </div>
          </td>
          <td className="t--md">{renderDesignationBadge(row)}</td>
          <td className="t--md">{renderExceptionBadges(row)}</td>
          <td className="t--md">{row.dea ? row.dea : "--"}</td>
          <td className="t--md">{row.hin}</td>
          <td className="t--md">{renderSuspensionsBadges(row)}</td>
          <td className="t--md">
            <DropDownMenu
              key={row.pharmacy_id}
              networks={networks}
              submitting={submitting}
              pharmacy_id={row.pharmacy_id}
              exceptions={pharmacyExceptions(row)}
              suspensions={_.filter(suspensions, { pid: row.pharmacy_id })}
              submitExceptions={submitExceptions}
              submitSuspensions={submitSuspensions}
              deleteException={deleteException}
              deleteSuspension={deleteSuspension}
              onOpenChange={(id) => setOpenDropDownMenu(id)}
              open={openDropDownMenu === row.pharmacy_id}
              chainName={row.chain_name}
              chainEnforcement={chainEnforcement}
            />
          </td>
        </tr>
      );
    });
  };

  const renderTable = (
    <table className="primary">
      <thead>
        <tr className="sm">
          <th>Pharmacy</th>
          <th>Designations</th>
          <th>Exceptions</th>
          <th>DEA</th>
          <th>HIN</th>
          <th>Suspensions</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {isLoading ? (
          <TableRowsLoadingSkeleton columns={8} />
        ) : (
          renderTableRows()
        )}
      </tbody>
    </table>
  );

  return (
    <div>
      <div className="table__actionbar table__actionbar--spacebetween">
        <PharmacySearch
          searchTerms={searchTerms}
          setSearchTerms={setSearchTerms}
        />
      </div>
      {renderTable}
      <Notice details={notice} />
    </div>
  );
};

export default ContractPharmaciesTable;
