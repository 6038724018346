import React from "react";
import _ from "lodash";

const OverdrawnAccountsSearch = (props) => {
  const handleSearch = (query) => {
    props.setSearchTerms({ searchQuery: query });
  };

  const debouncedSearch = _.debounce(handleSearch, 350);

  return (
    <div className="searchbar__wrapper">
      <div className="searchbar__container">
        <div className="searchbar__icon">
          <i className="solid solid-budicon-search-a" />
        </div>
        <input
          className="searchbar__input"
          type="text"
          placeholder="Search"
          onChange={(e) => debouncedSearch(e.target.value)}
        />
      </div>
    </div>
  );
};

export default OverdrawnAccountsSearch;
