import React, { ReactNode } from "react";

type ReportLayoutProps = {
  title: ReactNode;
  body: ReactNode;
  aside: ReactNode;
};

export default function ReportLayout({
  title = "Placeholder Title",
  body,
  aside,
}: ReportLayoutProps) {
  return (
    <div className="flex__container">
      <main className="content__container">
        <header className="page-details__container">
          <div className="page-details__container__info">
            <h2 className="page-details__title">{title}</h2>
            <span className="breadcrumbs__crumb">Report</span>
          </div>
        </header>
        <section>{body}</section>
      </main>
      <aside>{aside}</aside>
    </div>
  );
}
