import React, { useState } from "react";
import Notice from "../../Notice";
import httpClient from "../../utils/httpClient";
import { MeatballMenu } from "../Common/MeatballMenu";
import { defaultDate } from "../../utils/dateFormatter";

import { MfrException } from "../../types/models";

type ExceptionsTableProps = {
  exceptions: Array<MfrException>;
  exceptions_path: string;
  handleExceptionDelete: (arg1: number | string) => void;
};

const ExceptionsTable = ({
  exceptions,
  exceptions_path,
  handleExceptionDelete,
}: ExceptionsTableProps) => {
  const [notice, setNotice] = useState({});

  const deleteException = (id: number | string) => {
    httpClient
      .delete(`${exceptions_path}/${id}`)
      .then(() => {
        handleExceptionDelete(id);
        setNotice({
          kind: "success",
          open: true,
          message: "Success - you have successfully deleted the exception.",
        });
      })
      .catch(() => {
        setNotice({
          kind: "error",
          open: true,
          message: "An error occurred - please try again or contact support.",
        });
      });
  };

  const renderBadgeIndicator = (value: string) => {
    switch (value) {
      case "retail":
        return "light-blue";
      case "specialty":
        return "orange";
      case "pah":
        return "yellow";
      default:
        return "grey";
    }
  };

  const renderRows = exceptions.map((exception, i: number) => {
    return (
      <tr className="sm" key={i}>
        <td className="t--sm t--500">
          <div className="table__text__stack">
            <div className="t--sm t--500">
              {exception.id_340b.toUpperCase()}
            </div>
            <div className="t--sm t--subtitle">
              {exception.entity_name?.toUpperCase()?.substring(0, 35)}
            </div>
          </div>
        </td>
        <td className="t--sm t--500">
          <div className="table__text__stack">
            <div className="t--sm t--500">
              {exception.pharmacy_name?.toUpperCase()}
            </div>
            <div className="t--sm t--subtitle">
              {exception.pharmacy_address?.toUpperCase()?.substring(0, 35)}
            </div>
          </div>
        </td>
        <td className="t--sm t--500">{exception.pid}</td>
        <td className="t--sm t--500">
          <div className="badge">
            <div
              className={`badge__indicator badge__indicator--${renderBadgeIndicator(
                exception.network
              )}`}
            />
            {exception.network.toUpperCase()} {exception.chain ? "CHAIN" : ""}
          </div>
        </td>
        <td className="t--sm t--500">{exception.created_by}</td>
        <td className="t--sm t--500">{defaultDate(exception.created_at)}</td>
        <td className="t--sm t--500">
          <MeatballMenu
            menuData={exception}
            onModalConfirm={() => deleteException(exception.id)}
          />
        </td>
      </tr>
    );
  });

  const renderTable = (
    <table className="primary">
      <thead>
        <tr className="sm">
          <th className="t--md">ENTITY</th>
          <th className="t--md">PHARMACY</th>
          <th className="t--md">PID</th>
          <th className="t--md">NETWORK</th>
          <th className="t--md">CREATED BY</th>
          <th className="t--md">CREATED AT</th>
          <th className="t--md">ACTIONS</th>
        </tr>
      </thead>
      <tbody>{renderRows}</tbody>
    </table>
  );

  return (
    <div>
      {renderTable}
      <Notice details={notice} />
    </div>
  );
};

export default ExceptionsTable;
