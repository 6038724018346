import React from "react";

const RadialBarChartThickerPath =
  (threshold) =>
  ({ center, bars, outerRadius, innerRadius, arcGenerator }) => {
    if (!bars.length) {
      return null;
    }
    const { startAngle, endAngle } = bars[0].arc;
    const pathData = arcGenerator({
      startAngle,
      endAngle,
      innerRadius,
      outerRadius,
    });

    return (
      <g transform={`translate(${center[0]},${center[1]})`}>
        <path d={pathData} fill={bars[0].color} />
      </g>
    );
  };

export default RadialBarChartThickerPath;
