import React from "react";
import TableRowsLoadingSkeleton from "../../TableRowsLoadingSkeleton";

type OviEvent = {
  kind: string;
  date: string;
  type: string;
  archived: boolean;
};

const EventsTable = ({ events, loading }: { events: Array<OviEvent>; loading?: boolean }) => {
  const renderArchivedStatus = ({ type, archived }) => {
    return (
      type === "ovi_mailer" &&
      archived && (
        <div className="badge">
          <div className={`badge__indicator badge__indicator--gray`} />
          Archived
        </div>
      )
    );
  };

  const renderRows = events.map((event, i) => {
    return (
      <tr key={i} className="sm t--sm">
        <td>{event.kind}</td>
        <td>{renderArchivedStatus(event)}</td>
        <td>{event.date}</td>
      </tr>
    );
  });

  return (
    <table className="secondary secondary-border">
      <thead>
        <tr className="sm">
          <th>EVENT</th>
          <th style={{ width: "100px" }}></th>
          <th style={{ width: "150px" }}>EVENT DATE</th>
        </tr>
      </thead>
      <tbody>{loading ? <TableRowsLoadingSkeleton rows={5} columns={3} /> : renderRows}</tbody>
    </table>
  );
};

export default EventsTable;
