import React from "react";
import moment from "moment";
import TileLoadingSkeleton from "./TileLoadingSkeleton";
import StatCard from "../Base/StatCard";

const TODAY = moment().format("M/D/YYYY");

const LoadingWrapper = ({ isLoading, children }) => {
  return isLoading ? <TileLoadingSkeleton /> : children;
};

const StatCards = ({ statCards, entityDetails }) => {
  // TODO: looks like this is not being used just the isLoading
  const { isLoading, data, error } = statCards;
  const {
    isLoading: { isEntityInfoLoading },
    data: { submitted_claims, eligibile_cps },
  } = entityDetails;

  // TODO: gridColumnStyle and tilegridColumnStyle can be deleted when In-House WAC and CPs WAC are implemented
  const gridColumnStyle = { gridColumn: "4 / span 1" };
  const tileGridColumnStyle = { gridColumn: "1 / span 2" };

  return (
    <div className="graph__grid-container" style={{ width: "100%", ...gridColumnStyle }}>
      <div style={tileGridColumnStyle}>
        <LoadingWrapper isLoading={isLoading || isEntityInfoLoading}>
          <StatCard
            title="Submitted Claims"
            updated={TODAY}
            stat={submitted_claims?.current}
            legend="Conforming"
            badge={{
              trend: submitted_claims?.change.trending,
              value: submitted_claims?.change.friendly,
              info: "vs last month",
            }}
          />
        </LoadingWrapper>
      </div>
      <div style={tileGridColumnStyle}>
        <LoadingWrapper isLoading={isLoading || isEntityInfoLoading}>
          <StatCard
            title="CPs with Pricing"
            updated={TODAY}
            stat={eligibile_cps?.current}
            legend="Eligible"
            badge={{
              trend: eligibile_cps?.change.trending,
              value: eligibile_cps?.change.friendly,
              info: "vs last month",
            }}
          />
        </LoadingWrapper>
      </div>
    </div>
  );
};

export default StatCards;
