import React, { useState, ReactNode, MouseEvent } from "react";
import { Popover } from "@mui/material";

type TriggerProps = {
  className?: string;
  onClick: (event: MouseEvent<HTMLElement>) => void;
  children: ReactNode;
};

type ContentProps = {
  anchorEl: null | HTMLElement;
  onClose: () => void;
  className?: string;
  children: ReactNode;
};

const usePopoverMenu = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleMenuOpen = (event: MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return {
    anchorEl,
    handleMenuOpen,
    handleMenuClose,
  };
};

function PopoverMenu({ children }: { children: ReactNode }) {
  return <>{children}</>;
}

function Trigger({ className = "", onClick, children }: TriggerProps) {
  return (
    <button className={className} onClick={onClick}>
      {children}
    </button>
  );
}

function Content({
  anchorEl,
  onClose,
  className = "",
  children,
}: ContentProps) {
  return (
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      elevation={0}
    >
      <div className={className}>{children}</div>
    </Popover>
  );
}

PopoverMenu.Trigger = Trigger;
PopoverMenu.Content = Content;

export { usePopoverMenu, PopoverMenu };
