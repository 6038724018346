import React, { Fragment, useState, useMemo } from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getExpandedRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";

import { search } from "../../utils/search";
import ReportSearch from "../Common/ReportSearch";
import PaginationControls from "../Common/PaginationControls";

import { User } from "../../types/models";

type UsersTableProps = {
  users: User[];
};

const UsersTable = ({ users }: UsersTableProps) => {
  const [searchTerms, setSearchTerms] = useState("");

  const filterUsers = () => {
    return search(users, searchTerms, ["first_name", "last_name", "email"]);
  };

  const filteredUsers = useMemo(() => filterUsers(), [users, searchTerms]);

  const columnHelper = createColumnHelper<User>();

  const columns = [
    columnHelper.accessor("first_name", {
      header: "FIRST NAME",
      cell: ({ row, getValue }) => {
        return (
          <div className="table__text__stack">
            <div className="t--sm t--500">{getValue()}</div>
          </div>
        );
      },
    }),
    columnHelper.accessor("last_name", {
      header: "LAST NAME",
      cell: ({ row, getValue }) => {
        return (
          <div className="table__text__stack">
            <div className="t--sm t--500">{getValue()}</div>
          </div>
        );
      },
    }),
    columnHelper.accessor("email", {
      header: "EMAIL",
      cell: ({ row, getValue }) => {
        return (
          <div className="table__text__stack">
            <div className="t--sm t--500">{getValue()}</div>
          </div>
        );
      },
    }),
  ];

  const table = useReactTable<User>({
    data: filteredUsers,
    columns,
    getExpandedRowModel: getExpandedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: { pagination: { pageSize: 50 } },
  });

  const renderRows = table.getRowModel().rows.map((row) => {
    return (
      <Fragment key={row.id}>
        <tr className="sm">
          {row.getVisibleCells().map((cell) => (
            <td key={cell.id} className="t--md">
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          ))}
        </tr>
      </Fragment>
    );
  });

  const renderTable = (
    <table className="primary">
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id} className="sm">
            {headerGroup.headers.map((header) => (
              <th key={header.id}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>{renderRows}</tbody>
    </table>
  );

  return (
    <div>
      <div className="table__actionbar table__actionbar--spacebetween">
        <ReportSearch
          setSearchTerms={setSearchTerms}
          tooltip={{ title: "Search by first name, last name, or email" }}
        />
      </div>
      {renderTable}
      <PaginationControls table={table} recordCount={users.length} />
    </div>
  );
};

export default UsersTable;
