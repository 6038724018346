import React from "react";
import { ResponsivePie } from "@nivo/pie";

const CenteredMetric = ({ centerX, centerY, dataWithArc }) => {
  let total = 0;
  dataWithArc.forEach((datum) => {
    total += datum.value;
  });
  return (
    <text
      x={centerX}
      y={centerY}
      textAnchor="middle"
      dominantBaseline="central"
      style={{ fontSize: "12px", fontWeight: 600 }}
    >
      {total + "%"}
    </text>
  );
};

const PieChart = ({ data, chartHeight, chartWidth }) => {
  return (
    <div style={{ height: chartHeight, width: chartWidth }}>
      <ResponsivePie
        data={data}
        margin={{ top: 8, right: 8, bottom: 8, left: 8 }}
        innerRadius={0.8}
        padAngle={0.7}
        startAngle={96}
        cornerRadius={4}
        activeOuterRadiusOffset={4}
        borderWidth={1}
        borderColor={{
          from: "color",
          modifiers: [["darker", 0.2]],
        }}
        arcLabel={(d) => `${d.value}%`}
        arcLabelsSkipAngle={1}
        arcLabelsTextColor={{
          from: "color",
          modifiers: [["darker", 2]],
        }}
        enableArcLabels={false}
        enableArcLinkLabels={false}
        defs={[
          {
            id: "dots",
            type: "patternDots",
            background: "inherit",
            color: "rgba(255, 255, 255, 0.3)",
            size: 4,
            padding: 1,
            stagger: true,
          },
          {
            id: "lines",
            type: "patternLines",
            background: "inherit",
            color: "rgba(255, 255, 255, 0.3)",
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
        ]}
        fill={[]}
        layers={["arcs", CenteredMetric]}
      />
    </div>
  );
};

export default PieChart;
