import React, { ReactNode } from "react";

type InvisibleOverlayProps = {
  active: boolean;
  onClick: () => void;
  children: ReactNode;
};

export default function InvisibleOverlay({
  active,
  onClick,
  children,
}: InvisibleOverlayProps) {
  if (!active) return children;

  return (
    <div
      onClick={onClick}
      className="overlay overlay__invisible"
      style={{ position: "absolute" }}
    >
      <div style={{ pointerEvents: "none" }}>{children}</div>
    </div>
  );
}
