type DateInput = string | undefined | Date;
type DateFormatterProps = {
  date: DateInput;
  locale?: "en-US";
  options?: Intl.DateTimeFormatOptions;
};

const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function dateFormatter({ date, locale, options }: DateFormatterProps) {
  if (!date) return "---";
  return new Date(date).toLocaleDateString(locale, options);
}

export function defaultDate(date: DateInput) {
  return dateFormatter({ date });
}

export function monthOnly(
  date: DateInput,
  length: "long" | "short" | "numeric" | "2-digit" = "long"
) {
  return dateFormatter({ date, options: { month: length } });
}

export function monthAndYear(date: Date) {
  return `${MONTHS[date.getUTCMonth()]} ${date.getUTCFullYear()}`;
}

export function timezoneAgnosticDate(dateInput) {
  const date = new Date(dateInput);
  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
}
