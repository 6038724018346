import React from "react";

const Flag = ({ height = 14, width = 14 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      height={height}
      width={width}
      fill="none"
    >
      <circle cx="7" cy="7.805" r="7" fill="#fff" />
      <path
        d="M4.725 12.005c.152 0 .277-.05.377-.149a.512.512 0 0 0 .148-.376V8.505H7l.508 1.015a.606.606 0 0 0 .262.289c.117.064.24.096.367.096H10.5a.676.676 0 0 0 .498-.202.676.676 0 0 0 .202-.498v-2.8a.678.678 0 0 0-.202-.5.677.677 0 0 0-.498-.2H9.1L8.592 4.69a.606.606 0 0 0-.262-.29.754.754 0 0 0-.367-.095H4.9a.677.677 0 0 0-.498.2.678.678 0 0 0-.202.5v6.475a.51.51 0 0 0 .525.525zM7 14.805a6.813 6.813 0 0 1-2.73-.552 7.065 7.065 0 0 1-2.222-1.496 7.064 7.064 0 0 1-1.496-2.222A6.813 6.813 0 0 1 0 7.805c0-.969.184-1.879.552-2.73a7.064 7.064 0 0 1 1.495-2.223A7.074 7.074 0 0 1 4.27 1.356 6.82 6.82 0 0 1 7 .805a6.82 6.82 0 0 1 2.73.55 7.073 7.073 0 0 1 2.223 1.497c.63.63 1.128 1.371 1.495 2.223.368.851.552 1.761.552 2.73 0 .968-.184 1.878-.552 2.73a7.064 7.064 0 0 1-1.495 2.222 7.064 7.064 0 0 1-2.223 1.496 6.813 6.813 0 0 1-2.73.552z"
        fill="#3246D3"
      />
    </svg>
  );
};

export default Flag;
