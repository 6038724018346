import React from "react";
import { ResponsiveLine } from "@nivo/line";
import _ from "lodash";

const ComplianceChart = (props) => {
  return (
    <ResponsiveLine
      data={props.data && props.data.length ? props.data : []}
      margin={{ top: 12, right: 30, bottom: 20, left: 50 }}
      yScale={{
        type: "linear",
        min: -1,
        max: 1,
        stacked: false,
      }}
      curve="stepAfter"
      areaBaselineValue={0}
      crosshairType="x"
      axisLeft={{
        orient: "left",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        tickValues: 5,
      }}
      axisBottom={{
        tickSize: 0,
        tickPadding: 9,
        tickRotation: 0,
      }}
      enableGridX={false}
      enableGridY={true}
      gridYValues={5}
      colors={({ id, color }) => color}
      theme={{
        textColor: "#718291",
      }}
      enablePoints={false}
      pointSize={8}
      pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      pointBorderColor={{ from: "seriesColor" }}
      pointLabel="y"
      pointLabelYOffset={-12}
      useMesh={true}
      legends={[]}
      enableArea={true}
      enableSlices={false}
      tooltip={(points) => {
        return (
          <div
            style={{
              padding: 2,
              color: "#ffffff",
              background: "#222222",
            }}
          >
            <strong>
              {points.point.data.x}: {points.point.data.y}
            </strong>
          </div>
        );
      }}
    />
  );
};

export default ComplianceChart;
