import React from "react";
import { ResponsiveLine } from "@nivo/line";

const MyResponsiveLine = (props) => {
  const containerStyles = {
    height: props.height || 258,
    width: props.width || "auto",
    backgroundColor: props.bgColor || "#ffffff",
    borderRadius: props.bRadius || 8,
  };

  return (
    <div style={containerStyles}>
      <ResponsiveLine
        data={props.data}
        margin={{ top: 0, right: 12, bottom: 20, left: 12 }}
        xScale={{ type: "point" }}
        yScale={{
          type: "linear",
          min: "auto",
          max: "auto",
          stacked: false,
          reverse: false,
        }}
        curve="basis"
        axisBottom={{
          tickSize: 0,
          tickPadding: 8,
          tickRotation: 0,
        }}
        axisLeft={null}
        enableGridX={false}
        enableGridY={false}
        colors={["#3246d3"]}
        theme={{
          textColor: "#718291",
        }}
        enablePoints={false}
        pointSize={8}
        pointColor={{ theme: "background" }}
        pointBorderWidth={2}
        pointBorderColor={{ from: "seriesColor" }}
        pointLabel="y"
        pointLabelYOffset={-12}
        useMesh={true}
        legends={[]}
        enableArea={true}
        defs={[
          {
            id: "gradientC",
            type: "linearGradient",
            colors: [
              { offset: 0, color: "#3246D3" },
              { offset: 100, color: "rgba(226, 234, 255, 0.0)" },
            ],
          },
        ]}
        fill={[{ match: "*", id: "gradientC" }]}
      />
    </div>
  );
};

export default MyResponsiveLine;
