import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Box } from "@material-ui/core";

const PharmacySearch = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const filterableOptions = [
    { id: 1, value: "Eligible", label: "Eligible" },
    { id: 2, value: "Ineligible", label: "Ineligible" },
    { id: 3, value: "In Process", label: "In Process" },
    { id: 4, value: "At Risk", label: "At Risk" }
  ];

  const handleSearch = (query) => {
    props.setSearchTerms({ searchQuery: query, filterQuery: props.searchTerms.filterQuery });
  };

  const debouncedSearch = _.debounce(handleSearch, 350);

  const handleFilter = (query) => {
    var filterQuery = _.cloneDeep(props.searchTerms.filterQuery);

    if (filterQuery.includes(query)) {
      filterQuery.splice(filterQuery.indexOf(query), 1);
    } else {
      filterQuery.push(query);
    }

    props.setSearchTerms({
      searchQuery: props.searchTerms.searchQuery,
      filterQuery: filterQuery,
    });
  };

  const generateFilterLabel = () => {
    return _.map(props.searchTerms.filterQuery, (option) => {
      switch (option) {
        case "ELIGIBLE":
          return "Eligible";
        case "INELIGIBLE":
          return "Ineligible";
        case "IN_PROCESS":
          return "In Process";
        case "AT_RISK":
          return "At Risk";
      }
    }).join(" and ");
  };

  const renderDropdownLabel = () => {
    if (props.searchTerms.filterQuery.length === 0) {
      return "Status";
    }

    return props.searchTerms.filterQuery.join(' and ')
  };

  const renderSelectedOption = (option, i) => {
    return (
      <div
        key={i}
        className="filterbox__menu__item filterbox__menu__item--selected"
        onClick={() => handleFilter(option.value)}
      >
        <div>{option.label}</div>
        <div className="filterbox__menu__item__selected__icon">
          <div className="solid solid-budicon-check-ui" />
        </div>
      </div>
    );
  };

  const renderOption = (option, i) => {
    return (
      <div key={i} className="filterbox__menu__item" onClick={() => handleFilter(option.value)}>
        <div>{option.label}</div>
      </div>
    );
  };

  const renderFilterableOptions = () => {
    return filterableOptions.map((option, i) => {
      if (props.searchTerms.filterQuery.includes(option.value)) {
        return renderSelectedOption(option, i);
      } else {
        return renderOption(option, i);
      }
    });
  };

  const renderFilterMenu = () => {
    if (!menuOpen) return;

    return <div className="filterbox__menu">{renderFilterableOptions()}</div>;
  };

  const renderFilter = () => {
    if (!props.filterable) return;

    return (
      <div className="filterbox__container filterbox--ml" onClick={() => setMenuOpen(!menuOpen)}>
        <div className="filterbox--bold-text"> {renderDropdownLabel()} </div>
        {renderFilterMenu()}
        <div className="filterbox__icon">
          <i className="solid solid-budicon-chevron-bottom" />
        </div>
      </div>
    );
  };

  return (
    <div className="searchbar__wrapper">
      <div className="searchbar__container">
        <div className="searchbar__icon">
          <i className="solid solid-budicon-search-a" />
        </div>
        <input
          className="searchbar__input"
          type="text"
          placeholder="Search"
          onChange={(e) => debouncedSearch(e.target.value)}
        />
      </div>
      {renderFilter()}
    </div>
  );
};

export default PharmacySearch;
