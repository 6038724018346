import React from "react";
import ChipSelector from "../../ChipSelector";
import { NetworkOption } from "../../types/models";

type NetworkFilterProps = {
  networks: Array<NetworkOption>;
  selectedNetwork: string | null;
  setSelectedNetwork: (arg1: string) => void;
  required?: boolean;
};

const NetworkFilter = ({
  networks,
  selectedNetwork,
  setSelectedNetwork,
  required = true,
}: NetworkFilterProps) => {
  return (
    <>
      <div className="draw__item__title draw__item__title--mb-sm t--upcase t--sm">
        Networks
      </div>
      <div className="chip__well">
        <ChipSelector
          required={required}
          items={networks}
          selected={selectedNetwork}
          setSelected={setSelectedNetwork}
        />
      </div>
    </>
  );
};

export default NetworkFilter;
