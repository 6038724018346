import React, { useState, useEffect } from "react";
import _ from "lodash";
import Button from "../Base/Button";
import { Pie } from "@nivo/pie";
import CreditsDebitsChart from "./Charts/CreditsDebitsChart";
import httpClient from "../utils/httpClient";

import { Close } from "@material-ui/icons";
import {
  numberWithCommas,
  wholeNumberWithCommas,
  currencyWithCommas,
} from "../utils/NumberFormatter";

import { AccountProductRow } from "../types/models";

type SubmissionsData = {
  updated_at: string;
  total_non_conforming: number;
  total_conforming: number;
  data: any[];
};

type LedgerData = {
  transactions: {
    credits: any;
    debits: any;
  };
  updated_at: string;
};

type BalanceDataSeries = {
  data: Array<{ x: string; y: number }>;
};

type BalanceData = {
  updated_at: string;
  data: BalanceDataSeries[];
};

type AccountsModalProps = {
  searchQuery: {
    grouping: string;
    pharmacy_type: string;
    date_range: {
      start: string;
      end: string;
    };
  };
  selectedAccount: AccountProductRow;
  inhouseLedgerPath: string;
  submissionDetailsPath: string;
  inhouseBalanceHistoryPath: string;
  balanceHistoryPath: string;
  ledgerPath: string;
  displayModal: boolean;
  setDisplayModal: (arg1: boolean) => void;
};

const AccountsModal = ({
  searchQuery,
  selectedAccount,
  inhouseLedgerPath,
  submissionDetailsPath,
  inhouseBalanceHistoryPath,
  balanceHistoryPath,
  ledgerPath,
  displayModal,
  setDisplayModal,
}: AccountsModalProps) => {
  const [balanceHistory, setBalanceHistory] = useState<BalanceData | null>();
  const [submissions, setSubmissions] = useState<SubmissionsData | null>();
  const [ledger, setLedger] = useState<LedgerData | null>();

  useEffect(() => {
    var query = {
      id_340b: selectedAccount.id_340b,
      product_names: [selectedAccount.product_name],
      grouping: searchQuery.grouping,
      pharmacy_type: searchQuery.pharmacy_type,
      date_range: {
        start: searchQuery.date_range.start,
        end: searchQuery.date_range.end,
      },
    };

    if (searchQuery.grouping === "chain_name" && selectedAccount.chain_name) {
      query["chain_name"] = selectedAccount.chain_name;
    }

    if (
      (searchQuery.grouping === "chain_name" && !selectedAccount.chain_name) ||
      searchQuery.grouping === "product_name"
    ) {
      query["pid"] =
        searchQuery.pharmacy_type === "contract"
          ? selectedAccount.pharmacy_id
          : selectedAccount.in_house_pharmacy_id;
    }

    var resultLedgerPath =
      searchQuery.pharmacy_type === "inhouse" ? inhouseLedgerPath : ledgerPath;
    var balancePath =
      searchQuery.pharmacy_type === "inhouse"
        ? inhouseBalanceHistoryPath
        : balanceHistoryPath;

    httpClient
      .get(submissionDetailsPath, { params: { ...query } })
      // @ts-ignore - need to figure out how to handle this typing
      .then((res) => setSubmissions(res));
    httpClient
      .get(balancePath, { params: { ...query } })
      // @ts-ignore - need to figure out how to handle this typing
      .then((res) => setBalanceHistory(res));
    httpClient
      .get(resultLedgerPath, { params: { ...query } })
      // @ts-ignore - need to figure out how to handle this typing
      .then((res) => setLedger(res));
  }, []);

  const renderOverDrawnBadge = () => {
    if (selectedAccount.status === "OVERDRAWN") {
      return (
        <div className="badge">
          <div className="badge__indicator badge__indicator--orange" />
          Overdrawn
        </div>
      );
    }
  };

  const renderSubmissions = () => {
    const updatedAt = submissions?.updated_at;

    return (
      <div className="tile" style={{ flex: 1 }}>
        <div className="flex__container flex__container--gutter">
          <div style={{ flex: 0.4 }}>
            <div className="tile__header">
              <div className="tile__header__details">
                <div className="tile__header__details__title t--lg">
                  Submitted Claims
                </div>
                <div className="tile__header__details__subtitle">
                  {updatedAt && <>Updated - {updatedAt}</>}
                </div>
              </div>
            </div>
            <div className="flex__container flex__container__col flex__container__col--gap-sm">
              <div className="t--xl">
                {submissions
                  ? wholeNumberWithCommas(submissions?.total_conforming)
                  : 0}
              </div>
              <div className="t--sm">Conforming</div>
              <div className="t--xl">
                {submissions
                  ? wholeNumberWithCommas(submissions?.total_non_conforming)
                  : 0}
              </div>
              <div className="t--sm">Non-Conforming</div>
            </div>
            <div className="tile__footer tile__footer--spread"></div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 200,
              flex: 0.7,
            }}
          >
            <Pie
              data={submissions ? submissions.data : []}
              width={200}
              height={180}
              margin={{ top: 20, right: 30, bottom: 0, left: 30 }}
              innerRadius={0.8}
              activeInnerRadiusOffset={4}
              activeOuterRadiusOffset={4}
              enableArcLabels={false}
              enableArcLinkLabels={false}
              colors={["#3246D3", "#0A162F"]}
              legends={[
                {
                  anchor: "top-left",
                  direction: "row",
                  justify: false,
                  translateX: -30,
                  translateY: -20,
                  itemWidth: 95,
                  itemHeight: 10,
                  symbolSpacing: 4,
                  symbolSize: 6,
                  symbolShape: "circle",
                },
              ]}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderCreditsDebitsData = () => {
    var data;

    if (ledger && ledger.transactions) {
      data = [
        {
          id: "340B Purchases",
          data: _.map(ledger.transactions.debits, (value, key) => ({
            x: key,
            y: Math.abs(value.units),
          })).slice(-6),
        },
        {
          id: "Claim Submissions",
          data: _.map(ledger.transactions.credits, (value, key) => ({
            x: key,
            y: Math.abs(value.units),
          })).slice(-6),
        },
      ];
    }

    return data || [];
  };

  const renderCreditsDebits = () => {
    const updatedAt = ledger?.updated_at;

    return (
      <div className="tile" style={{ flex: 1 }}>
        <div className="flex__container flex__container--gutter">
          <div style={{ flex: 0.3 }}>
            <div className="tile__header">
              <div className="tile__header__details">
                <div className="tile__header__details__title t--lg">
                  Unit Submissions
                  <br /> vs <br />
                  340B Purchases
                </div>
                <div className="tile__header__details__subtitle">
                  {updatedAt && (
                    <>
                      Updated -<br />
                      {updatedAt}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="tile__footer tile__footer--spread">
              {renderOverDrawnBadge()}
            </div>
          </div>
          <div style={{ height: 200, flex: 0.7 }}>
            <CreditsDebitsChart data={renderCreditsDebitsData()} />
          </div>
        </div>
      </div>
    );
  };

  const renderRows = () => {
    if (ledger && ledger.transactions) {
      var balance = 0;
      var wac_balance = 0;

      return _.map(ledger.transactions.credits, (value, key) => {
        balance += value.units + ledger.transactions.debits[key].units;
        wac_balance +=
          value.wac_price + ledger.transactions.debits[key].wac_price;

        return (
          <tr className="sm" key={key}>
            <td className="t--md">{key}</td>
            <td className="t--sm t--500">{selectedAccount.product_name}</td>
            <td className="t--md t--500">
              {numberWithCommas(ledger.transactions.debits[key].units)}
            </td>
            <td className="t--md t--500">{numberWithCommas(value.units)}</td>
            <td className="t--md t--500">
              {numberWithCommas(
                value.units + ledger.transactions.debits[key].units
              )}
            </td>
            <td className="t--md t--500">{numberWithCommas(balance)}</td>
            <td className="t--md t--500">{currencyWithCommas(wac_balance)}</td>
          </tr>
        );
      });
    }
  };

  const renderBalanceHistoryTable = () => {
    return (
      <div>
        <div className="section__title section__title--mtb">Ledger History</div>
        <table className="primary">
          <thead>
            <tr className="sm">
              <th>Date</th>
              <th>Product Name</th>
              <th>340B Purchases</th>
              <th>Unit Submissions</th>
              <th>Subtotal</th>
              <th>Unit Balance</th>
              <th>WAC Balance</th>
            </tr>
          </thead>
          <tbody>{renderRows()}</tbody>
        </table>
      </div>
    );
  };

  const renderBadgePharmacyName = () => {
    if (selectedAccount.pharmacy_name) {
      return (
        <div
          className="badge badge--md color--blue"
          style={{ marginRight: 10 }}
        >
          {selectedAccount.pharmacy_name}
        </div>
      );
    }
  };

  const renderBadgePharmacyAddress = () => {
    if (selectedAccount.address) {
      return (
        <div
          className="badge badge--md color--blue"
          style={{ marginRight: 10 }}
        >
          {selectedAccount.address}
        </div>
      );
    }
  };

  const renderModal = () => {
    if (displayModal) {
      return (
        <div
          className="modal__background"
          style={{ alignItems: "flex-end" }}
          onClick={() => setDisplayModal(false)}
        >
          <Button
            variant="link"
            className="modal__close"
            onClick={() => setDisplayModal(false)}
          >
            <Close />
          </Button>
          <div
            className="modal__panel animated fadeInUpScale"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="container">
              <Button
                variant="link"
                className="modal__close"
                onClick={() => setDisplayModal(false)}
              >
                <Close />
              </Button>
              <div className="page-details__container">
                <div className="page-details__container__info">
                  <div className="flex__container flex__container--space-between">
                    <span>
                      <div className="page-details__title">Account Details</div>
                      <div className="t--lg mb-3">
                        {selectedAccount.entity_name}
                      </div>
                      <div className="page-details__subtitle">
                        <div className="breadcrumbs__container">
                          <div className="breadcrumbs__crumb breadcrumbs__crumb__active">
                            <div
                              className="badge badge--md color--blue"
                              style={{ marginRight: 10 }}
                            >
                              {selectedAccount.product_name}
                            </div>
                            <div
                              className="badge badge--md color--blue"
                              style={{ marginRight: 10 }}
                            >
                              {selectedAccount.id_340b}
                            </div>
                            {renderBadgePharmacyName()}
                            {renderBadgePharmacyAddress()}
                          </div>
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
              <div
                className="content__bordered_container"
                style={{ width: "100%", height: "auto" }}
              >
                <div
                  className="flex__container flex__container--gutter"
                  style={{ marginTop: 20 }}
                >
                  {renderSubmissions()}
                  {renderCreditsDebits()}
                </div>
                {renderBalanceHistoryTable()}
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return renderModal();
};

export default AccountsModal;
