import React, { Fragment, useState } from "react";
import HorizontalBarChart from "../../Charts/HorizontalBarChart";
import _ from "lodash";
import { wholeNumberWithCommas } from "../../utils/NumberFormatter";
import { UnfoldMore, KeyboardArrowRight } from "@material-ui/icons";
import TableRowsLoadingSkeleton from "../../TableRowsLoadingSkeleton";
import ReportSearch from "../Common/ReportSearch";
import PaginationControls from "../Common/PaginationControls";
import DetailModal from "./DetailModal";

import { AtRiskRow } from "../../types/models";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getExpandedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  ColumnSort,
  Row,
} from "@tanstack/react-table";

type NonCompliantCEsTableProps = {
  accounts_path: string;
  pharmacy_detail_path: string;
  entity_path: string;
  entities: AtRiskRow[];
  setSearchTerms: (arg1: string) => void;
  isLoading: boolean;
};

const NonCompliantCEsTable = ({
  accounts_path,
  pharmacy_detail_path,
  entity_path,
  entities,
  setSearchTerms,
  isLoading,
}: NonCompliantCEsTableProps) => {
  const [sorting, setSorting] = useState<ColumnSort[]>([]);
  const [displayModal, setDisplayModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<Row<AtRiskRow> | null>(null);

  const columnHelper = createColumnHelper<AtRiskRow>();

  const renderStatus = (row) => {
    return (
      <div className="flex__container">
        {renderSuspensionsBadges(row)}
        {renderExceptionsBadges(row)}
      </div>
    );
  };
  const renderSuspensionsBadges = (row) => {
    if (["Suspended", "Chain Suspended"].includes(row.original.status)) {
      return (
        <div className="badge">
          <div className={`badge__indicator badge__indicator--red`} />
          {row.original.status}
        </div>
      );
    }
  };

  const renderExceptionsBadges = (row) => {
    if (["Exception", "Chain Exception"].includes(row.original.status)) {
      return (
        <div className="badge">
          <div className={`badge__indicator badge__indicator--green`} />
          {row.original.status}
        </div>
      );
    }
  };

  const columns = (accounts_path) => {
    return [
      columnHelper.accessor("id_340b", {
        header: "Covered Entity",
        enableSorting: false,
        cell: ({ row, getValue }) => {
          return (
            <a href={`${entity_path}?id_340b=${getValue().toUpperCase()}`}>
              <div className="table__text__stack">
                <div className="t--sm t--500 t--link">{getValue().toUpperCase()}</div>
                <div className="t--sm t--subtitle">
                  {row.original.entity_name.toUpperCase().substring(0, 35)}
                </div>
              </div>
            </a>
          );
        },
      }),
      columnHelper.accessor("pharmacy_name", {
        header: "Contract Pharmacy",
        enableSorting: false,
        cell: ({ row, getValue }) => {
          return (
            <div className="table__text__stack">
              <div className="t--sm t--500">{getValue().toUpperCase()}</div>
              <div className="t--sm t--subtitle">
                {row.original.pharmacy_address?.toUpperCase().substring(0, 50)}
              </div>
            </div>
          );
        },
      }),
      columnHelper.display({
        header: "Status",
        cell: ({ row }) => {
          return renderStatus(row);
        },
      }),
      columnHelper.accessor("purchases", {
        header: "Purchases",
        enableSorting: true,
        cell: ({ row, getValue }) => (
          <div className="t--md t--500">${wholeNumberWithCommas(getValue())}</div>
        ),
      }),
      columnHelper.accessor("claims", {
        header: "Claims",
        enableSorting: true,
        cell: ({ row, getValue }) => (
          <div className="t--md t--500">${wholeNumberWithCommas(getValue())}</div>
        ),
      }),
      columnHelper.accessor("balance", {
        header: "Balance",
        enableSorting: true,
        cell: ({ row, getValue }) => (
          <div className="t--md t--500">${wholeNumberWithCommas(getValue())}</div>
        ),
      }),
      columnHelper.accessor("claims_percent", {
        header: "Percentage",
        cell: ({ row, getValue }) => {
          return (
            <div className="t--md t--500">
              <HorizontalBarChart variant="danger" progress={getValue() * 100} />
            </div>
          );
        },
      }),
      columnHelper.display({
        header: "Details",
        cell: ({ row }) => {
          return (
            <button
              className="btn btn--light"
              onClick={() => {
                setSelectedRow(row);
                setDisplayModal(true);
              }}
            >
              View Details
            </button>
          );
        },
      }),
      columnHelper.display({
        header: "Actions",
        enableSorting: false,
        cell: ({ row }) => {
          return (
            <a href={`${accounts_path}?id=${row.original.id_340b}`}>
              <div className="btn btn--sqr" style={{ verticalAlign: "middle" }}>
                <KeyboardArrowRight fontSize="small" />
              </div>
            </a>
          );
        },
      }),
    ];
  };

  const table = useReactTable<AtRiskRow>({
    data: entities,
    columns: columns(accounts_path),
    state: { sorting },
    onSortingChange: setSorting,
    getExpandedRowModel: getExpandedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: { pagination: { pageSize: 50 } },
  });

  const renderRows = () => {
    return table.getRowModel().rows.map((row) => {
      return (
        <Fragment key={row.id}>
          <tr className="sm">
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id} className="t--sm">
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        </Fragment>
      );
    });
  };

  const renderTable = (
    <table className="primary">
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id} className="sm">
            {headerGroup.headers.map((header) => (
              <th key={header.id}>
                {header.isPlaceholder
                  ? null
                  : flexRender(header.column.columnDef.header, header.getContext())}
                {header.column.getCanSort() && (
                  <span
                    style={{
                      fontSize: 12,
                      display: "inline-flex",
                      verticalAlign: "top",
                    }}
                    onClick={() => header.column.toggleSorting()}
                  >
                    <UnfoldMore fontSize="inherit" color="inherit" />
                  </span>
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>{isLoading ? <TableRowsLoadingSkeleton rows={25} columns={9} /> : renderRows()}</tbody>
    </table>
  );

  return (
    <div>
      <div className="table__actionbar table__actionbar--spacebetween">
        <ReportSearch setSearchTerms={setSearchTerms} />
      </div>
      {renderTable}
      <PaginationControls table={table} recordCount={entities.length} />
      {displayModal && selectedRow && (
        <DetailModal
          setDisplayModal={setDisplayModal}
          products={selectedRow.original.products}
          id_340b={selectedRow.original.id_340b}
          pid={selectedRow.original.pid || selectedRow.original.chain_name}
          pharmacy_detail_path={pharmacy_detail_path}
        />
      )}
    </div>
  );
};

export default NonCompliantCEsTable;
