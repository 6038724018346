import React, { useEffect, useState, PropsWithChildren } from "react";
import _ from "lodash";
import moment from "moment";
import { Skeleton } from "@mui/material";
import { ResponsiveLine } from "@nivo/line";
import httpClient from "../utils/httpClient";
import {
  wholeNumberWithCommas,
  currencyWithCommas,
} from "../utils/NumberFormatter";
import { defaultDate, monthOnly } from "../utils/dateFormatter";

import TableRowsLoadingSkeleton from "../TableRowsLoadingSkeleton";
import Tile from "../Base/Tile";

type DataPoint = { x: string; y: number };

type AtRiskAccountsWidgetProps = {
  at_risk_status_history_path: string;
  at_risk_entities_path: string;
  organization: string;
};

export default function AtRiskAccountsWidget({
  at_risk_status_history_path,
  at_risk_entities_path,
  organization,
}: AtRiskAccountsWidgetProps) {
  const [isChartLoading, setIsChartLoading] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [currentRangeSelection, setCurrentRangeSelection] = useState<number>(3);
  const [atRiskEntities, setAtRiskEntities] = useState<any[]>([]);

  const initializeStatusHistory = () => {
    return [3, 2, 1].map((monthsAgo) => {
      return {
        x: new Date(
          new Date().setMonth(new Date().getMonth() - monthsAgo)
        ).toLocaleDateString("en-US", { month: "short" }),
        y: 0,
      };
    });
  };
  const [statusHistory, setStatusHistory] = useState<DataPoint[]>(
    initializeStatusHistory()
  );

  const handleStatusHistoryUpdate = (
    data: Array<{ date: string; value: number }>
  ) => {
    if (!data?.length) return;

    setStatusHistory(
      data.map(({ date, value }) => {
        return { x: monthOnly(date, "short"), y: value };
      })
    );
  };

  // Need this for nivo chart area gradient otherwise it overflows the x-axis
  const minPoint = statusHistory.reduce(
    (currMin, { y }) => Math.min(currMin, y),
    statusHistory[0].y
  );

  const start_date = moment()
    .startOf("month")
    .subtract(currentRangeSelection, "months")
    .format("YYYY-MM-DD");

  useEffect(() => {
    setIsTableLoading(true);

    httpClient
      .get(at_risk_entities_path, { params: { date_range: { start_date } } })
      // @ts-ignore Fix typing for http responses
      .then((res) => setAtRiskEntities(res.accounts))
      .finally(() => setIsTableLoading(false));
  }, [currentRangeSelection]);

  useEffect(() => {
    setIsChartLoading(true);

    httpClient
      .get(at_risk_status_history_path, {
        params: {
          months: currentRangeSelection,
        },
      })
      .then((response) => handleStatusHistoryUpdate(response.data))
      .finally(() => setIsChartLoading(false));
  }, [currentRangeSelection]);

  const LoadingWrapper = (
    props: PropsWithChildren<{
      width?: number | string;
      height?: number | string;
      isLoading: boolean;
    }>
  ) => {
    const { width, height, children, isLoading } = props;

    return isLoading ? <Skeleton width={width} height={height} /> : children;
  };

  const chartSection = (
    <section
      style={{
        width: "100%",
        height: 254,
        backgroundColor: "#ffffff",
        borderRadius: 8,
      }}
    >
      <LoadingWrapper height="100%" isLoading={isChartLoading}>
        <ResponsiveLine
          data={[{ id: "at-risk-accounts", data: statusHistory }]}
          curve="catmullRom"
          enableArea
          enableCrosshair={false}
          margin={{ top: 24, right: 16, bottom: 24, left: 32 }}
          yScale={{ type: "linear", min: "auto", max: "auto" }}
          areaBaselineValue={minPoint}
          axisBottom={{ tickSize: 4, tickPadding: 8 }}
          axisLeft={{ tickSize: 4, tickPadding: 4, format: ">-.0d" }}
          enableGridX={false}
          colors={["#FF681F"]}
          pointColor={{ theme: "background" }}
          useMesh
          defs={[
            {
              id: "gradient",
              type: "linearGradient",
              colors: [
                { offset: 0, color: "#FF681F" },
                { offset: 100, color: "rgba(255, 104, 31, 0.1)" },
              ],
            },
          ]}
          fill={[{ match: "*", id: "gradient" }]}
        />
      </LoadingWrapper>
    </section>
  );

  const sortedTopTenAccounts = _.sortBy(atRiskEntities, ["balance"]).slice(
    0,
    10
  );

  const renderRows = sortedTopTenAccounts.map((account, i) => (
    <tr className="sm" key={i}>
      <td className="t--sm">{account.entity_name}</td>
      <td className="t--sm">{account.pharmacy_name}</td>
      <td className="t--sm">{currencyWithCommas(account.balance)}</td>
    </tr>
  ));

  const tableSection = (
    <section className="mt-5">
      <div className="d-flex flex-column gap-1">
        <Tile.SecondaryText className="t--500 px-2">
          Current At Risk Accounts
        </Tile.SecondaryText>
        <Tile.TertiaryText className="px-2">
          Includes suspended accounts
        </Tile.TertiaryText>
      </div>
      <div className="draw__divider" style={{ margin: "10px 0" }} />
      <div
        className="mt-3"
        style={{
          maxHeight: 240,
          overflowY: "scroll",
          scrollbarWidth: "none",
        }}
      >
        <table className="secondary">
          <thead>
            <tr className="sm">
              <th className="t--sm table__th--sticky no-bg">ENTITY</th>
              <th className="t--sm table__th--sticky no-bg">PHARMACY</th>
              <th className="t--sm table__th--sticky no-bg">BALANCE</th>
            </tr>
          </thead>
          <tbody>
            {isTableLoading ? (
              <TableRowsLoadingSkeleton columns={3} rows={6} />
            ) : (
              renderRows
            )}
          </tbody>
        </table>
      </div>
    </section>
  );

  return (
    <Tile>
      <Tile.Header className="flex-column gap-3">
        <section className="d-flex justify-content-between">
          <div className="d-flex flex-column gap-1">
            <Tile.SecondaryText className="t--500">
              At Risk Accounts
            </Tile.SecondaryText>
            <Tile.TertiaryText>
              <LoadingWrapper isLoading={isChartLoading}>
                Updated - {defaultDate(new Date())}
              </LoadingWrapper>
            </Tile.TertiaryText>
          </div>
          <a
            href={`/organization/${organization}/reports/ce_compliance`}
            className="btn btn--light"
          >
            View Report
          </a>
        </section>
        <section className="d-flex justify-content-between align-items-center">
          <Tile.PrimaryText>
            <LoadingWrapper width={60} isLoading={isChartLoading}>
              {wholeNumberWithCommas(
                statusHistory[statusHistory.length - 1]?.y
              )}
            </LoadingWrapper>
          </Tile.PrimaryText>
          <div className="btn__option__group">
            <button
              onClick={() => setCurrentRangeSelection(12)}
              className={
                currentRangeSelection === 12
                  ? "btn btn--tiny btn--selected"
                  : "btn btn--tiny"
              }
            >
              Last 12 Months
            </button>
            <button
              onClick={() => setCurrentRangeSelection(3)}
              className={
                currentRangeSelection === 3
                  ? "btn btn--tiny btn--selected"
                  : "btn btn--tiny"
              }
            >
              Last 3 Months
            </button>
          </div>
        </section>
      </Tile.Header>
      <Tile.Body>
        {chartSection}
        {tableSection}
      </Tile.Body>
    </Tile>
  );
}
