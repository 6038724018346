import React, { useState } from "react";
import { MoreHoriz } from "@material-ui/icons";
import { usePopoverMenu, PopoverMenu } from "../../Base/PopoverMenu";
import ConfirmationModal from "../../ConfirmationModal";

type MeatballMenuProps = {
  menuData: Record<string, unknown>;
  onModalConfirm: () => void;
};

export const MeatballMenu = ({
  menuData,
  onModalConfirm,
}: MeatballMenuProps) => {
  const { anchorEl, handleMenuOpen, handleMenuClose } = usePopoverMenu();
  const [displayConfirmation, setDisplayConfirmation] = useState(false);

  const handleDeleteSelection = () => {
    setDisplayConfirmation(true);
    handleMenuClose();
  };

  return (
    <>
      <PopoverMenu>
        <PopoverMenu.Trigger className="btn btn--sqr" onClick={handleMenuOpen}>
          <MoreHoriz fontSize="small" />
        </PopoverMenu.Trigger>
        <PopoverMenu.Content
          anchorEl={anchorEl}
          onClose={handleMenuClose}
          className="py-1"
        >
          <button className="btn btn--light" onClick={handleDeleteSelection}>
            Delete
          </button>
        </PopoverMenu.Content>
      </PopoverMenu>
      <ConfirmationModal
        title={`Please confirm that you want to delete ${menuData?.entity_name}`}
        message="Are you sure you want to delete?"
        displayModal={displayConfirmation}
        setModalState={setDisplayConfirmation}
        action={onModalConfirm}
      />
    </>
  );
};
