import React from "react";
import Table from "./UsersTable";
import ReportLayout from "../ReportLayout";
import DownloadReport from "../Common/DownloadReport";
import ReportDrawer from "../Common/ReportDrawer";

import { User } from "../../types/models";

type UsersIndexProps = {
  users: User[];
  users_download_path: string;
  filename: string;
};

const UsersIndex = ({
  users,
  users_download_path,
  filename,
}: UsersIndexProps) => {
  const downloadComponent = (
    <DownloadReport
      reportName="users"
      downloadPath={users_download_path}
      downloadFilename={filename}
      downloadParams={{}}
    />
  );

  return (
    <ReportLayout
      title="User contact details"
      body={<Table users={users} />}
      aside={<ReportDrawer download={downloadComponent} />}
    />
  );
};

export default UsersIndex;
