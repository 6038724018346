import React from "react";
import ChipSelector from "../../ChipSelector";

type StatusFilterProps = {
  statuses: Array<{ label: string; value: string }>;
  selectedStatus: string | null;
  setSelectedStatus: (arg1: string | null) => void;
};

const StatusFilter = ({
  statuses,
  selectedStatus,
  setSelectedStatus,
}: StatusFilterProps) => {
  return (
    <>
      <div className="draw__item__title draw__item__title--mb-sm t--upcase t--sm">
        Status
      </div>
      <div className="chip__well">
        <ChipSelector
          items={statuses}
          selected={selectedStatus}
          setSelected={setSelectedStatus}
        />
      </div>
    </>
  );
};

export default StatusFilter;
