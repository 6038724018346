import { toCapitalizedWords } from "./String";

export const capitalizeObjectKeys = (obj: Object) => {
  const result = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      result[toCapitalizedWords(key)] = obj[key];
    }
  }
  return result;
};
