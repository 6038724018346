import React, { Fragment } from "react";
import { numberWithCommas } from "../../utils/NumberFormatter";
import TableRowsLoadingSkeleton from "../../TableRowsLoadingSkeleton";
import { setCookie } from "../../utils/Page";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
} from "@tanstack/react-table";

const columnHelper = createColumnHelper<OverdrawnAccount>();

const columns = (accounts_path, organization_id) => {
  return [
    columnHelper.accessor("id_340b", {
      header: "340B ID",
      cell: ({ getValue }) => {
        return (
          <div className="table__text__stack">
            <div className="t--sm t--500">{getValue()}</div>
          </div>
        );
      },
    }),
    columnHelper.accessor("overdrawn_accounts", {
      header: "products",
      cell: ({ getValue }) => {
        return (
          <div className="table__text__stack">
            <div className="t--sm t--500">{getValue()}</div>
          </div>
        );
      },
    }),
    columnHelper.accessor("overdrawn_balance", {
      header: "unit balance",
      cell: ({ getValue }) => {
        return (
          <div className="table__text__stack">
            <div className="t--sm t--500">{numberWithCommas(getValue())}</div>
          </div>
        );
      },
    }),
    columnHelper.accessor("accounts_path", {
      header: "actions",
      cell: ({ row }) => {
        return (
          <button
            className="btn btn--light"
            onClick={() => {
              setCookie(
                "id_340b",
                row.original.id_340b,
                `/organization/${organization_id}`
              );
              window.location.href = `${accounts_path}?id=${row.original.id_340b}&toggleOverdrawnOnly=true`;
            }}
          >
            View Details
          </button>
        );
      },
    }),
  ];
};

type OverdrawnAccount = {
  id_340b: string;
  overdrawn_balance: number;
  overdrawn_accounts: number;
  accounts_path: string;
};

type OverdrawnAccountsTableProps = {
  overdrawnAccounts: Array<OverdrawnAccount>;
  accounts_path: string;
  loading: boolean;
  organization_id: string;
};

const OverdrawnAccountsTable = ({
  overdrawnAccounts,
  accounts_path,
  loading,
  organization_id,
}: OverdrawnAccountsTableProps) => {
  const table = useReactTable<OverdrawnAccount>({
    data: overdrawnAccounts,
    columns: columns(accounts_path, organization_id),
    getCoreRowModel: getCoreRowModel<OverdrawnAccount>(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: { pagination: { pageSize: 50 } },
  });

  const renderRows = () => {
    return table.getRowModel().rows.map((row) => {
      return (
        <Fragment key={row.id}>
          <tr className="sm">
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id} className="t--md">
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        </Fragment>
      );
    });
  };

  const renderTable = () => {
    return (
      <table className="primary">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className="sm">
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {loading ? (
            <TableRowsLoadingSkeleton rows={10} columns={4} />
          ) : (
            renderRows()
          )}
        </tbody>
      </table>
    );
  };

  const renderPaginationControls = () => {
    if (overdrawnAccounts.length > 50) {
      return (
        <div
          className="flex__container"
          style={{ justifyContent: "flex-end", marginTop: 10 }}
        >
          <button
            className="btn btn--small"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            Prev
          </button>
          <button
            className="btn btn--small"
            style={{ marginLeft: 10 }}
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            Next
          </button>
        </div>
      );
    }
  };

  return (
    <div>
      {renderTable()}
      {renderPaginationControls()}
    </div>
  );
};

export default OverdrawnAccountsTable;
