import React from "react";
import { numberWithCommas } from "../../utils/NumberFormatter";
import { EnforcementProductDetail } from "../../types/models";

type SubTableProps = {
  transactions: EnforcementProductDetail[];
};

const SubTable = ({ transactions }: SubTableProps) => {
  const getStatusColor = (val: string) => {
    if (val === "pass") {
      return "green";
    } else {
      return "red";
    }
  };

  const renderStatusBadge = (val: string) => {
    return (
      <div className="badge">
        <div className={`indicator indicator--${getStatusColor(val)}`} />
        {val}
      </div>
    );
  };

  const renderRows = transactions.map((row, i) => {
    return (
      <tr className="sm t--sm" key={i}>
        <td>
          <div className="t--sm t--500">{row.product_name.toUpperCase()}</div>
        </td>
        <td className="t--sm t--500 align-right">${numberWithCommas(row.sales)}</td>
        <td className="t--sm t--500 align-right">${numberWithCommas(row.claims)}</td>
        <td className="t--sm t--500 align-right">
          {numberWithCommas(row.claims_percent * 100.0)}%
        </td>
        <td>{renderStatusBadge(row.pass_fail)}</td>
      </tr>
    );
  });

  return (
    <div>
      <table className="primary">
        <thead>
          <tr className="sm">
            <th>Product</th>
            <th className="align-right">Purchases</th>
            <th className="align-right">Submissions</th>
            <th className="align-right">Claims Percent</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>{renderRows}</tbody>
      </table>
    </div>
  );
};

export default SubTable;
