import React, { Fragment } from "react";
import { ExpandMore } from "@material-ui/icons";
import SubmissionSummaryChart from "./SubmissionSummaryChart";
import _ from "lodash";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getExpandedRowModel,
  Row,
} from "@tanstack/react-table";

import { ClaimHistoryRow } from "../../types/models";

const columnHelper = createColumnHelper<ClaimHistoryRow>();

const columns = [
  columnHelper.accessor("date", {
    header: "Submission",
    cell: ({ row, getValue }) => {
      return (
        <>
          <div
            className="btn btn--sqr"
            style={{ marginRight: 18, verticalAlign: "middle" }}
            onClick={() => row.toggleExpanded()}
          >
            <ExpandMore fontSize="small" />
          </div>
          <span className="t t--500">{getValue()}</span>
        </>
      );
    },
  }),
  columnHelper.accessor("row_count", {
    header: "Claims",
    cell: ({ getValue }) => <div className="t--500">{getValue().toLocaleString()}</div>,
  }),
  columnHelper.accessor("conforming_percent", {
    header: "Conforming",
    cell: ({ row, getValue }) => {
      return (
        <div className="progress_bar__wrapper">
          <div className="progress_bar__container progress_bar__container--t-fixed">
            <div className="progress_bar__fill" style={{ width: `${getValue()}%` }} />
          </div>
          <div className="progress_bar__value">{`${getValue()}%`}</div>
        </div>
      );
    },
  }),
  columnHelper.accessor("entities", {
    header: "Total Entities",
    cell: ({ getValue }) => <div className="t--500">{getValue().toLocaleString()}</div>,
  }),
];

type ClaimsHistoryTableProps = {
  submissions: ClaimHistoryRow[];
};

export default function ClaimsHistoryTable({ submissions }: ClaimsHistoryTableProps) {
  const table = useReactTable<ClaimHistoryRow>({
    data: submissions,
    columns,
    getExpandedRowModel: getExpandedRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });

  const renderSubComponent = (row: Row<ClaimHistoryRow>) => {
    return <SubmissionSummaryChart row={row} />;
  };

  const renderRows = () => {
    return table.getRowModel().rows.map((row) => {
      return (
        <Fragment key={row.id}>
          <tr className="sm">
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id} className="t--md">
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
          {row.getIsExpanded() && (
            <tr>
              <td colSpan={row.getVisibleCells().length}>{renderSubComponent(row)}</td>
            </tr>
          )}
        </Fragment>
      );
    });
  };

  return (
    <div>
      <table className="primary">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className="sm">
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>{renderRows()}</tbody>
      </table>
    </div>
  );
}
