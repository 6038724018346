import React from "react";
import { Skeleton } from "@mui/material";

const ChartLoadingSkeleton = (props) => {
  return (
    <div>
      <Skeleton animation="wave" />
      <Skeleton animation="wave" />
      <Skeleton animation="wave" />
      <Skeleton animation="wave" />
      <Skeleton animation="wave" />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Skeleton animation="wave" variant="circular" width={12} height={12} />
        <Skeleton animation="wave" variant="circular" width={12} height={12} />
        <Skeleton animation="wave" variant="circular" width={12} height={12} />
        <Skeleton animation="wave" variant="circular" width={12} height={12} />
      </div>
    </div>
  );
};

export default ChartLoadingSkeleton;
