import React, { Fragment, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import ButtonGroup from "./Base/ButtonGroup";
import { CalendarMonth } from "@mui/icons-material";
import moment from "moment";

import { DateRange } from "./types/models";

type DateSelectorProps = {
  dateRange: DateRange;
  setDateRange: ({}: DateRange) => void;
  policyStartDate?: string;
};

const DateSelector = ({
  dateRange,
  policyStartDate = "2020-10-01",
  setDateRange,
}: DateSelectorProps) => {
  const dateMap = {
    "This Month": {
      start: moment().startOf("month").toDate(),
      end: moment().toDate(),
    },
    "Last 3 Months": {
      start: moment().startOf("month").subtract(3, "months").toDate(),
      end: moment().toDate(),
    },
    "Policy to Date": {
      start: moment(policyStartDate).toDate(),
      end: moment().toDate(),
    },
  };

  const buttons = ["This Month", "Last 3 Months", "Policy to Date"];
  const [activeButton, setActiveButton] = useState("Policy to Date");

  const setStartDate = (newDate) => {
    setDateRange({
      ...dateRange,
      start: moment(newDate).format("YYYY-MM-DD"),
    });
  };

  const setEndDate = (newDate) => {
    setDateRange({
      ...dateRange,
      end: moment(newDate).format("YYYY-MM-DD"),
    });
  };

  const handleButtonChange = (newButton: string) => {
    setActiveButton(newButton);
    setDateRange({
      start: moment(dateMap[newButton].start).format("YYYY-MM-DD"),
      end: moment(dateMap[newButton].end).format("YYYY-MM-DD"),
    });
  };

  const startDate = moment(dateRange.start).toDate();
  const endDate = moment(dateRange.end).toDate();

  return (
    <Fragment>
      <ButtonGroup
        buttons={buttons}
        activeButton={activeButton}
        setActiveButton={handleButtonChange}
      />
      <div className="flex__container flex__container--space-between">
        <div className="label label--grey">
          <div className="label__icon">
            <CalendarMonth fontSize="inherit" />
          </div>
          <div className="label__value">
            <DatePicker
              dateFormat="yyyy-MM-dd"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
            />
          </div>
        </div>
        <div className="label label--grey">
          <div className="label__icon">
            <CalendarMonth fontSize="inherit" />
          </div>
          <div className="label__value">
            <DatePicker
              dateFormat="yyyy-MM-dd"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsStart
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default DateSelector;
