import React from "react";

const Chart = ({ height = 18, width = 18 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" height={height} width={width}>
      <path
        d="M2.248 8h2.667v6H2.248V8zm9.334-2.667h2.666V14h-2.666V5.333zm-4.667-4h2.667V14H6.915V1.333z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Chart;
