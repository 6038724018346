import React from "react";
import TileLoadingSkeleton from "./TileLoadingSkeleton";
import CreditsDebitsChart from "../Accounts/Charts/CreditsDebitsChart";
import Tile from "../Base/Tile";

const CreditsVsDebits = ({ creditsVsDebits }) => {
  const { isLoading, data, error } = creditsVsDebits;

  return (
    <div style={{ gridColumn: "1 / span 2" }}>
      {isLoading ? (
        <TileLoadingSkeleton />
      ) : (
        <Tile>
          <Tile.Header>
            <Tile.SecondaryText className="t--500">Claim Submissions vs 340B Purchases</Tile.SecondaryText>
          </Tile.Header>
          <Tile.Body>
            <div style={{ height: 258 }}>{!!data?.length && <CreditsDebitsChart data={data} />}</div>
          </Tile.Body>
        </Tile>
      )}
    </div>
  );
};

export default CreditsVsDebits;
