import React from "react";
import useEntityFetch from "./useEntityFetch";
import TableRowsLoadingSkeleton from "../TableRowsLoadingSkeleton";
import DownloadReport from "../Reports/Common/DownloadReport";
import { currencyWithCommas } from "../utils/NumberFormatter";
import { CeCpTransactionRow } from "../types/models";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getExpandedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";

const CeCpTable = ({ query, resource }) => {
  const { isLoading, data } = useEntityFetch({
    query,
    endpoint: `${resource}/ce_cp`,
  });

  const transactions = data || [];
  const columnHelper = createColumnHelper<CeCpTransactionRow>();
  const columns = [
    columnHelper.accessor("pharmacy_id", {
      header: "Pharmacy",
      cell: ({ row, renderValue }) => {
        return (
          <div className="table__text__stack">
            <div className="t--sm t--500">{renderValue()}</div>
            <div className="t--sm t--subtitle t--upcase">
              {row.original.pharmacy_name || "In House"}
            </div>
          </div>
        );
      },
    }),
    columnHelper.accessor("pharmacy_type_flag", {
      header: "Pharmacy Type Flag (CP/CE)",
      cell: ({ renderValue }) => {
        return (
          <div className="table__text__stack">
            <div className="t--sm t--500">{renderValue()}</div>
          </div>
        );
      },
    }),
    columnHelper.accessor("network", {
      header: "Network",
      cell: ({ renderValue }) => {
        return (
          <div className="table__text__stack">
            <div className="t--sm t--500">{renderValue()}</div>
          </div>
        );
      },
    }),
    columnHelper.accessor("total_purchases", {
      header: "Total Purchases",
      cell: ({ getValue }) => {
        return (
          <div className="table__text__stack">
            <div className="t--sm t--500">{currencyWithCommas(getValue())}</div>
          </div>
        );
      },
    }),
  ];

  const downloadComponent = (
    <DownloadReport
      reportName="ce-cp-purchases"
      downloadPath={`${resource}/ce_cp`}
      downloadFilename={`ce-cp-${query.id_340b}.xlsx`}
      downloadParams={query}
    />
  );

  const table = useReactTable<CeCpTransactionRow>({
    data: transactions,
    columns,
    getExpandedRowModel: getExpandedRowModel(),
    getCoreRowModel: getCoreRowModel<CeCpTransactionRow>(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: { pagination: { pageSize: 50 } },
  });

  return (
    <div>
      <div className="justify-content-end d-flex my-2">{downloadComponent}</div>
      <table className="primary">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className="sm">
              {headerGroup.headers.map((header, i) => (
                <th key={header.id} style={i > 2 ? { textAlign: "right" } : { textAlign: "left" }}>
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {isLoading ? (
            <TableRowsLoadingSkeleton rows={5} columns={4} />
          ) : (
            table.getRowModel().rows.map((row) => {
              return (
                <tr className="sm" key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id} className="t--sm">
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </div>
  );
};

export default CeCpTable;
