export const numberWithCommas = (val = 0) => {
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(val);
};

export const wholeNumberWithCommas = (val = 0) => {
  return new Intl.NumberFormat("en-US", { maximumFractionDigits: 0 }).format(
    val
  );
};

export const currencyWithCommas = (amount: number, maxDigits = 0) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: maxDigits,
  }).format(amount);
};

export const abbreviatedNumber = (number) => {
  const formatter = new Intl.NumberFormat("en", {
    notation: "compact",
    compactDisplay: "short",
    maximumSignificantDigits: 3,
  });

  return formatter.format(number);
};

export const fractionToPercentage = (numerator, denominator, decimals = 0) => {
  const percentage = denominator === 0 ? 0 : numerator / denominator;

  return new Intl.NumberFormat("en-US", {
    style: "percent",
    maximumFractionDigits: decimals,
  }).format(percentage);
};
