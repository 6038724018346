import React, { Fragment } from "react";
import ReportSearch from "../Common/ReportSearch";
import PaginationControls from "../Common/PaginationControls";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
} from "@tanstack/react-table";

import { CPDesignation } from "../../types/models";

const columnHelper = createColumnHelper<CPDesignation>();

const columns = [
  columnHelper.accessor("entity_name", {
    header: "COVERED ENTITY",
    cell: ({ getValue }) => {
      return <div className="t--500">{getValue()}</div>;
    },
  }),
  columnHelper.accessor("id_340b", {
    header: "340B ID",
    cell: ({ getValue }) => <div className="t--nowrap">{getValue()}</div>,
  }),
  columnHelper.accessor("pharmacy_name", {
    header: "PHARMACY",
    cell: ({ getValue }) => {
      return <div>{getValue()}</div>;
    },
  }),
  columnHelper.accessor("pharmacy_address", {
    header: "PHARMACY ADDRESS",
    cell: ({ getValue }) => <div>{getValue()}</div>,
  }),
  columnHelper.accessor("hin", {
    header: "HIN",
    cell: ({ getValue }) => <div>{getValue()}</div>,
  }),
];

const CPDesignationTable = ({ designations, setSearchTerms }) => {
  const table = useReactTable<CPDesignation>({
    data: designations,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: { pagination: { pageSize: 50 } },
  });

  const renderRows = table.getRowModel().rows.map((row) => {
    return (
      <Fragment key={row.id}>
        <tr className="sm">
          {row.getVisibleCells().map((cell) => (
            <td key={cell.id} className="t--sm">
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          ))}
        </tr>
      </Fragment>
    );
  });

  const renderTable = (
    <table className="primary">
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id} className="sm">
            {headerGroup.headers.map((header) => (
              <th key={header.id}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>{renderRows}</tbody>
    </table>
  );

  return (
    <div>
      <div className="table__actionbar table__actionbar--spacebetween">
        <ReportSearch setSearchTerms={setSearchTerms} />
      </div>
      {renderTable}
      <PaginationControls table={table} recordCount={designations.length} />
    </div>
  );
};

export default CPDesignationTable;
