import React, { useEffect, useState } from "react";

import useEntityFetch from "./useEntityFetch";
import BalanceHistory from "./BalanceHistory";
import StatCards from "./StatCards";
import CreditsVsDebits from "./CreditsVsDebits";
import ContractPharmaciesTile from "./ContractPharmaciesTile";

const Overview = ({
  query,
  organization_id,
  entityDetails,
  contractPharmaciesLoading,
  mapData,
}) => {
  const resource = `/organization/${organization_id}/entities`;
  const productHistory = useEntityFetch({ query, endpoint: `${resource}/monthly_balance` });
  const statCards = useEntityFetch({ query, endpoint: `${resource}/stat_cards` });
  const creditsVsDebits = useEntityFetch({ query, endpoint: `${resource}/purchases_v_claims` });

  return (
    <div className="dashboard__container__4">
      <BalanceHistory productHistory={productHistory.data} isLoading={productHistory.isLoading} />
      <StatCards statCards={statCards} entityDetails={entityDetails} />
      <CreditsVsDebits creditsVsDebits={creditsVsDebits} />
      <ContractPharmaciesTile
        isLoading={contractPharmaciesLoading}
        pharmaciesMapData={mapData}
        entityDetails={entityDetails}
      />
    </div>
  );
};

export default Overview;
