import React, { Fragment, useState } from "react";
import _ from "lodash";
import { BarChartOutlined } from "@material-ui/icons";

import reports from "./reports";
import Tabs from "../Base/Tabs";
import ReportsGraphs from "./ReportsGraphs";
import DisabledTabModal from "../Modals/DisabledTabModal";

const Reports = (props) => {
  const [tabContent, setTabContent] = useState();
  const [disabledModalTag, setDisabledModalTag] = useState(null);
  const { OVI: isOVIClient } = props.disabled_report_tags;

  const filteredReports = !isOVIClient ? reports : reports.filter(report => report.kind !== 'ovi_communications');

  const tabs = {
    Reports: renderReports,
  };

  const disabledModalText = {
    OVI: {
      header:
        "Your organization currently does not have OVI configured as part of its policy.",
      body: "Please reach out to your account lead for more information.",
    },
  };

  const handleSelectedTab = (selectedTab) => {
    setTabContent(() => tabs[selectedTab]());
  };

  const renderTabs = () => {
    return (
      <Tabs tabs={Object.keys(tabs)} handleSelectedTab={handleSelectedTab} />
    );
  };

  const renderDisabledTabModal = () => {
    if (!disabledModalTag) return;

    return (
      <DisabledTabModal
        closeModal={() => setDisabledModalTag(null)}
        headerText={disabledModalText[disabledModalTag].header}
        bodyText={disabledModalText[disabledModalTag].body}
      />
    );
  };

  function renderReportRows() {
    const entityReports = ["claim_submissions"];
    return _.map(filteredReports, (report) => {
      let reportUrl = `/organization/${props.organization_id}/reports/${report.url_name}`;
      const disabled = props.disabled_report_tags[report.tag];
      if (props.id_340b && entityReports.includes(report.url_name)) {
        reportUrl += `?id_340b=${props.id_340b}`;
      }
      return (
        <tr key={report.kind} className="sm">
          <td className="t--md">
            <div className="flex__container flex__container--align-center">
              <div className="icon__sqr mr-10">
                <BarChartOutlined fontSize="inherit" />
              </div>
              <div className="table__text__stack">
                <div className="t--md t--500">
                  {report.title}{" "}
                  {report.tag && (
                    <span className="badge badge--tiny">{report.tag}</span>
                  )}
                </div>
                <div className="t--sm t--subtitle">{report.subtitle}</div>
              </div>
            </div>
          </td>
          <td className="t--md t--500">
            {disabled ? (
              <button
                onClick={() => setDisabledModalTag(report.tag)}
                className="btn btn--light"
              >
                View Details
              </button>
            ) : (
              <a href={reportUrl}>
                <div className="btn btn--light">View Details</div>
              </a>
            )}
          </td>
        </tr>
      );
    });
  }

  function renderReports() {
    return (
      <table className="primary">
        <thead>
          <tr className="sm">
            <th>Library</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>{renderReportRows()}</tbody>
      </table>
    );
  }

  return (
    <Fragment>
      <ReportsGraphs
        organizationId={props.organization_id}
        designations_count={props.designations_count}
      />
      {renderTabs()}
      {tabContent}
      {renderDisabledTabModal()}
    </Fragment>
  );
};

export default Reports;
