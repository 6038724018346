import React, { useState, useEffect } from "react";
import { Suspension } from "../types/models";
import { defaultDate } from "../utils/dateFormatter";
import httpClient from "../utils/httpClient";
import Tile from "../Base/Tile";
import TableRowsLoadingSkeleton from "../TableRowsLoadingSkeleton";

type SuspendedEntitiesWidgetProps = {
  organization: string;
  suspended_entities_path: string;
};

export default function SuspendedEntitiesWidget({
  organization,
  suspended_entities_path,
}: SuspendedEntitiesWidgetProps) {
  const [suspensions, setSuspensions] = useState<Suspension[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    httpClient
      .get(suspended_entities_path)
      .then((res) => setSuspensions(res.data))
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <Tile>
      <Tile.Header className="justify-content-between">
        <div className="d-flex flex-column gap-1">
          <Tile.SecondaryText className="t--500">
            Pricing Suspensions
          </Tile.SecondaryText>
          <Tile.TertiaryText>
            Updated - {defaultDate(new Date())}
          </Tile.TertiaryText>
        </div>
        <a
          className="btn btn--light"
          href={`/organization/${organization}/reports/suspended_entities`}
        >
          View Report
        </a>
      </Tile.Header>
      <Tile.Body>
        <div
          style={{
            maxHeight: 400,
            overflowY: "scroll",
            scrollbarWidth: "none",
          }}
        >
          <table className="secondary secondary--light">
            <thead>
              <tr className="sm">
                <th className="rnd-l t--sm table__th--sticky">Entity</th>
                <th className="t--sm table__th--sticky">Pharmacies</th>
                <th className="t--sm table__th--sticky">Network</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <TableRowsLoadingSkeleton columns={3} rows={10} />
              ) : (
                suspensions.map((item, i) => (
                  <tr className="sm" key={i}>
                    <td className="t--sm">
                      <div className="table__text__stack">
                        <div className="t--500">{item.entity_name}</div>
                        <div className="t--subtitle">{item.id_340b}</div>
                      </div>
                    </td>
                    <td className="t--sm">{item.pid}</td>
                    <td className="t--sm">
                      {item.network} {item.chain ? "chain" : ""}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </Tile.Body>
    </Tile>
  );
}
