import React from "react";

const CenteredMetric =
  (content) =>
  ({ center, bars }) => {
    // setting it for a specific case for now. should look to extend
    const { color } = bars.length ? bars[0] : "#3246D3";
    const x = center[0];
    const y = center[1] / 1.5;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x="0" y="0" fill={color || "currentColor"} fontSize="16">
          {content.map((c) => {
            return (
              <tspan x="0" fontSize={c.fs} dy="1.25em" textAnchor="middle" key={c.text}>
                {c.text}
              </tspan>
            );
          })}
        </text>
      </g>
    );
  };

export default CenteredMetric;
