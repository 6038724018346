import React, { createContext } from "react";

const ManufacturerContext = createContext();
const { Provider } = ManufacturerContext;

const ManufacturerProvider = props => {
  const {
    manufacturer_name,
    manufacturer_networks,
    logo_filename,
    policy_start_date,
    policy_annc_date,
    testing_threshold,
    policy_document_link,
    hub_subdomain,
    enforcement_products,
    in_house_enforcement,
    min_wac_threshold,
    api_key_hmac,
    has_additional_contact_info_export
  } = props.mfrSettings

  const value = {
    manufacturer_name,
    manufacturer_networks,
    logo_filename,
    policy_start_date,
    policy_annc_date,
    testing_threshold,
    policy_document_link,
    hub_subdomain,
    enforcement_products,
    in_house_enforcement,
    min_wac_threshold,
    api_key_hmac,
    has_additional_contact_info_export
  }

  return <Provider value={value}>
    {props.children}
  </Provider>
}

export { ManufacturerProvider, ManufacturerContext }
