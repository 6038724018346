import React, { useState, useEffect } from "react";
import { GroupsTwoTone, VerifiedUser, ChevronRight } from "@mui/icons-material";
import Tile from "../Base/Tile";
import httpClient from "../utils/httpClient";
import { StatCardData } from "../types/models";
import { Skeleton } from "@mui/material";
import StatCard from "../Base/StatCard";

type StatCardsProps = {
  hubUrl: string;
  user_name: string;
  user_email: string;
  user_role: any;
  entities_count: StatCardData;
  mfrProfileUrl: string;
  pharmacies_count: StatCardData;
  totalSalesDataUrl: string;
};

const Link = ({ url, isExternal = false }: { url: string; isExternal?: boolean }) => {
  if (!url) return null;

  return (
    <a
      href={url}
      target={isExternal ? "_blank" : "_self"}
      className="btn btn--sqr"
      style={{ width: "32px", height: "32px" }}
    >
      <ChevronRight />
    </a>
  );
};

const LoadingCard = ({ children }) => {
  return (
    <Tile>
      <Tile.Header className="justify-content-between">{children}</Tile.Header>
      <Tile.Body className="d-flex flex-column">
        <Skeleton animation="wave" width={210} />
      </Tile.Body>
    </Tile>
  );
};

const StatCards = (props: StatCardsProps) => {
  const [totalSales, setTotalSales] = useState<StatCardData>();
  const [salesLoaded, setSalesLoaded] = useState(false);

  function fetchTotalSales() {
    httpClient
      .get(props.totalSalesDataUrl)
      .then((res) => setTotalSales(res.data))
      .catch((error) => console.log(error))
      .finally(() => setSalesLoaded(true));
  }

  useEffect(() => {
    fetchTotalSales();
  }, []);

  return (
    <>
      <div className="div1">
        <Tile>
          <Tile.Header className="justify-content-between">
            <GroupsTwoTone htmlColor={"#3246D3"} fontSize={"large"} />
            <Link url={props.hubUrl} isExternal />
          </Tile.Header>
          <Tile.Body className="d-flex flex-column">
            <span className="tile--fw-500 tile--fs-22">{props.user_name}</span>
            <Tile.TertiaryText>{props.user_email}</Tile.TertiaryText>
          </Tile.Body>
          <Tile.Footer className="justify-content-between">
            <Tile.TertiaryText>Role: {props.user_role.toUpperCase()}</Tile.TertiaryText>
            <div className="d-flex align-items-center gap-1">
              <VerifiedUser htmlColor={"#2BD67B"} fontSize={"small"} style={{ width: "24px", height: "24px" }} />
              <Tile.TertiaryText>2fa: ON</Tile.TertiaryText>
            </div>
          </Tile.Footer>
        </Tile>
      </div>

      <div className="div2">
        {salesLoaded && totalSales ? (
          <StatCard
            title="Yearly WAC 340B Sales"
            stat={totalSales.featured_value || 0}
            legend="Total Sales"
            badge={{
              trend: totalSales.change.trending,
              value: totalSales.change.friendly,
              info: totalSales.interval || "",
            }}
            link={<Link url={props.hubUrl} isExternal />}
          />
        ) : (
          <LoadingCard>
            <Tile.SecondaryText>Yearly WAC 340B Sales</Tile.SecondaryText>
            <Link url={props.hubUrl} />
          </LoadingCard>
        )}
      </div>

      <div className="div3">
        <StatCard
          title="Distinct CEs with 340B pricing"
          stat={props.entities_count.current}
          badge={{
            trend: props.entities_count.change.trending,
            value: props.entities_count.change.friendly,
            info: "vs last month",
          }}
          link={<Link url={props.mfrProfileUrl} />}
        />
      </div>

      <div className="div4">
        <StatCard
          title="Distinct CPs with 340B pricing"
          stat={props.pharmacies_count.current}
          badge={{
            trend: props.pharmacies_count.change.trending,
            value: props.pharmacies_count.change.friendly,
            info: "vs last month",
          }}
          link={<Link url={props.mfrProfileUrl} />}
        />
      </div>
    </>
  );
};

export default StatCards;
