import React from "react";
import { ResponsiveBar } from "@nivo/bar";
import { getTextWidth } from "../utils/String";

type NetworkCountsBarChartProps = {
  // Note - this is an example of what's returned from the DB, but this particular TS file doesn't make
  // use of the keys directly, so it's ok that not all networks are here.
  data: {
    retail?: number;
  };
};

const NetworkCountsBarChart = ({ data }: NetworkCountsBarChartProps) => {
  const formattedData = Object.entries(data).map(([key, value]) => ({
    id: key,
    count: value,
  }));
  const networkNames = Object.keys(data);
  const marginRight = 20;
  // TODO: figure out why the getTextWidth for the longest text isn't matching the width rendered
  const marginLeft =
    Math.max(
      ...networkNames.map((network) => getTextWidth(network, "11px sans-serif"))
    ) + 7;

  // Used to skip every other x-axis tick
  let nextTickHidden = false;

  const showTick = (value) => {
    if (value === 0) {
      nextTickHidden = true;
      return true;
    } else {
      nextTickHidden = !nextTickHidden;
      return nextTickHidden;
    }
  };

  return (
    <ResponsiveBar
      data={formattedData}
      keys={["count"]}
      layout="horizontal"
      colors="#33c197"
      enableGridY={false}
      enableGridX={true}
      enableLabel={false}
      borderRadius={2}
      margin={{
        bottom: 40,
        right: marginRight,
        left: marginLeft,
      }}
      padding={0.5}
      axisBottom={{
        tickSize: 0,
        format: (v) => (showTick(v) ? v : ""),
      }}
      axisLeft={{
        tickSize: 0,
      }}
      tooltip={({ data }) => (
        <div className="tooltip-nivo-network-counts">
          <div className="tooltip-nivo-network-counts__header">{data.id}</div>
          <div className="tooltip-nivo-network-counts__body">
            <div className="tooltip-nivo-network-counts__ellipse"></div>
            <div className="tooltip-nivo-network-counts__text">
              {data.count} with Pricing
            </div>
          </div>
        </div>
      )}
      theme={{
        axis: {
          ticks: {
            text: {
              fontSize: 10,
              fontFamily: "Roboto",
            },
          },
        },
      }}
    />
  );
};

export default NetworkCountsBarChart;
