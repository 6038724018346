import React from "react";
import { ResponsiveBar } from "@nivo/bar";

const BarChart = ({ data, chartHeight }) => {
  return (
    <div style={{ height: chartHeight }}>
      <ResponsiveBar
        data={data}
        layout={"vertical"}
        keys={["conforming", "non_conforming"]}
        indexBy="month"
        groupMode="grouped"
        margin={{ top: 25, bottom: 25, right: 0, left: 50 }}
        padding={0.45}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colors={["#3246D3", "#9DB7FF"]}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          format: (v) => v.slice(0, 3),
          orient: "bottom",
          tickSize: 0,
          tickPadding: 10,
          tickRotation: 0,
        }}
        axisLeft={{
          orient: "left",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          tickValues: 5,
        }}
        theme={{
          textColor: "#718291",
        }}
        enableLabel={false}
        enableGridX={false}
        enableGridY={true}
        gridYValues={4}
        labelSkipHeight={12}
        labelTextColor={{
          from: "color",
          modifiers: [["darker", 1.6]],
        }}
        legends={[
          {
            anchor: "top-left",
            direction: "row",
            justify: false,
            translateX: -40,
            translateY: -20,
            itemWidth: 10,
            itemHeight: 10,
            itemsSpacing: 80,
            symbolSpacing: 4,
            symbolSize: 8,
            symbolShape: "circle",
          },
        ]}
        role="application"
        ariaLabel="Conforming claims"
      />
    </div>
  );
};

export default BarChart;
