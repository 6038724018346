import _ from "lodash";

export const search = (
  collection: any[],
  searchTerms: string,
  keys: string[]
) => {
  return collection.filter((pharmacy) => {
    return keys.some((key) =>
      pharmacy[key]?.toLowerCase().includes(searchTerms.toLowerCase())
    );
  });
};
