import React from "react";
import { ResponsiveLine } from "@nivo/line";

const OverdrawnAccountCharts = (props) => {
  return (
    <ResponsiveLine
      data={props.data && props.data.length ? [{ id: props.data.id, data: props.data.data }] : []}
      curve="catmullRom"
      enableArea={true}
      enablePoints={false}
      areaOpacity={0.2}
      enableCrosshair={false}
      defs={[
        {
          id: "gradientFill",
          type: "linearGradient",
          colors: [
            { offset: 0, color: "#FF681F" },
            { offset: 100, color: "#FFFFFF" },
          ],
        },
      ]}
      theme={{
        grid: {
          line: {
            stroke: "#F2F5F8",
          },
        },
      }}
      margin={{ top: 20, right: 10, bottom: 25, left: 45 }}
      xScale={{ type: "point" }}
      yScale={{
        type: "linear",
        min: "auto",
        max: "auto",
        stacked: true,
        reverse: false,
      }}
      axisBottom={{
        orient: "bottom",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
      }}
      axisLeft={{
        orient: "left",
        tickSize: 5,
        tickValues: 5,
        tickPadding: 5,
        tickRotation: 0,
      }}
      enableGridX={false}
      colors={["#FF681F"]}
      fill={[{ match: "*", id: "gradientFill" }]}
      pointColor={{ theme: "background" }}
      pointLabel="y"
      pointLabelYOffset={-12}
      useMesh={true}
    />
  );
};

export default OverdrawnAccountCharts;
