import React, { useState, useEffect } from "react";

import Table from "./OverdrawnAccountsTable";
import ReportSearch from "../Common/ReportSearch";

import { search } from "../../utils/search";
import httpClient from "../../utils/httpClient";
import DateSelector from "../../DateSelector";
import ProductFilter from "../Common/ProductFilter";
import DownloadReport from "../Common/DownloadReport";
import ReportDrawer from "../Common/ReportDrawer";
import ReportLayout from "../ReportLayout";

import moment from "moment";

type OverdrawnAccountsIndexProps = {
  products: Array<string>;
  download_filename: string;
  overdrawn_by_ce_path: string;
  policy_annc_date: string;
  accounts_path: string;
  organization_id: string;
};

const OverdrawnAccountsIndex = ({
  products,
  download_filename,
  overdrawn_by_ce_path,
  policy_annc_date,
  accounts_path,
  organization_id,
}: OverdrawnAccountsIndexProps) => {
  const [overdrawnAccounts, setOverdrawnAccounts] = useState([]);
  const [selectedProductName, setSelectedProductName] = useState<string | null>(
    null
  );
  const [searchTerms, setSearchTerms] = useState("");
  const [loading, setLoading] = useState(true);
  const [dateRange, setDateRange] = useState({
    start: moment(policy_annc_date).format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
  });
  const filteredAccounts = search(overdrawnAccounts, searchTerms, ["id_340b"]);
  const queryParams = {
    product_name: selectedProductName,
    date_range: dateRange,
  };

  useEffect(() => {
    fetchOverdrawnAccounts();
  }, [selectedProductName, dateRange]);

  const fetchOverdrawnAccounts = () => {
    setLoading(true);

    httpClient
      .get(overdrawn_by_ce_path, { params: queryParams })
      .then((response) => {
        setOverdrawnAccounts(response.data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const downloadComponent = (
    <DownloadReport
      reportName="overdrawn-accounts-report"
      downloadPath={overdrawn_by_ce_path}
      downloadFilename={download_filename}
      downloadParams={queryParams}
    />
  );

  const dateFilter = (
    <DateSelector
      dateRange={dateRange}
      setDateRange={setDateRange}
      policyStartDate={policy_annc_date}
    />
  );

  const productFilter = (
    <ProductFilter
      products={products}
      selectedProductName={selectedProductName}
      setSelectedProductName={setSelectedProductName}
    />
  );

  return (
    <ReportLayout
      title="Overdrawn Accounts"
      body={
        <>
          <div className="d-flex mb-3">
            <ReportSearch
              setSearchTerms={setSearchTerms}
              tooltip={{ title: "Search by 340B ID" }}
            />
          </div>
          <Table
            overdrawnAccounts={filteredAccounts}
            accounts_path={accounts_path}
            loading={loading}
            organization_id={organization_id}
          />
        </>
      }
      aside={
        <ReportDrawer
          download={downloadComponent}
          dateFilter={dateFilter}
          filterComponents={[productFilter]}
        />
      }
    />
  );
};

export default OverdrawnAccountsIndex;
