import React from "react";
import { currencyWithCommas } from "../utils/NumberFormatter";

type MfrProfileHeaderProps = {
  networks: string[];
  mfr_logo: string;
  name: string;
  policy_document_link: string;
  annc_date: string;
  start_date: string;
  ndcs: number;
  wac: {
    total: number;
    total_since_enforcement: number;
    inhouse: number;
    inhouse_since_enforcement: number;
    cp: number;
    cp_since_enforcement: number;
  };
};

const MfrProfileHeader = ({
  networks,
  mfr_logo,
  name,
  policy_document_link,
  annc_date,
  start_date,
  ndcs,
  wac,
}: MfrProfileHeaderProps) => {
  const renderNetworks = networks.map((network, i) => (
    <div key={i} className="badge" style={{ marginRight: 10 }}>
      {network}
    </div>
  ));

  const renderWac = (totalAmt: number, enforcementAmt: number) => {
    return (
      <>
        <div>Total: {currencyWithCommas(totalAmt)}</div>
        <div>Enforcement Population: {currencyWithCommas(enforcementAmt)}</div>
      </>
    );
  };

  return (
    <div className="details__header" style={{ border: 0 }}>
      <div
        style={{
          padding: "20px 0 10px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          className="details__header__partition details__header__section__info__item"
          style={{ border: 0, paddingLeft: 35 }}
        >
          <div className="mfr__item">
            <img src={mfr_logo} style={{ width: 180, marginBottom: 5 }} />
            <div className="mfr__item__details">
              <div className="mfr__item__details__title">{name}</div>
            </div>
          </div>
        </div>

        <div
          className="details__header__partition details__header__section__info__item"
          style={{ border: 0 }}
        >
          <div className="details__header__partition__item">
            <div className="details__header__partition__item__title">NDCs</div>
            <a
              href={"manufacturer/download_ndcs"}
              target="_blank"
              download="340B_ESP_NDC_List.xlsx"
            >
              <div className="details__header__partition__item__content">
                <div className="details__header__partition__item__content__action-icon">
                  <i className="solid solid solid-budicon-file-download"></i>
                </div>
                <div className="details__header__partition__item__content__details">
                  <div className="details__header__partition__item__content__details__title">
                    Download NDCs
                  </div>
                  <div className="details__header__partition__item__content__details__subtitle">
                    340B ESP NDC List
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="details__header__partition__item">
            <div className="details__header__partition__item__title">
              Policy
            </div>
            <a href={policy_document_link}>
              <div className="details__header__partition__item__content">
                <div className="details__header__partition__item__content__action-icon">
                  <i className="solid solid solid-budicon-file-download"></i>
                </div>
                <div className="details__header__partition__item__content__details">
                  <div className="details__header__partition__item__content__details__title">
                    Download Policy
                  </div>
                  <div className="details__header__partition__item__content__details__subtitle">
                    Policy Document
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>

      <div className="details__header__section__info__container details__header__section__info__container--mt">
        <div className="details__header__section__info__item">
          <div className="details__header__section__info__item__title">
            Networks
          </div>
          <div className="details__header__section__info__item__value">
            {renderNetworks}
          </div>
        </div>
        <div className="details__header__section__info__item">
          <div className="details__header__section__info__item__title">
            NDCs
          </div>
          <div className="details__header__section__info__item__value">
            {ndcs}
          </div>
        </div>
        <div className="details__header__section__info__item">
          <div className="details__header__section__info__item__title">
            Policy Date
          </div>
          <div className="details__header__section__info__item__value">
            {start_date}
          </div>
        </div>
        <div className="details__header__section__info__item">
          <div className="details__header__section__info__item__title">
            Announcement Date
          </div>
          <div className="details__header__section__info__item__value">
            {annc_date}
          </div>
        </div>
      </div>
      <div className="details__header__section__info__container details__header__section__info__container--bb">
        <div className="details__header__section__info__item">
          <div className="details__header__section__info__item__title">
            Total WAC
          </div>
          <div className="details__header__section__info__item__value">
            {renderWac(wac.total, wac.total_since_enforcement)}
          </div>
        </div>
        <div className="details__header__section__info__item">
          <div className="details__header__section__info__item__title">
            In-House WAC
          </div>
          <div className="details__header__section__info__item__value">
            {renderWac(wac.inhouse, wac.inhouse_since_enforcement)}
          </div>
        </div>
        <div className="details__header__section__info__item">
          <div className="details__header__section__info__item__title">
            CP WAC
          </div>
          <div className="details__header__section__info__item__value">
            {renderWac(wac.cp, wac.cp_since_enforcement)}
          </div>
        </div>
        <div className="details__header__section__info__item">
          <div className="details__header__section__info__item__title">
            OVI Threshold
          </div>
          <div className="details__header__section__info__item__value">
            {"--"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MfrProfileHeader;
