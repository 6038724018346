import React, { useRef, useEffect, useState } from "react";
import moment from "moment";
import ClaimDetailsTable from "./ClaimsDetailsTable";
import ReportDrawer from "../Common/ReportDrawer";
import MultiProductFilter from "../Common/MultiProductFilter";
import CoveredEntitySelector from "../Common/CoveredEntitySelector";
import DownloadReport from "../Common/DownloadReport";
import ClaimStatusFilter from "./ClaimStatusFilter";
import DateSelector from "../../DateSelector";
import Notice from "../../Notice";
import httpClient from "../../utils/httpClient";
import ReportLayout from "../ReportLayout";

import { Claim, ProductOption } from "../../types/models";

type ReportsIndexProps = {
  organization_id: string;
  default_ce: {
    entity_name: string;
    id_340b: string;
  };
  query_path: string;
  announcement_date: string;
  products: ProductOption[];
  filename: string;
};

const ReportsIndex = ({
  organization_id,
  default_ce,
  query_path,
  announcement_date,
  products,
  filename,
}: ReportsIndexProps) => {
  const [notice, setNotice] = useState({
    kind: "error",
    open: false,
    message: "",
  });

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const entityID = urlParams.get("id_340b");

  const [dateRange, setDateRange] = useState({
    start: moment(announcement_date || "2020-10-01").format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
  });
  const [selectedProductNames, setSelectedProductNames] = useState<string[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
  const [selectedEntityId, setSelectedEntityId] = useState(entityID || default_ce.id_340b);
  const [claims, setClaims] = useState<Claim[]>([]);
  const [isClaimsLoading, setIsClaimsLoading] = useState(false);

  const fetchedInitialClaims = useRef(false);

  const filterQuery = {
    product_name: selectedProductNames,
    status: selectedStatus,
  };

  useEffect(() => {
    fetch();
  }, [selectedEntityId, dateRange]);

  useEffect(() => {
    if (!fetchedInitialClaims.current || claims?.length || isClaimsLoading) return;

    setNotice({
      kind: "success",
      open: true,
      message: "The current selection and filters returned no Claims",
    });
  }, [claims, isClaimsLoading, fetchedInitialClaims]);

  const fetch = async () => {
    setIsClaimsLoading(true);

    await httpClient
      .get(query_path, {
        params: { id_340b: selectedEntityId, date_range: dateRange },
      })
      .then((res) => {
        // @ts-ignore todo: figure out axios typing
        setClaims(res.claims);
      });

    setIsClaimsLoading(false);
    fetchedInitialClaims.current = true;
  };

  const filteredClaims = () => {
    let filteredData = claims;

    if (selectedStatus) {
      filteredData = filteredData.filter((d) => d.status === selectedStatus);
    }

    if (selectedProductNames.length) {
      filteredData = filteredData.filter((d) => selectedProductNames.includes(d.product_name));
    }

    return filteredData;
  };

  const downloadComponent = (
    <DownloadReport
      reportName="claim-submissions"
      downloadPath={query_path}
      downloadFilename={filename}
      downloadParams={{ ...filterQuery, id_340b: selectedEntityId, date_range: dateRange }}
    />
  );

  const dateFilter = (
    <DateSelector
      dateRange={dateRange}
      setDateRange={setDateRange}
      policyStartDate={announcement_date}
    />
  );

  const productFilter = (
    <MultiProductFilter
      products={products}
      selectedProductNames={selectedProductNames}
      setSelectedProductNames={setSelectedProductNames}
    />
  );

  const claimStatusFilter = (
    <ClaimStatusFilter selectedStatus={selectedStatus} setSelectedStatus={setSelectedStatus} />
  );

  const coveredEntityFilter = (
    <CoveredEntitySelector
      selectedEntityId={selectedEntityId}
      setSelectedEntityId={setSelectedEntityId}
      organizationId={organization_id}
    />
  );

  return (
    <>
      <ReportLayout
        title="Claims Submissions"
        body={<ClaimDetailsTable claims={filteredClaims()} isLoading={isClaimsLoading} />}
        aside={
          <ReportDrawer
            download={downloadComponent}
            dateFilter={dateFilter}
            filterComponents={[coveredEntityFilter, claimStatusFilter, productFilter]}
          />
        }
      />

      <Notice details={notice} />
    </>
  );
};

export default ReportsIndex;
