import React from "react";
import useEntityFetch from "./useEntityFetch";
import Table from "../Reports/EnforcementLayer/EnforcementLayerTable";

const EnforcementLayerTable = ({ query, resource }) => {
  const { isLoading, data } = useEntityFetch({
    query,
    endpoint: `${resource}/enforcement_layer`,
  });

  const transactions = (data?.contract_pharmacies ?? []).concat(
    data?.in_house || []
  );

  return (
    <Table
      transactions={transactions}
      isLoading={isLoading}
      entity_path={resource}
    />
  );
};

export default EnforcementLayerTable;
