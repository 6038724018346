import React, { useState, useEffect } from "react";
import classnames from "classnames";
import Button from "./Button";

type TabsProps = {
  tabs: string[];
  handleSelectedTab: (arg1: string) => void;
};

const Tabs = ({ tabs, handleSelectedTab }: TabsProps) => {
  const [activeTab, setActiveTab] = useState(tabs[0]);

  useEffect(() => {
    handleSelectedTab(activeTab);
  }, [activeTab]);

  const renderTabs = () => {
    return tabs.map((tab) => {
      const tabItemClasses = classnames({
        tabs__item: true,
        "tabs__item--active": activeTab === tab,
      });

      return (
        <Button
          className={tabItemClasses}
          variant="link"
          key={tab}
          onClick={() => setActiveTab(tab)}
        >
          {tab}
        </Button>
      );
    });
  };

  return <div className="tabs__container">{renderTabs()}</div>;
};

export default Tabs;
