import React from "react";
import { Line } from "@nivo/line";

const Circle = ({ series }) => {
  if (series.length < 2) return;

  // In the design if the line is flat it has a circle marker in the end with an additional line for threshold.
  // I am not sure what the data will look like for this but in the mean time we're using to series of points
  // One for the threshhold and the other for the data and getting the x coordinate of the last item in order to place the cirlce at the end of it.
  const circleXPosition = series[0].data.at(-1).position.x;
  return (
    <g transform={`translate(${circleXPosition - 6},12)`}>
      <circle cx="3" cy="3" r="3" fill="#3246d3" strokeWidth="1" stroke="#fff" />
    </g>
  );
};

const MiniLineChart = ({ data }) => {
  return (
    <Line
      data={data}
      width={50}
      height={30}
      curve="basis"
      enablePoints={false}
      enableGridX={false}
      enableGridY={false}
      colors={["#3246d3", "#DEE7EE"]}
      layers={["lines", Circle]}
    />
  );
};

export default MiniLineChart;
