import React from "react";
import classnames from "classnames";
import { numberWithCommas } from "../utils/NumberFormatter";

const HorizontalBarChart = (props) => {
  const chartClasses = classnames({
    horizontalbarchart: true,
    "horizontalbarchart--reverse": props.isReverse,
  });

  const progressClasses = classnames({
    horizontalbarchart__progress: true,
    [`horizontalbarchart__progress--variant-${props.variant}`]: true,
  });

  const progressBarCSSProps = {
    "--progress": `${props.progress}%`,
    "--threshold": props.threshold ?? "none",
    "--threshold-position": props.threshold && `${props.threshold}%`,
  };

  const renderLabel = () => {
    if (!props.leftLabel || !props.rightLabel) return;

    return (
      <div className="horizontalbarchart__label">
        <span>{props.leftLabel}</span>
        <span>{props.rightLabel}</span>
      </div>
    );
  };

  return (
    <div className="progress_bar__wrapper" style={{ justifyContent: "flex-end" }}>
      <div className="progress_bar__container" style={{ overflow: "hidden" }}>
        <div
          className="progress_bar__fill progress_bar__fill--orange"
          style={{ width: `${Math.round(props.progress)}%` }}
        ></div>
      </div>
      <div className="progress_bar__value progress_bar__value--min-w">{Math.round(props.progress)}%</div>
    </div>

    // <div className={chartClasses} style={{ width: 120 }}>
    //   {renderLabel()}
    //   <div className="horizontalbarchart__track">
    //     <span className={progressClasses} style={progressBarCSSProps}></span>
    //     {props.trackLabel && <span>{props.trackLabel}</span>}
    //   </div>
    // </div>
  );
};

export default HorizontalBarChart;
