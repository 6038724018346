const reports = [
  {
    kind: "ovi_communications",
    title: "OVI Communications",
    subtitle: "",
    url_name: "ovi_communications",
    tag: "OVI",
  },
  {
    kind: "enforcement_layer",
    title: "Enforcement Layer",
    subtitle: "",
    url_name: "enforcement_layer",
    tag: "OVI",
  },
  {
    kind: "CE Compliance",
    title: "At-Risk Covered Entities",
    subtitle: "",
    url_name: "ce_compliance",
    tag: "OVI",
  },
  {
    kind: "suspended_entities",
    title: "Suspended Entities",
    subtitle: "",
    url_name: "suspended_entities",
    tag: "OVI",
  },
  {
    kind: "entity_exceptions",
    title: "Covered Entity Exceptions",
    subtitle: "CEs with exceptions can not be suspended",
    url_name: "exceptions",
    tag: "OVI",
  },
  {
    kind: "claim_submissions",
    title: "Claim Submissions",
    subtitle: "View Claim details by Covered Entity",
    url_name: "claim_submissions",
  },
  {
    kind: "claims",
    title: "Claims History",
    subtitle: "",
    url_name: "claims",
  },
  {
    kind: "overdrawn_accounts",
    title: "Overdrawn Accounts",
    subtitle: "Covered Entities",
    url_name: "overdrawn_accounts",
    tag: "OVI",
  },
  {
    kind: "contract_pharmacy_designations",
    title: "Contract Pharmacy Designations",
    subtitle: "",
    url_name: "contract_pharmacy_designations",
  },
  // https://secondsightsolutions.atlassian.net/browse/SCRUM-4309
  // {
  //   kind: "ce_cp_purchases",
  //   title: "CE / CP Purchases",
  //   subtitle: "",
  //   url_name: "ce_cp_purchases",
  // },
  {
    kind: "users",
    title: "User Contact Details",
    subtitle: "Registered Users",
    url_name: "users",
  },
];

export default reports;
