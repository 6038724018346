export const setQueryParam = (param: string, value: string) => {
  const queryParams = new URLSearchParams(document.location.search);
  queryParams.set(param, value);
  document.location.search = queryParams.toString();
};

export const setCookie = (key: string, value: string, path: string) => {
  if (path) {
    document.cookie = `${key}=${value};path=${path}`;
  } else {
    document.cookie = `${key}=${value}`;
  }
};
