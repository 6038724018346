import React from "react";
import ChipSelector from "../../ChipSelector";

type ProductFilterProps = {
  products: Array<string>;
  selectedProductName: string | null;
  setSelectedProductName: (arg1: string) => void;
};

const ProductFilter = ({
  products,
  selectedProductName,
  setSelectedProductName,
}: ProductFilterProps) => {
  return (
    <>
      <div className="draw__item__title draw__item__title--mb-sm t--upcase t--sm">
        Products
      </div>
      <div className="chip__well">
        <ChipSelector
          items={products}
          selected={selectedProductName}
          setSelected={setSelectedProductName}
        />
      </div>
    </>
  );
};

export default ProductFilter;
