import React, { Fragment, useState } from "react";
import _ from "lodash";
import { ExpandMore, ExpandLess, UnfoldMore } from "@material-ui/icons";
import SubTable from "./SubTable";
import TableRowsLoadingSkeleton from "../../TableRowsLoadingSkeleton";
import PaginationControls from "../Common/PaginationControls";
import { numberWithCommas } from "../../utils/NumberFormatter";

import { EnforcementRow } from "../../types/models";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getExpandedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  ColumnSort,
  Row,
} from "@tanstack/react-table";

type EnforcementLayerTableProps = {
  transactions: EnforcementRow[];
  isLoading: boolean;
  entity_path: string;
};

const EnforcementLayerTable = (props: EnforcementLayerTableProps) => {
  const [sorting, setSorting] = useState<ColumnSort[]>([]);
  const columnHelper = createColumnHelper<EnforcementRow>();

  const renderCellValue = (getValue) => {
    return getValue()?.toUpperCase() ?? "--";
  };

  const columns = [
    columnHelper.display({
      id: "expand",
      cell: ({ row }) => {
        return (
          <div
            className="btn btn--sqr"
            style={{ verticalAlign: "middle" }}
            onClick={() => {
              row.toggleExpanded();
            }}
          >
            {row.getIsExpanded() ? (
              <ExpandLess fontSize="small" />
            ) : (
              <ExpandMore fontSize="small" />
            )}
          </div>
        );
      },
    }),
    columnHelper.accessor("id_340b", {
      header: "Covered Entity",
      enableSorting: false,
      cell: ({ row, getValue }) => {
        return (
          <a href={`${props.entity_path}?id_340b=${renderCellValue(getValue)}`}>
            <div className="table__text__stack">
              <div className="t--sm t--500 t--link">{renderCellValue(getValue)}</div>
              <div className="t--sm t--subtitle">
                {row.original.entity_name.toUpperCase().substring(0, 35)}
              </div>
            </div>
          </a>
        );
      },
    }),
    columnHelper.accessor("pharmacy_name", {
      header: "Contract Pharmacy",
      enableSorting: false,
      cell: ({ row, getValue }) => {
        return (
          <div className="table__text__stack">
            <div className="t--sm t--500">{renderCellValue(getValue)}</div>
            <div className="t--sm t--subtitle">
              {row.original.pharmacy_address?.toUpperCase().substring(0, 50)}
            </div>
          </div>
        );
      },
    }),
    columnHelper.accessor("sales", {
      header: "Purchases",
      enableSorting: true,
      cell: ({ getValue }) => {
        return <div className="align-right">${numberWithCommas(getValue())}</div>;
      },
    }),
    columnHelper.accessor("claims", {
      header: "Submissions",
      cell: ({ getValue }) => {
        return <div className="align-right">${numberWithCommas(getValue())}</div>;
      },
    }),
    columnHelper.accessor("claims_percent", {
      header: "Percent",
      cell: ({ getValue }) => {
        return <div className="align-right">{numberWithCommas(getValue() * 100)}%</div>;
      },
    }),

    columnHelper.accessor("pass_fail", {
      header: "Status",
      cell: ({ row }) => {
        return (
          <div className="badge">
            <div className={`indicator indicator--${getStatusColor(row.original.pass_fail)}`} />
            {row.original.pass_fail}
          </div>
        );
      },
    }),
  ];

  const getStatusColor = (val: "pass" | "fail") => {
    if (val === "pass") {
      return "green";
    } else {
      return "red";
    }
  };

  const table = useReactTable<EnforcementRow>({
    data: props.transactions,
    columns,
    state: { sorting },
    onSortingChange: setSorting,
    getExpandedRowModel: getExpandedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: { pagination: { pageSize: 50 } },
  });

  const renderSubComponent = (row: Row<EnforcementRow>) => {
    return (
      <SubTable
        transactions={_.orderBy(
          row.original.product_details,
          ["sales", "product_name"],
          ["desc", "asc"]
        )}
      />
    );
  };

  const renderRows = table.getRowModel().rows.map((row) => {
    return (
      <Fragment key={row.id}>
        <tr className="sm">
          {row.getVisibleCells().map((cell) => (
            <td key={cell.id} className="t--sm">
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          ))}
        </tr>
        {row.getIsExpanded() && (
          <tr>
            <td colSpan={row.getVisibleCells().length}>{renderSubComponent(row)}</td>
          </tr>
        )}
      </Fragment>
    );
  });

  const renderTable = (
    <table className="primary">
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id} className="sm">
            {headerGroup.headers.map((header, i) => (
              <th key={header.id} style={i > 2 ? { textAlign: "right" } : { textAlign: "left" }}>
                {header.isPlaceholder
                  ? null
                  : flexRender(header.column.columnDef.header, header.getContext())}
                {header.column.getCanSort() && (
                  <span
                    style={{
                      fontSize: 12,
                      display: "inline-flex",
                      verticalAlign: "top",
                    }}
                    onClick={() => header.column.toggleSorting()}
                  >
                    <UnfoldMore fontSize="inherit" color="inherit" />
                  </span>
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {props.isLoading ? <TableRowsLoadingSkeleton rows={25} columns={7} /> : renderRows}
      </tbody>
    </table>
  );

  return (
    <>
      {renderTable}
      <PaginationControls table={table} recordCount={props.transactions.length} />
    </>
  );
};

export default EnforcementLayerTable;
