import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import debounce from "debounce-promise";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";

import httpClient from "../utils/httpClient";
import Button from "../Base/Button";
import { setCookie, setQueryParam } from "../utils/Page";

const customSelectStyles = {
  container: (base) => ({
    ...base,
    width: "100%",
    margin: 0,
  }),
  placeholder: (base) => ({
    ...base,
    color: "#718291",
    fontSize: 14,
  }),
  control: (base, state) => ({
    ...base,
    minHeight: 42,
    borderColor: `${state.isFocused ? "#3246D3" : "#DEE5EB"}`,
    borderRadius: 8,
    boxShadow: "none",
    "&:hover": {
      borderColor: `${state.isFocused ? "#3246D3" : "#DEE5EB"}`,
    },
  }),
  menu: (base) => ({
    ...base,
    borderRadius: 8,
    position: "relative",
    top: 0,
    marginBottom: 0,
    color: "#718291",
    fontSize: 14,
    border: "1px solid #DEE5EB",
    boxShadow: "none",
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: 210,
    borderRadius: 8,
    padding: 8,
  }),
  option: (base, state) => ({
    ...base,
    fontSize: 14,
    borderRadius: 8,
    cursor: "pointer",
    color: "#0A162F",
    backgroundColor: state.isSelected ? "#3246D3" : "#ffffff",
    "&:hover": {
      opacity: state.isFocused ? 0.7 : 1,
    },
  }),
  singleValue: (base) => ({
    ...base,
    fontSize: 14,
  }),
  input: (base) => ({
    ...base,
    fontSize: 14,
  }),
  valueContainer: (base) => ({
    ...base,
    padding: 6,
  }),
};

const formatOptionLabel = ({ value, label }) => {
  return (
    <>
      <div className="t--sm t--subtitle">{value}</div>
      <div className="t--500">{label}</div>
    </>
  );
};

const EntityDetailsDraw = (props) => {
  const [selected, setSelected] = useState(props.initialEntity);

  const fetchAvailableEntities = async (query) => {
    return await httpClient.get(`/api/entities?${new URLSearchParams({ query }).toString()}`)
      .then((response) => response);
  };

  const handleSelection = (option) => {
    props.onCoveredEntityEventEmit(option);
    setSelected(option);
    setQueryParam('id_340b', option.value);
    const cookiePath = `/organization/${props.organizationId}`;
    setCookie('id_340b', option.value, cookiePath);
  };

  const renderSelectedValue = () => {
    if (selected) {
      return (
        <div className="label label--full-width label--col label--align-l">
          <div className="label__subtitle">Name</div>
          <div className="label__value">{selected.label}</div>
          <div className="label__subtitle">340B ID</div>
          <div className="label__value">{selected.value}</div>
        </div>
      );
    }
  };

  return (
    <div className="draw__container draw__container--scroll">
      {/* Hide disabled share and download buttons until implemented */}
      {/* <div className="draw__tools__container">
        <Button variant="secondary" isDisabled>
          <ShareOutlinedIcon fontSize="small" /> <span>Share</span>
        </Button>
        <Button variant="secondary" isDisabled>
          <FileDownloadOutlinedIcon fontSize="small" /> <span>CSV</span>
        </Button>
      </div> */}
      <div className="draw__item">
        <div className="draw__item__title draw__item__title--mb-sm t--upcase t--sm">Covered Entity</div>
        <div className="chip__well">
          {renderSelectedValue()}
          <AsyncSelect
            components={{ IndicatorSeparator: null }}
            cacheOptions
            defaultOptions
            isClearable={false}
            name="id_340b"
            placeholder="Select Covered Entity"
            styles={customSelectStyles}
            loadOptions={debounce(fetchAvailableEntities, 500)}
            formatOptionLabel={formatOptionLabel}
            controlShouldRenderValue={false}
            hideSelectedOptions={true}
            onChange={handleSelection}
          />
        </div>
      </div>
      <div className="draw__divider" />
      <div className="draw__item__title draw__item__title--mb-sm t--upcase t--sm">Notes & Activity</div>
    </div>
  );
};

export default EntityDetailsDraw;
