import React from "react";

type ReportTitleProps = {
  title: String;
  breadcrumb?: string;
};

const ReportTitle = ({ title, breadcrumb = "Index" }: ReportTitleProps) => {
  return (
    <>
      <div className="page-details__container">
        <div className="page-details__container__info">
          <div className="flex__container flex__container--space-between">
            <span>
              <div className="page-details__title">{title}</div>
              <div className="page-details__subtitle">
                <div className="breadcrumbs__container">
                  <div className="breadcrumbs__crumb breadcrumbs__crumb__active">
                    {breadcrumb}
                  </div>
                </div>
              </div>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportTitle;
