import React from "react";
import Button from "../Base/Button";
import { Close } from "@material-ui/icons";
import Illustration from "~/images/modal_illustration.png";

type DisabledTabModalProps = {
  closeModal: () => void;
  headerText: string;
  bodyText: string;
};

const DisabledTabModal = ({
  closeModal,
  headerText,
  bodyText,
}: DisabledTabModalProps) => {
  return (
    <div className="small-modal__background" onClick={() => closeModal()}>
      <div
        className="small-modal__panel animated fadeInUpScale"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="small-modal__header">
          <img
            src={Illustration}
            style={{ width: 145, height: 49, position: "absolute", right: 98 }}
            alt="Modal Illustration"
          />
          <Button
            variant="link"
            className="modal__close"
            onClick={() => closeModal()}
          >
            <Close />
          </Button>
        </div>
        <div className="small-modal__body">
          <div className="small-modal__body--title">{headerText}</div>
          <div className="small-modal__body--text">{bodyText}</div>
          <div style={{ textAlign: "right", marginTop: 24 }}>
            <button className="btn btn--small" onClick={() => closeModal()}>
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisabledTabModal;
