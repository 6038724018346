import React from "react";

const PlusMinus = ({ height = 48, width = 48, isPlus }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" height={height} width={width}>
      {isPlus ? (
        <path d="M22.5 38V25.5H10v-3h12.5V10h3v12.5H38v3H25.5V38Z" />
      ) : (
        <path d="M10 25.5v-3h28v3Z" />
      )}
    </svg>
  );
};

export default PlusMinus;
