export const isSubstrIncludedInString = (str: string, substr: string) => {
  if (!str) return false;
  return str.toLowerCase().includes(substr.toLowerCase());
};

export const doesStringStartWith = (str: string, startsWith: string) => {
  if (!str) return false;

  return str.toLowerCase().startsWith(startsWith.toLowerCase());
};

export const getTextWidth = (text: string, font: string) => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  context!.font = font || getComputedStyle(document.body).font;

  return context!.measureText(text).width;
};

export const toCapitalizedWords = (str: string) => {
  const words = str.match(/[A-Za-z][a-z]*/g) || [];

  return words.map(capitalizeStr).join(" ");
};

export const capitalizeStr = (str: string) => {
  return str.charAt(0).toUpperCase() + str.substring(1);
};
