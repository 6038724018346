import React from "react";
import { ResponsiveScatterPlot } from "@nivo/scatterplot";

const ScatterPlotChart = ({ data, chartHeight }) => {
  return (
    <div style={{ height: chartHeight }}>
      <ResponsiveScatterPlot
        data={data}
        margin={{ top: 50, right: 15, bottom: 25, left: 50 }}
        xScale={{ type: "point" }}
        yScale={{ type: "linear", min: 0, max: "auto" }}
        enableGridX={false}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: "bottom",
          tickSize: 0,
          tickPadding: 9,
          tickRotation: 0,
        }}
        axisLeft={{
          orient: "left",
          tickSize: 0,
          tickPadding: 20,
          tickRotation: 0,
        }}
        colors={["#3D71FF", "#9DB7FF", "#25265e"]}
        theme={{
          textColor: "#718291",
        }}
        legends={[
          {
            anchor: "top-left",
            direction: "row",
            justify: false,
            translateX: -35,
            translateY: -40,
            itemWidth: 100,
            itemHeight: 20,
            itemsSpacing: 0,
            symbolSize: 9,
            symbolShape: "circle",
            itemDirection: "left-to-right",
          },
        ]}
      />
    </div>
  );
};

export default ScatterPlotChart;
