import React from "react";

const Trend = ({ height = 48, width = 48, trendDirection = "flat" }) => {
  const availableTrends = {
    up: "M10.1 40 8 37.85 32.85 13H18.2v-3H38v19.8h-3V15.1Z",
    down: "M10 38V18.2h3v14.7L37.9 8l2.1 2.15L15.15 35H29.8v3Z",
    flat: "",
  };

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" height={height} width={width}>
      <path d={availableTrends[trendDirection]} fill="currentColor" />
    </svg>
  );
};

export default Trend;
