import React, { useState, useEffect } from "react";
import _ from "lodash";

import Table from "./CeCpTable";

import { search } from "../../utils/search";
import httpClient from "../../utils/httpClient";
import DownloadReport from "../Common/DownloadReport";
import NetworkFilter from "../Common/NetworkFilter";
import ReportDrawer from "../Common/ReportDrawer";
import ReportTitle from "../Common/ReportTitle";
import PharmacyTypeFilter from "../Common/PharmacyTypeFilter";
import ReportSearch from "../Common/ReportSearch";

import { NetworkOption } from "../../types/models";

type CeCpPurchasesIndexProps = {
  ce_cp_purchases_path: string;
  networks: Array<NetworkOption>;
  filename: string;
};

type Record = {
  id_340b: string;
  entity_name: string;
  total_purchases: number;
  units: number;
  category: "contract" | "in_house";
  product_details: any[];
  network: string;
};

const CeCpIndex = ({
  ce_cp_purchases_path,
  networks,
  filename,
}: CeCpPurchasesIndexProps) => {
  const [searchTerms, setSearchTerms] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [data, setData] = useState<Record[]>([]);
  const [selectedNetwork, setSelectedNetwork] = useState(networks[0].value);

  const filterData = () => {
    let filteredData = data;

    if (selectedCategory) {
      filteredData = data.filter((d) => d.category === selectedCategory);
    }

    if (selectedNetwork) {
      filteredData = filteredData.filter((d) => d.network === selectedNetwork);
    }

    if (searchTerms) {
      filteredData = search(filteredData, searchTerms, [
        "entity_name",
        "id_340b",
      ]);
    }

    return _.orderBy(filteredData, ["id_340b"], ["asc"]);
  };

  useEffect(() => {
    setLoading(true);

    httpClient
      .get(ce_cp_purchases_path)
      .then((response) => {
        // @ts-ignore
        setData(response);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, []);

  const downloadComponent = (
    <DownloadReport
      reportName="ce-cp-purchases-report"
      downloadPath={ce_cp_purchases_path}
      downloadFilename={filename}
      downloadParams={{ network: selectedNetwork, category: selectedCategory }}
    />
  );

  const networkFilter = (
    <NetworkFilter
      networks={networks}
      setSelectedNetwork={setSelectedNetwork}
      selectedNetwork={selectedNetwork}
      required={false}
    />
  );

  const pharmacyTypes = [
    { label: "Contract Pharmacies", value: "contract" },
    { label: "In-House", value: "in_house" },
  ];

  const pharmacyTypeFilter = (
    <PharmacyTypeFilter
      required={false}
      types={pharmacyTypes}
      selectedType={selectedCategory}
      setSelectedType={setSelectedCategory}
    />
  );

  return (
    <div className="d-flex">
      <div className="content__container">
        <ReportTitle title="CP/CE Purchases" breadcrumb="Report" />
        <div style={{ marginTop: 20 }}>
          <div className="table__actionbar table__actionbar--spacebetween">
            <ReportSearch setSearchTerms={setSearchTerms} />
          </div>
          <Table transactions={filterData()} isLoading={loading} />
        </div>
      </div>
      <ReportDrawer
        download={downloadComponent}
        filterComponents={[pharmacyTypeFilter, networkFilter]}
      />
    </div>
  );
};

export default CeCpIndex;
